import { callAPI } from '.';

/**
 * Retrieve the DV01 Liabilities data
 */
export function getLiabilities() {
  const json = callAPI(`/api/DV01KRD_Liabilities.json`);

  return json;
}
