import { Box, Button, Flex, H2, VisuallyHiddenLabel } from '@risk-first/ui-core';
import { Select } from '@risk-first/ui-select';
import { rem } from 'polished';
import React, { FormEvent, MouseEvent, useCallback, useEffect, useMemo, useState } from 'react';
import { useHistory } from 'react-router';
import { useSearchParam } from 'react-use';
import { ReactComponent as GraphIconComponent } from '../../../assets/icons/chart-bar.svg';
import { Portfolio } from '../../../features/portfolios/PortfolioState';
import { getSavedAnalysisSettings } from '../../../lib/localStorage';
import { useThemeVal } from '../../../lib/style-helper/theme';
import { composeIdForGeneratedStrategy, transformSearchParams } from '../../../utils';
import { EmptyPortfolio } from '../../SelectedPortfolio/styles';
import { VerticalTabMenu } from '../../VerticalTabMenu';
import { AssetAllocationContainer } from './AssetAllocationContainer';
import { DefaultAnalysisStrategyDialog } from './DefaultAnalysisStrategyDialog';
import { SaveStrategyDialog } from './SaveStrategyDialog';
import { StrategyOverview } from './StrategyOverview';
import { StrategyValueTable } from './StrategyValueTable';

interface FromExistingPanelProps {
  onSaveStrategy: (strategy: Portfolio) => void;
  strategies: Portfolio[] | undefined;
}

export const FromExistingPanel = (props: FromExistingPanelProps) => {
  const { onSaveStrategy, strategies } = props;
  const history = useHistory();
  const [newStrategy, setNewStrategy] = useState<Portfolio | undefined>();
  const [verticalTab, setVerticalTab] = useState<string | undefined>('tabpanel-assets');
  const [isNewStrategyVisible, setNewStrategyVisible] = useState(false);
  const [isSaveDialogOpen, setSaveDialogOpen] = useState(false);

  const themeVal = useThemeVal();
  const panelBorderStyle = `1px solid ${themeVal('colors.border')}`;

  const existingStrategyId = useSearchParam('existingId');
  const existingStrategy: Portfolio | undefined = useMemo(
    () =>
      existingStrategyId !== null
        ? strategies?.find((strategy) => strategy.strategyID === existingStrategyId)
        : undefined,
    [existingStrategyId, strategies],
  );

  const setExistingStrategy = useCallback(
    (strategy: Portfolio | undefined) => {
      history.push({
        pathname: history.location.pathname,
        search: transformSearchParams(history.location.search, { existingId: strategy?.strategyID }),
      });
    },
    [history],
  );

  const newStrategyFromExisting = useCallback(
    (existingStrategy: Portfolio) => {
      if (!strategies) return undefined;

      const uniqueStrategyId = (Math.max(...strategies.map((s) => parseInt(s.strategyID))) + 1).toString();
      const donorStrategy =
        getSavedAnalysisSettings()?.defaultStrategyForAnalysis?.strategyID || existingStrategy.strategyID;

      return {
        ...existingStrategy,
        name: 'New Strategy',
        id: Math.max(...strategies.map((s) => s.id)) + 1,
        source: 'Generate',
        strategyID: composeIdForGeneratedStrategy(uniqueStrategyId, donorStrategy),
      };
    },
    [strategies],
  );

  useEffect(() => {
    // If we get a different existing strategy, create a new strategy derived from it
    if (existingStrategy !== undefined) {
      setNewStrategy(newStrategyFromExisting(existingStrategy));
    } else {
      setNewStrategy(undefined);
    }
  }, [existingStrategy, newStrategyFromExisting, setNewStrategy]);

  const handleExistingStrategyChange = useCallback(
    (event: FormEvent<HTMLSelectElement>) => {
      if (!strategies) return;

      const selectedStrategy = strategies.find(({ strategyID }) => strategyID === event.currentTarget.value);
      setExistingStrategy(selectedStrategy);
    },
    [setExistingStrategy, strategies],
  );

  const handleExistingStrategyClear = useCallback(() => {
    setExistingStrategy(undefined);
    setNewStrategy(undefined);
  }, [setExistingStrategy]);

  const handleNewStrategySave = useCallback(
    (name: string) => {
      if (!newStrategy) return;

      const namedStrategy: Portfolio = {
        ...newStrategy,
        name,
      };
      onSaveStrategy(namedStrategy);
    },
    [newStrategy, onSaveStrategy],
  );

  const handleTabItemClick = useCallback((event: MouseEvent<HTMLButtonElement>) => {
    const button = event.currentTarget;
    const newActiveTab = button.getAttribute('aria-controls')!.toString();
    const currentStatus = button.getAttribute('aria-selected');

    if (currentStatus === 'true') {
      setVerticalTab(undefined);
      button.focus();
    } else {
      setVerticalTab(newActiveTab);
    }
  }, []);

  const [defaultStrategyDialogOpen, setDefaultStrategyDialogOpen] = useState(false);
  const handleDefaultStrategyDialogToggle = useCallback(() => setDefaultStrategyDialogOpen((open) => !open), []);

  return (
    <>
      <DefaultAnalysisStrategyDialog
        isOpen={defaultStrategyDialogOpen}
        setOpen={setDefaultStrategyDialogOpen}
        strategies={strategies}
      />
      <Flex>
        <Box borderRight={panelBorderStyle} flex="1">
          <Box p={`${rem(20)} ${rem(32)} ${rem(10)} ${rem(32)}`}>
            <H2 fontWeight={themeVal('fontWeight.medium')} pb={rem(20)}>
              Create strategy from existing
            </H2>
            <VisuallyHiddenLabel htmlFor="portfolio-id">Choose</VisuallyHiddenLabel>
            {strategies && (
              <Box mr={2}>
                <Select
                  id="strategy-id"
                  isClearEnabled={true}
                  name="strategy"
                  value={existingStrategy !== undefined ? existingStrategy.strategyID : ''}
                  variant="default"
                  onChange={handleExistingStrategyChange}
                  onClear={handleExistingStrategyClear}
                >
                  <option value="">No strategy selected</option>
                  {strategies.map((strategy: Portfolio) => (
                    <option key={strategy.strategyID} value={strategy.strategyID}>
                      {strategy.name}
                    </option>
                  ))}
                </Select>
              </Box>
            )}
          </Box>

          {existingStrategy && newStrategy ? (
            <StrategyOverview
              existingStrategy={existingStrategy}
              newStrategy={isNewStrategyVisible ? newStrategy : undefined}
            />
          ) : (
            <EmptyPortfolio>Select a strategy above as a basis for the new strategy</EmptyPortfolio>
          )}

          {existingStrategy && newStrategy && (
            <Box borderTop={panelBorderStyle} p={`${rem(30)} ${rem(20)}`}>
              <StrategyValueTable existingStrategy={existingStrategy} newStrategy={newStrategy} />

              <Button
                disabled={newStrategy === undefined}
                mt={rem(20)}
                size="small"
                variant="primary"
                onClick={() => setSaveDialogOpen(true)}
              >
                Save strategy
              </Button>
              {strategies && (
                <SaveStrategyDialog
                  isOpen={isSaveDialogOpen}
                  setOpen={setSaveDialogOpen}
                  strategies={strategies}
                  strategy={newStrategy}
                  onSave={handleNewStrategySave}
                />
              )}
            </Box>
          )}
        </Box>

        {verticalTab === 'tabpanel-assets' && (
          <Box
            backgroundColor={themeVal('colors.ghostWhite')}
            borderLeft={panelBorderStyle}
            borderRight={panelBorderStyle}
            marginLeft="-1px"
            width={rem(340)}
          >
            <AssetAllocationContainer
              existingStrategy={existingStrategy}
              newStrategy={newStrategy}
              setNewStrategyVisible={setNewStrategyVisible}
              toggleDefaultStrategyDialog={handleDefaultStrategyDialogToggle}
              updateNewStrategy={setNewStrategy}
            />
          </Box>
        )}
        <VerticalTabMenu
          activeTab={verticalTab}
          tabLabels={[
            {
              controlledId: 'tabpanel-assets',
              icon: <GraphIconComponent />,
              id: 'tab-assets',
              text: 'Asset allocation',
            },
          ]}
          onTabItemClick={handleTabItemClick}
        />
      </Flex>
    </>
  );
};
