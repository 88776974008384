import { CloseIconComponent } from '@risk-first/ui-assets';
import { Checkbox } from '@risk-first/ui-checkbox';
import { Box, Flex, Input } from '@risk-first/ui-core';
import { themeGet } from '@styled-system/theme-get';
import { withTheme } from 'emotion-theming';
import { rem } from 'polished';
import React, { FormEvent, useCallback } from 'react';
import { FlatButton, Label, Select } from '../../../styles';
import { ConstraintAssetTable, ConstraintEditIconComponent, ConstraintNameInput, HalfWidthInputBox } from './styles';
import { EditableConstraint } from './types';

interface ConstraintItemProps {
  assetNames: string[] | null;
  constraint: EditableConstraint;
  updateConstraint: (constraint: EditableConstraint) => void;
  deleteConstraint: (constraint: EditableConstraint) => void;
}

export const ConstraintItem = withTheme((props: ConstraintItemProps) => {
  const { assetNames, constraint, deleteConstraint, updateConstraint } = props;

  const handleActivateClick = useCallback(() => {
    updateConstraint({ ...constraint, active: !constraint.active });
  }, [constraint, updateConstraint]);

  const handleDeleteClick = useCallback(() => {
    deleteConstraint(constraint);
  }, [constraint, deleteConstraint]);

  const handleEditConstraint = useCallback(() => {
    updateConstraint({ ...constraint, editorOpen: !constraint.editorOpen });
  }, [constraint, updateConstraint]);

  const handleNameChange = useCallback(
    (event: FormEvent<HTMLInputElement>) => {
      updateConstraint({ ...constraint, name: event.currentTarget.value });
    },
    [constraint, updateConstraint],
  );

  const handleOperatorChange = useCallback(
    (event: FormEvent<HTMLSelectElement>) => {
      const operator = event.currentTarget.value as EditableConstraint['operator'];
      updateConstraint({ ...constraint, operator });
    },
    [constraint, updateConstraint],
  );

  const handleProportionChange = useCallback(
    (event: FormEvent<HTMLInputElement>) => {
      updateConstraint({ ...constraint, proportion: event.currentTarget.value });
    },
    [constraint, updateConstraint],
  );

  const handleControlAssetSelection = useCallback(
    (event: FormEvent<HTMLInputElement>) => {
      const assetName = event.currentTarget.value;
      const controlAssets = new Set<string>(constraint.controlAssets);
      if (event.currentTarget.checked) {
        controlAssets.add(assetName);
      } else {
        controlAssets.delete(assetName);
      }
      updateConstraint({ ...constraint, controlAssets });
    },
    [constraint, updateConstraint],
  );

  const handleReferenceAssetSelection = useCallback(
    (event: FormEvent<HTMLInputElement>) => {
      const assetName = event.currentTarget.value;
      const referenceAssets = new Set<string>(constraint.referenceAssets);
      if (event.currentTarget.checked) {
        referenceAssets.add(assetName);
      } else {
        referenceAssets.delete(assetName);
      }
      updateConstraint({ ...constraint, referenceAssets });
    },
    [constraint, updateConstraint],
  );

  return (
    <div key={constraint.id} aria-expanded={constraint.editorOpen} className="constraint-item">
      <Flex alignItems="center" borderBottom={`1px solid ${themeGet('colors.border')(props)}`} height="2rem" px={3}>
        <Checkbox
          aria-labelledby={`constraint-name-${constraint.id}`}
          checked={constraint.active}
          id={`constraint-active-${constraint.id}`}
          value="active"
          onChange={handleActivateClick}
        />
        <Box flex="1" pr={rem(16)}>
          <ConstraintNameInput
            aria-label="Constraint name"
            id={`constraint-name-${constraint.id}`}
            name={`constraint-name-${constraint.id}`}
            value={constraint.name}
            onChange={handleNameChange}
          />
        </Box>
        <FlatButton
          style={{ marginRight: '6px', outline: 'none' }}
          title="Edit constraint"
          type="button"
          onClick={handleEditConstraint}
        >
          <ConstraintEditIconComponent aria-expanded={constraint.editorOpen} />
        </FlatButton>
        <FlatButton
          style={{ color: themeGet('colors.text')(props), outline: 'none' }}
          title="Remove constraint"
          type="button"
          onClick={handleDeleteClick}
        >
          <CloseIconComponent />
        </FlatButton>
      </Flex>
      {constraint.editorOpen && (
        <Box borderBottom={`1px solid ${themeGet('colors.border')(props)}`}>
          <Flex
            alignItems="center"
            backgroundColor={themeGet('colors.athensGray')(props)}
            borderBottom={`1px solid ${themeGet('colors.border')(props)}`}
            justifyContent="space-between"
            p={3}
          >
            <HalfWidthInputBox>
              <Label htmlFor={`constraint-operator-${constraint.id}`}>Operator</Label>
              <Select
                id={`constraint-operator-${constraint.id}`}
                name={`constraint-operator-${constraint.id}`}
                required={true}
                value={constraint.operator}
                variant="default"
                onChange={handleOperatorChange}
              >
                <option value="">Select operator</option>
                <option value="<">&lt;</option>
                <option value="<=">&lt;=</option>
                <option value="=">=</option>
                <option value=">=">&gt;=</option>
                <option value=">">&gt;</option>
              </Select>
            </HalfWidthInputBox>
            <HalfWidthInputBox>
              <Label htmlFor={`constraint-proportion-${constraint.id}`}>Proportion (%)</Label>
              <Input
                id={`constraint-proportion-${constraint.id}`}
                value={constraint.proportion || ''}
                onChange={handleProportionChange}
              />
            </HalfWidthInputBox>
          </Flex>
          <ConstraintAssetTable>
            <thead>
              <tr>
                <th>Asset Name</th>
                <th>Control</th>
                <th>Reference</th>
              </tr>
            </thead>
            <tbody>
              {assetNames?.map((name, index) => (
                <tr key={index}>
                  <td>
                    <span id={`asset-name-${index}`}>{name}</span>
                  </td>
                  <td>
                    <Checkbox
                      aria-label="Toggle control asset active"
                      checked={constraint.controlAssets.has(name)}
                      id={`constraint-control-${index}`}
                      value={`${name}`}
                      onChange={handleControlAssetSelection}
                    />
                  </td>
                  <td>
                    <Checkbox
                      aria-label="Toggle reference asset active"
                      checked={constraint.referenceAssets.has(name)}
                      id={`constraint-reference-${index}`}
                      value={`${name}`}
                      onChange={handleReferenceAssetSelection}
                    />
                  </td>
                </tr>
              ))}
            </tbody>
          </ConstraintAssetTable>
        </Box>
      )}
    </div>
  );
});
