import { ChevronDownIconComponent } from '@risk-first/ui-assets';
import { FormTextFilter, TextFiltersMap } from '@risk-first/ui-text-filter';
import React, { useState, useRef, useCallback } from 'react';
import { useClickAway } from 'react-use';
import { FilterButton, FilterButtonIcon, MenuContainer } from './style';

interface SimpleFilterProps {
  title: string;
  filters: TextFiltersMap;
  onChange: (filters: TextFiltersMap) => void;
}

export const TableFilterMenu: React.FC<SimpleFilterProps> = ({ title, filters, onChange }: SimpleFilterProps) => {
  const [isOpen, setOpen] = useState<boolean>(false);
  const triggerRef = useRef<HTMLButtonElement>(null);
  const menuRef = useRef<HTMLDivElement>(null);

  useClickAway(menuRef, (event: MouseEvent) => {
    const trigger = triggerRef.current;
    if (!trigger || !trigger.contains(event.target as Node)) {
      setOpen(false);
    }
  });

  const openHandler = useCallback(() => setOpen((isOpen) => !isOpen), []);

  return (
    <span>
      <FilterButton ref={triggerRef} aria-expanded={isOpen} onClick={openHandler}>
        {title}{' '}
        <FilterButtonIcon>
          <ChevronDownIconComponent />
        </FilterButtonIcon>
      </FilterButton>
      {isOpen && (
        <MenuContainer ref={menuRef}>
          <FormTextFilter filters={filters} onChange={onChange} />
        </MenuContainer>
      )}
    </span>
  );
};
