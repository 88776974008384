import { CloseIconComponent, PlusIconComponent } from '@risk-first/ui-assets';
import { Box, Flex, H2 } from '@risk-first/ui-core';
import { TabMenu } from '@risk-first/ui-tab-menu';
import { themeGet } from '@styled-system/theme-get';
import { withTheme } from 'emotion-theming';
import React, { MouseEvent, useCallback, useState } from 'react';
import { TabButton } from '../../../styles';
import { GenerateConfigureSubPanel } from './GenerateConfigureSubPanel';
import { GenerateEfficientFrontierSubPanel } from './GenerateEfficientFrontierSubPanel';
import { GenerateEfficientPortfoliosSubPanel } from './GenerateEfficientPortfoliosSubPanel';
import { OptimizationList, SmallerFlatButton, TabContents, TabMenuStyleWrapper } from './styles';
import { OptimizationItem } from './types';

let lastOptimizationId = 0;

const initialOptimizations: OptimizationItem[] = Array.from({ length: 5 }).map((_, index) => ({
  id: lastOptimizationId++,
  name: `Optimization ${index + 1}`,
}));

export const GeneratePanel = withTheme((props) => {
  const [optimizations, setOptimizations] = useState<OptimizationItem[]>(initialOptimizations);

  const [activeOptimization, setActiveOptimization] = useState<number>(0);
  const handleOptimizationTabClick = useCallback((event: MouseEvent<HTMLButtonElement>) => {
    const button = event.currentTarget;
    const id = Number((/-([0-9]+)/.exec(button.id) || [])[1]);
    setActiveOptimization(id);
  }, []);

  const handleAddOptimization = useCallback(() => {
    // Make a name that's not currently in use
    const highestNameIndex = optimizations.reduce((max, optimization) => {
      const match = optimization.name.match(/Optimization ([0-9]+)/);
      return match ? Math.max(Number(match[1]), max) : max;
    }, 0);
    const name = `Optimization ${highestNameIndex + 1}`;

    setOptimizations(
      optimizations.concat([
        {
          id: lastOptimizationId++,
          name,
        },
      ]),
    );
  }, [optimizations]);

  const handleDeleteOptimization = useCallback(
    (event: MouseEvent) => {
      const button = event.currentTarget;
      const id = Number((/-([0-9]+)/.exec(button.id) || [])[1]);
      const index = optimizations.findIndex((o) => o.id === id);
      const newOptimizations = optimizations.filter((o) => o.id !== id);
      if (activeOptimization === id) {
        const newActive = newOptimizations[index < newOptimizations.length ? index : index - 1].id;
        setActiveOptimization(newActive);
      }
      setOptimizations(newOptimizations);
      event.stopPropagation();
    },
    [activeOptimization, optimizations],
  );

  const [activeTab, setActiveTab] = useState<string>('sg-tabpanel-id-0');
  const handleFormTabClick = useCallback((event: MouseEvent<HTMLButtonElement>) => {
    const button = event.currentTarget;
    const newActiveTab = button.getAttribute('aria-controls')!.toString();
    setActiveTab(newActiveTab);
  }, []);

  const tabLabels = ['Configure', 'Asset Statistics', 'Efficient Frontier', 'Efficient Portfolios'];

  return (
    <>
      <H2 fontWeight={themeGet('fontWeight.medium')(props)} p="20px 13px">
        Optimizations
      </H2>
      <Flex>
        {/* Left-hand selectable list of optimisations */}
        <Box width="230px">
          {/* Note: This component does not yet support accessibility shortcuts */}
          <OptimizationList role="tablist">
            {optimizations.map((o) => (
              <TabButton
                key={`sg-opt-tab-id-${o.id}`}
                aria-labelledby={`sg-opt-tab-name-id-${o.id}`}
                aria-selected={activeOptimization === o.id}
                id={`sg-opt-tab-id-${o.id}`}
                role="tab"
                onClick={handleOptimizationTabClick}
              >
                <Flex justifyContent="space-between" width="100%">
                  <Box id={`sg-opt-tab-name-id-${o.id}`}>{o.name}</Box>
                  {optimizations.length > 1 && (
                    <CloseIconComponent
                      aria-label="Delete"
                      id={`sg-opt-tab-delete-id-${o.id}`}
                      onClick={handleDeleteOptimization}
                    />
                  )}
                </Flex>
              </TabButton>
            ))}
          </OptimizationList>
          <Box mr="20px" mt="10px">
            <SmallerFlatButton style={{ float: 'right' }} onClick={handleAddOptimization}>
              <PlusIconComponent />
              <Box ml={2}>New Optimization</Box>
            </SmallerFlatButton>
          </Box>
        </Box>

        {/* Tabbed list of panels */}
        <Box flex="1">
          <TabMenuStyleWrapper>
            <TabMenu
              items={tabLabels.map((name, index) => ({
                'aria-controls': `sg-tabpanel-id-${index}`,
                'aria-selected': activeTab === `sg-tabpanel-id-${index}`,
                id: `sg-tabpanel-id-${index}`,
                label: name,
                role: 'tab',
                type: 'button',
                onClick: handleFormTabClick,
              }))}
              variant="secondary"
            />
          </TabMenuStyleWrapper>

          <TabContents>
            {activeTab === 'sg-tabpanel-id-0' && <GenerateConfigureSubPanel />}
            {activeTab === 'sg-tabpanel-id-1' && 'Asset Statistics'}
            {activeTab === 'sg-tabpanel-id-2' && (
              <GenerateEfficientFrontierSubPanel
                optimization={optimizations.find((o) => o.id === activeOptimization)!}
              />
            )}
            {activeTab === 'sg-tabpanel-id-3' && (
              <GenerateEfficientPortfoliosSubPanel
                optimization={optimizations.find((o) => o.id === activeOptimization)!}
              />
            )}
          </TabContents>
        </Box>
      </Flex>
    </>
  );
});
