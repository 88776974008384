import styled from '@emotion/styled';
import { Box } from '@risk-first/ui-core';
import { themeGet } from '@styled-system/theme-get';
import { rgba } from 'polished';

export const MarketEventsTableWrapper = styled(Box)`
  thead th {
    font-weight: ${themeGet('fontWeight.medium')};
    position: static;
  }

  thead th:first-of-type,
  td:first-of-type {
    padding-left: 1.25rem;
    width: 20%;
  }

  thead tr,
  td {
    width: 13.3%;
  }

  thead tr:last-of-type,
  td:last-of-type {
    width: 20%;
  }

  thead th:nth-of-type(n + 2):nth-of-type(-n + 4) {
    text-align: right;
  }
  td:nth-of-type(n + 2):nth-of-type(-n + 4) > div {
    display: block;
    text-align: right;
  }
  thead th:last-of-type {
    text-align: center;
  }

  tbody td {
    color: ${(props: any) => rgba(themeGet('colors.text')(props), 0.8)};
  }
  tbody tr:nth-of-type(6) td {
    font-weight: ${themeGet('fontWeight.medium')};
  }

  tbody tr:last-of-type td {
    border-top: 1px solid ${themeGet('colors.border')};
  }

  /* The magic chart table cell - the div inside takes up the whole height of the tbody */
  /* It'd be nice to have rowspan, but here we are */
  tbody tr:nth-of-type(1) > td:last-of-type {
    position: relative;
  }
  tbody tr:nth-of-type(1) > td:last-of-type > div {
    position: absolute;
    display: block;
    left: 0;
    right: 0;
    top: 0;
    height: 700%;
    padding-left: 1.25rem;
  }
`;

export const NetAssetsMovementDescriptionList = styled.dl`
  color: ${themeGet('colors.text')};
  text-align: right;

  dt {
    font-size: ${themeGet('fontSizes.1')};
    font-weight: ${themeGet('fontWeight.medium')};
    opacity: 0.75;
  }

  dd {
    font-size: ${themeGet('fontSizes.4')};
    margin-top: 0.375rem;
  }
`;
