import { SingleStrategies } from '../components/EfficientFrontier/types';
import { callAPI } from '.';

/**
 * Get the Strategy filename from the ID given.
 * The id passed in will be a zero-indexed number in a string like '0', or '9' but the file name
 * will be single-strategy_00.json or single-strategy_09.json
 */
export function getStrategyFilenameById(id: string) {
  const zeroIndexedSelectedPortfolio: string = id;
  const paddedSelectedPortfolio = zeroIndexedSelectedPortfolio.padStart(2, '0');
  const filename = `single-strategy-${paddedSelectedPortfolio}.json`;

  return filename;
}

/**
 * Return the full single-strategy data
 */
export function getStrategy(id: string, abortController?: AbortController) {
  const filename = getStrategyFilenameById(id);
  const init: RequestInit | undefined = abortController && { signal: abortController.signal };
  const json = callAPI(`/api/${filename}`, init);

  return json;
}

/**
 *  Return the full single-strategy data wrapped in an object which also contains a strategyId key/value
 */
export async function getStrategyWithId(id: string) {
  const filename = getStrategyFilenameById(id);
  const json = await callAPI(`/api/${filename}`);

  if (json) {
    const strategyWithId = {
      strategyId: id,
      data: json,
    };

    return strategyWithId;
  }
}

/**
 * Retrieve the Strategies data from an array of `id` values
 * e.g. `['0', '5', '21']` should fetch
 * - /api/single-strategy-00.json
 * - /api/single-strategy-05.json
 * - /api/single-strategy-21.json
 * which will return
 * [
 *  {
 *    strategyId: '0',
 *    data: {}
 *  },
 *  {
 *    strategyId: '5',
 *    data: {}
 *  },
 *
 *  {
 *    strategyId: '21',
 *    data: {}
 *  }
 * ]
 * Note: Nest the JSON inside a `data` array so we can match each data set to
 * the `strategyId` key in other API data.
 */
export async function getStrategiesByIds(ids: string[]) {
  const strategies: SingleStrategies[] = [];

  try {
    const values = await Promise.all(ids.map((id) => getStrategyWithId(id)));

    if (values) {
      values.forEach((value) => {
        if (value !== undefined) {
          const strategy: SingleStrategies = value;
          strategies.push(strategy);
        }
      });
    }

    return strategies;
  } catch (error) {
    console.error(error);
  }
}
