import { Box, Button, Flex, H2, Input, TextCtaButton } from '@risk-first/ui-core';
import { Dialog } from '@risk-first/ui-dialog';
import { rem } from 'polished';
import React, { FormEvent, useCallback, useEffect, useMemo, useState } from 'react';
import { Portfolio } from '../../../features/portfolios/PortfolioState';
import { useThemeVal } from '../../../lib/style-helper/theme';
import { Label } from '../../../styles';
import { ErrorMessage } from './styles';

interface SaveStrategyDialogProps {
  isOpen: boolean;
  setOpen: (isOpen: boolean) => void;
  strategy: Portfolio;
  strategies: Portfolio[];
  onSave: (name: string) => void;
}

export const SaveStrategyDialog = (props: SaveStrategyDialogProps) => {
  const { isOpen, onSave, strategy, setOpen, strategies } = props;

  const [strategyName, setStrategyName] = useState<string | undefined>();

  const error: string | undefined = useMemo(() => {
    if (strategyName !== undefined) {
      if (strategyName.length === 0) {
        return 'The strategy name is empty, please enter a strategy name.';
      } else if (strategies.some((s) => s.name === strategyName)) {
        return 'This strategy name is already in use, please enter a unique strategy name.';
      }
    }
    return undefined;
  }, [strategies, strategyName]);

  useEffect(() => {
    // When the dialog is opened, make sure it has the current strategy name (not the old state)
    if (isOpen) {
      setStrategyName(strategy.name);
    }
  }, [isOpen, strategy]);

  const handleDialogToggle = useCallback(() => setOpen(!isOpen), [isOpen, setOpen]);

  const handleChange = useCallback(
    (event: FormEvent<HTMLInputElement>) => setStrategyName(event.currentTarget.value),
    [],
  );

  const handleSave = useCallback(() => {
    handleDialogToggle();
    onSave(strategyName!);
  }, [handleDialogToggle, onSave, strategyName]);

  const themeVal = useThemeVal();
  const panelBorderStyle = `1px solid ${themeVal('colors.border')}`;

  return (
    <Dialog ariaLabel="Default New Strategy Data" isOpen={isOpen} toggleDialog={handleDialogToggle}>
      <H2 fontWeight={`${themeVal('fontWeight.medium')}`} marginBottom={rem(20)}>
        Save Strategy
      </H2>
      {/* This is a fair amunt of hassle just to get the top border to go right to the edges of the dialog */}
      <Box borderTop={panelBorderStyle} marginX={`-${rem(32)}`} paddingX={rem(32)} pt={rem(20)}>
        <Label htmlFor="save-strategy-strategy-name">Strategy name</Label>
        <Input
          aria-errormessage="save-strategy-error-message"
          aria-invalid={error !== undefined}
          id="save-strategy-strategy-name"
          name="strategy-name"
          style={{ width: '100%' }}
          value={strategyName}
          variant="default"
          onChange={handleChange}
        />
        <ErrorMessage
          id="save-strategy-error-message"
          mb={rem(16)}
          minHeight={themeVal('fontSizes.0')}
          minWidth={rem(450)}
          mt={rem(8)}
        >
          {error}
        </ErrorMessage>
      </Box>

      <Flex>
        <Button disabled={error !== undefined} size="small" variant="primary" onClick={handleSave}>
          Save strategy
        </Button>
        <Box width={rem(20)} />
        <TextCtaButton ml={rem(20)} onClick={handleDialogToggle}>
          Cancel
        </TextCtaButton>
      </Flex>
    </Dialog>
  );
};
