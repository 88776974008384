import { ChevronDownIconComponent } from '@risk-first/ui-assets';
import { NativeColumn, NativeTable } from '@risk-first/ui-native-table';
import React, { useMemo } from 'react';
import { Portfolio } from '../../../features/portfolios/PortfolioState';
import { StrategyValueTableWrapper } from './styles';

const columns: NativeColumn[] = [
  {
    field: 'name',
    headerName: 'Strategy',
  },
  {
    field: 'assets',
    headerName: 'Assets',
  },
  {
    field: 'liabilities',
    headerName: 'Liabilities',
  },
  {
    field: 'surplus',
    headerName: 'Surplus / (Deficit)',
  },
  {
    field: 'movement',
    headerName: 'Movement',
  },
  {
    field: '',
    headerName: '',
    cellRendererFramework: () => <ChevronDownIconComponent style={{ transform: 'scale(1.5)' }} />,
  },
];

interface StrategyValueTableProps {
  existingStrategy: Portfolio;
  newStrategy: Portfolio;
}

export const StrategyValueTable = ({ existingStrategy, newStrategy }: StrategyValueTableProps) => {
  const rowData = useMemo(
    () => [
      {
        name: existingStrategy.name,
        assets: '125,000,000',
        liabilities: '105,252,687',
        surplus: '19,747,313',
        movement: '0.00%',
      },
      {
        name: newStrategy.name,
        assets: '125,000,000',
        liabilities: '105,252,687',
        surplus: '19,747,313',
        movement: '0.00%',
      },
    ],
    [existingStrategy, newStrategy],
  );

  return (
    <StrategyValueTableWrapper>
      <NativeTable columns={columns} getRowNodeId={({ name }) => name} rowData={rowData} />
    </StrategyValueTableWrapper>
  );
};
