import { useAuth0 } from '@auth0/auth0-react';
import { LogoutOptions } from '@auth0/auth0-spa-js';
import styled from '@emotion/styled/macro';
import { AppHeader } from '@risk-first/ui-app-header';
import { Box } from '@risk-first/ui-core';
import { themeGet } from '@styled-system/theme-get';
import { withTheme } from 'emotion-theming';
import React, { useCallback } from 'react';
import { AccessToken } from '../app/auth/AccessToken';

const Wrapper = styled(Box)`
  background-color: ${themeGet('colors.lightGrayishBlue')};
  color: ${themeGet('colors.dark')};
  font-family: ${themeGet('fontFamily')};
  min-height: 100vh;
`;

export const Layout: React.FC = withTheme((props) => {
  const { isAuthenticated, user, logout } = useAuth0();
  const logo = React.useMemo(() => <span>RiskIntegrity&trade; Investment Insight</span>, []);
  // Ensure Auth0 logout goes to our login page.
  // Make sure the URL is set in the Auth0 management console's 'Allowed Logout URLs' list
  const handleLogout = useCallback(() => {
    const returnTo = `${window.location.origin}/login`;
    const logoutOptions: LogoutOptions = {
      returnTo,
    };
    logout(logoutOptions);
  }, [logout]);

  return (
    <Wrapper>
      {isAuthenticated && (
        <AppHeader
          hideMenuButton={true}
          logo={logo}
          user={{ name: user?.name, picture: user?.picture }}
          variant="dark"
          onLogout={handleLogout}
        />
      )}
      <main id="content">{props.children}</main>
      <AccessToken />
    </Wrapper>
  );
});
