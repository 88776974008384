import { Box, Button, Flex } from '@risk-first/ui-core';
import { themeGet } from '@styled-system/theme-get';
import { withTheme } from 'emotion-theming';
import { rem } from 'polished';
import React, { FormEvent, forwardRef, useCallback } from 'react';
import { CreateStrategiesMenuItemProps } from './types';

export const CreateStrategiesMenuItem = withTheme(
  forwardRef<HTMLLIElement, CreateStrategiesMenuItemProps>(({ actionItem, onClick, ...props }, forwardedRef) => {
    const handleOnClick = useCallback(
      (event: FormEvent<HTMLButtonElement>) => {
        if (actionItem.button.action !== undefined) {
          actionItem.button.action(event);
        }
        onClick(event);
      },
      [actionItem.button, onClick],
    );

    return (
      <li ref={forwardedRef} role="menuitem" tabIndex={-1}>
        <Flex>
          <Box>
            <Box
              fontSize={themeGet('fontSizes.1')(props)}
              mb={rem(3)}
              style={{ fontWeight: themeGet('fontWeight.medium')(props) }}
            >
              {actionItem.title}
            </Box>
            <Box fontSize={themeGet('fontSizes.0')(props)}>{actionItem.subtitle}</Box>
          </Box>
          <Box ml="auto">
            {actionItem.button.action !== undefined && !actionItem.button.link && (
              <Button minWidth={rem(90)} size="regular" type="button" variant="primary" onClick={handleOnClick}>
                {actionItem.button.text}
              </Button>
            )}
            {!actionItem.button.action && actionItem.button.link && (
              <a href={actionItem.button.link}>{actionItem.button.text}</a>
            )}
          </Box>
        </Flex>
      </li>
    );
  }),
);
