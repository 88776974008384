import { useAuth0 } from '@auth0/auth0-react';
import { ThemeProvider } from 'emotion-theming';
import { getUserLocale } from 'get-user-locale';
import React from 'react';
import { IntlProvider } from 'react-intl';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import { Loading } from '../components/Loading';
import { PrivateRoute } from '../lib/private-route';
import { Analysis, Callback, Home, Login, Strategies } from '../pages';
import { theme } from '../theme';

const App = () => {
  const currentLocale = getUserLocale();
  const { isLoading } = useAuth0();

  if (isLoading) {
    return <Loading height={'100vh'} />;
  }

  return (
    <ThemeProvider theme={theme}>
      <BrowserRouter>
        <IntlProvider locale={currentLocale}>
          <Switch>
            <Route component={Login} path="/login" />
            <Route path="/callback" render={(props) => <Callback {...props} />} />
            <PrivateRoute component={Analysis} path="/analysis/:id" />
            <PrivateRoute component={Analysis} path="/analysis" />
            <PrivateRoute component={Strategies} path="/strategies/:id/:tab?/:subtab?" />
            <PrivateRoute component={Strategies} path="/strategies" />
            <PrivateRoute exact component={Home} path="/" />
          </Switch>
        </IntlProvider>
      </BrowserRouter>
    </ThemeProvider>
  );
};

export default App;
