import { CloseIconComponent } from '@risk-first/ui-assets';
import { IconButton } from '@risk-first/ui-core';
import React, { FormEvent, useCallback } from 'react';
import { ReactComponent as ChangeIconComponent } from '../../../assets/icons/sync-alt.svg';
import { PortfolioAsset } from '../../../features/portfolios/PortfolioState';
import { GENERAL_DECIMAL_NUMBER_PATTERN } from '../../../lib/constants/validation';
import { NumberInput } from '../../../styles';
import { AssetsSelectorTable } from './styles';

interface RebalanceButtonProps {
  name: string;
  handleRebalance: (name: string) => void;
}
const RebalanceButton = ({ name, handleRebalance }: RebalanceButtonProps) => {
  const handleClick = useCallback(() => handleRebalance(name), [handleRebalance, name]);

  return (
    <IconButton
      aria-label="Rebalance allocation"
      aria-labelledby={`asset-allocation--${name}`}
      size="regular"
      variant="osiris"
      onClick={handleClick}
    >
      <ChangeIconComponent />
    </IconButton>
  );
};

interface AssetsSelectorProps {
  assets: PortfolioAsset[] | undefined;
  setAssets: (assets: PortfolioAsset[]) => void;
}

export const AssetsSelector = ({ assets, setAssets }: AssetsSelectorProps) => {
  const handleAssetValueUpdate = useCallback(
    (event: FormEvent<HTMLInputElement>) => {
      const name = event.currentTarget.getAttribute('data-asset-name');
      const value = parseFloat(event.currentTarget.value) || 0;
      setAssets(assets!.map((asset) => (asset.name === name ? { name, value } : asset)));
    },
    [assets, setAssets],
  );

  const handleRebalanceAllocation = useCallback(
    (name: string) => {
      // Note: item https://riskfirst.visualstudio.com/RiskFirst/_workitems/edit/33536 explicity
      // allows negative percent allocations for the demo
      const totalPercent = assets!.reduce((sum, { value }) => sum + (value || 0), 0);
      const percentDiscrepancy = totalPercent - 100;

      setAssets(
        assets!.map((asset) =>
          asset.name === name ? { ...asset, value: (asset.value || 0) - percentDiscrepancy } : asset,
        ),
      );
    },
    [assets, setAssets],
  );

  return (
    <AssetsSelectorTable>
      <thead>
        <tr>
          <th>UK Proxy</th>
        </tr>
      </thead>
      <tbody>
        {assets &&
          assets.map(({ name, value }) => {
            return (
              <tr key={name}>
                <td id={`asset-allocation--${name}`}>{name}</td>
                <td>
                  <RebalanceButton handleRebalance={handleRebalanceAllocation} name={name} />
                </td>
                <td>
                  <NumberInput
                    data-asset-name={name}
                    disabled={assets === undefined}
                    pattern={GENERAL_DECIMAL_NUMBER_PATTERN}
                    style={{ textAlign: 'right' }}
                    value={value !== null ? value.toString() : '0'}
                    onChange={handleAssetValueUpdate}
                  />
                </td>
                <td>
                  <IconButton aria-label="Reset allocation" size="regular" variant="osiris">
                    <CloseIconComponent />
                  </IconButton>
                </td>
              </tr>
            );
          })}
      </tbody>
    </AssetsSelectorTable>
  );
};
