export enum ValueAtRiskTypes {
  Attribution,
  Breakdown,
}

export interface ValueAtRiskDataPoint {
  name: string;
  color?: string;
  y: number;
}

export interface ValueAtRiskProps {
  height?: string;
  portfolio: string | undefined;
  type: ValueAtRiskTypes;
}

export interface ValueAtRiskData {
  name: string;
  data: number;
}

export interface ValueAtRiskJSON {
  strategyId: string;
  data: ValueAtRiskData[];
}
