import styled from '@emotion/styled';
import { ChevronDownIconComponent } from '@risk-first/ui-assets';
import { Box } from '@risk-first/ui-core';
import { themeGet } from '@styled-system/theme-get';
import { rem } from 'polished';

export const HeadingIcon = styled(ChevronDownIconComponent)`
  transform: rotate(180deg);
  [aria-expanded='true'] & {
    transform: none;
  }
  margin-right: 0.25rem;
`;

export const SectionHeading = styled.header`
  &[aria-expanded='true'] {
    border-bottom: 1px solid ${themeGet('colors.border')};
  }
  align-items: center;
  box-sizing: content-box;
  cursor: pointer;
  display: flex;
  font-size: ${themeGet('fontSizes.1')};
  font-weight: ${themeGet('fontWeight.medium')};
  height: ${rem(50)};
  justify-content: space-between;
  padding: 0 1rem;
  user-select: none;
`;

export const OuterBox = styled(Box)`
  border-bottom: 1px solid ${themeGet('colors.border')};
`;
