import { callAPI } from '.';

/**
 * Retrieve the Curve data from the JSON data folder
 * The id passed in will be a zero-indexed number in a string like '0', or '9' but the file name
 * will be DV01KRD_00.json or DV01KRD_09.json
 */
export function getCurve(portfolioId: string) {
  const zeroIndexedSelectedPortfolio: string = portfolioId;
  const paddedSelectedPortfolio = zeroIndexedSelectedPortfolio.padStart(2, '0');
  const filename = `DV01KRD_${paddedSelectedPortfolio}.json`;
  const json = callAPI(`/api/${filename}`);

  return json;
}
