import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ReactText } from 'react';

const initialState: ReactText[] = [];

const statisticsSlice = createSlice({
  name: 'metrics',
  initialState,
  reducers: {
    updateStatistics: (_, action: PayloadAction<ReactText[]>) => [...action.payload],
  },
});

export const { updateStatistics } = statisticsSlice.actions;

export const { reducer: statisticsReducer } = statisticsSlice;
