import { Box, Button } from '@risk-first/ui-core';
import { rem } from 'polished';
import React, { MouseEvent, useCallback, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '../../app/RootState';
import { selectAssessmentCharts } from '../../features/assessmentCharts/assessmentChartSlice';
import {
  AnalysisSettings,
  clearAnalysisSettingsLocalStorage,
  saveAnalysisSettingsLocalStorage,
  localStorageExists,
} from '../../lib/localStorage';

const FAKE_DELAY_LENGTH = 250;

export const SaveClearAnalysisSettings = () => {
  const charts = useSelector(selectAssessmentCharts);
  const { filters } = useSelector((state: RootState) => state.filter);
  const { selectedPortfolio } = useSelector((state: RootState) => state.portfolios);
  const settings: AnalysisSettings = useMemo(
    () => ({
      charts,
      filters,
      selectedPortfolio,
    }),
    [charts, filters, selectedPortfolio],
  );

  const [clearing, setClearing] = useState<boolean>(false);
  const [saving, setSaving] = useState<boolean>(false);

  const handleSave = useCallback(
    (event: MouseEvent<HTMLButtonElement>) => {
      setSaving(true);
      saveAnalysisSettingsLocalStorage(settings);
      setTimeout(() => {
        setSaving(false);
      }, FAKE_DELAY_LENGTH);
    },
    [settings],
  );

  const handleClear = useCallback((event: MouseEvent<HTMLButtonElement>) => {
    setClearing(true);
    clearAnalysisSettingsLocalStorage();
    setTimeout(() => {
      setClearing(false);
    }, FAKE_DELAY_LENGTH);
  }, []);

  return (
    <>
      {localStorageExists && (
        <Box ml="auto">
          <Button size="regular" type="button" variant="default" onClick={handleSave}>
            {saving ? 'Saving...' : 'Save settings'}
          </Button>

          <Button ml={2} mr={rem(-24)} size="regular" type="button" variant="clear" onClick={handleClear}>
            {clearing ? 'Clearing...' : 'Clear settings'}
          </Button>
        </Box>
      )}
    </>
  );
};
