import styled from '@emotion/styled';
import { Box } from '@risk-first/ui-core';
import { themeGet } from '@styled-system/theme-get';
import { rem } from 'polished';

export const Wrapper = styled(Box)`
  border-bottom: ${rem(1)} solid ${themeGet('colors.border')};
  color: ${themeGet('colors.darkGrayishBlue')};
  max-height: 518px;

  .highcharts-tooltip {
    pointer-events: auto !important;

    b {
      font-weight: ${themeGet('fontWeight.medium')};
    }

    .coordinate-info {
      margin: 0.2em 0;

      th {
        padding-right: 0.5em;
      }
    }

    .show-portfolio-detail {
      margin: 0.5em 0;
      padding: 0;
      border: none;
      background: none;
      font-size: inherit;
      font-weight: ${themeGet('fontWeight.bold')};
      color: ${themeGet('colors.darkPink')};
      text-decoration: underline;
      cursor: pointer;

      &:hover {
        text-decoration: none;
      }
    }

    /* WARNING: This is an atrocity - it hides the default text at the top of a tooltip formatted by
       pointFormatter which we are using here because formatter has an issue of an empty tooltip if
       the portfolio link is clicked.
    */
    & > span {
      span,
      br {
        display: none;
      }
    }
  }
`;
