import { ChevronDownIconComponent } from '@risk-first/ui-assets';
import { withTheme } from 'emotion-theming';
import React, { FormEvent, useEffect, useMemo, useRef, useState, useCallback } from 'react';
import uniqid from 'uniqid';
import { KEYS } from '../../lib/constants/keys';
import { ButtonIcon } from '../../styles';
import { CreateStrategiesMenuItem } from './CreateStrategiesMenuItem';
import { CloseButton, CreateButton, Menu, Wrapper } from './style';
import { ActiveMenuItem, CreateStrategiesActionItem, CreateStrategiesMenuProps } from './types';

export const CreateStrategiesMenu: React.FC<CreateStrategiesMenuProps> = withTheme(({ actionItems, ...props }) => {
  const [isActive, setIsActive] = useState<boolean>(false);
  const refWrapper = useRef<HTMLDivElement>(null);
  const refOpenButton = useRef<HTMLButtonElement>(null);
  const menuId = useMemo(() => uniqid('create-strategies-'), []);
  const [firstOpen, setFirstOpen] = useState<boolean>(false);
  const refsMenuItems = useRef<(HTMLLIElement | HTMLButtonElement)[]>([]);

  useEffect(() => {
    if (refWrapper && refWrapper.current) {
      refsMenuItems.current = new Array(actionItems.length);
    }
  }, [actionItems.length, refsMenuItems, refWrapper]);

  const focusOpenButton = useCallback(() => {
    if (refOpenButton.current) {
      refOpenButton.current.focus();
    }
  }, [refOpenButton]);

  const focusActionItem = useCallback(
    (index: number) => {
      if (refsMenuItems && refsMenuItems.current && refsMenuItems.current[index]) {
        refsMenuItems.current[index].focus();
      }
    },
    [refsMenuItems],
  );

  const handleOnToggleButtonClick = useCallback(
    (event: FormEvent<HTMLButtonElement>) => {
      event.preventDefault();
      const invertedIsActive = !isActive;

      setFirstOpen(invertedIsActive);
      setIsActive(invertedIsActive);
    },
    [isActive],
  );

  // set focus to first item in the list
  useEffect(() => {
    if (firstOpen === true) {
      focusActionItem(0);
    }
  }, [firstOpen, focusActionItem]);

  const closeMenu = useCallback(() => {
    setIsActive(false);
    setFirstOpen(false);
  }, []);

  const handleOnCloseButtonClick = useCallback(
    (event: FormEvent<HTMLButtonElement>): void => {
      closeMenu();

      // set focus back to the open button as there will be no visible item in focus now
      focusOpenButton();
    },
    [closeMenu, focusOpenButton],
  );

  const handleOnActionButtonClick = useCallback(
    (event: FormEvent<HTMLButtonElement>) => {
      event.preventDefault();
      closeMenu();

      // For the demo, set focus back to the open button as there will be no visible item in focus now
      // In production we won't do this as we assume the action will load a modal or a new page and
      // thus will take care of focus itself
      focusOpenButton();
    },
    [closeMenu, focusOpenButton],
  );

  const handleKeyDown = useCallback(
    (event) => {
      if (!refWrapper || !refWrapper.current) {
        return;
      }

      // Is the activeElement one of the menu items?
      // const menuItemsArray = menuItems ? Array.from(menuItems) : undefined;
      const activeElement = document.activeElement;
      const activeMenuItem: ActiveMenuItem | undefined = refsMenuItems.current
        ?.map((element, index) => {
          if (element === activeElement) {
            return {
              element,
              index,
            };
          } else {
            return undefined;
          }
        })
        .find((item) => item !== undefined);

      // Establish the last menuitem (not the close button though)
      const lastIndex: number = actionItems.length - 1;

      // When the open button is in focus but not active/open,
      // the UP/DOWN buttons should open the menu
      // UP should then focus the last menu item and
      // DOWN should focus the first
      if (activeElement === refOpenButton.current) {
        if (event.keyCode === KEYS.DOWN) {
          event.preventDefault();
          setIsActive(true);
          setFirstOpen(true);
        } else if (event.keyCode === KEYS.UP) {
          event.preventDefault();
          setIsActive(true);
          setFirstOpen(false);
          focusActionItem(lastIndex);
        }
      }

      // When the menu is open, different keys do different things
      // ESC = close
      // ENTER/SPACE = perform the action in the active menuitem
      // UP - go to previous menuitem (unless the first item is active in which case do nothing)
      // DOWN - go to next menuitem (unless the last item is active in which case do nothing)
      // TAB - close the menu and return to the document's original tab order (the latter happens automatically)
      // SHIFT + TAB - close the menu and return to the document's original tab order in reverse order (the latter happens automatically)
      // HOME - focus the first menuitem
      // END - focus the last menuitem
      if (isActive === true) {
        if (event.keyCode === KEYS.ESC || event.keyCode === KEYS.TAB) {
          closeMenu();
        } else if (event.keyCode === KEYS.UP) {
          if (activeMenuItem?.index && actionItems && activeMenuItem.index > 0) {
            const previousActionItem: number = activeMenuItem.index - 1;

            focusActionItem(previousActionItem);
          }

          // stop the page from moving upwards
          event.preventDefault();
        } else if (event.keyCode === KEYS.DOWN) {
          if (
            activeMenuItem &&
            activeMenuItem.index !== undefined &&
            actionItems &&
            actionItems.length >= activeMenuItem.index
          ) {
            const nextActionItem: number = activeMenuItem.index + 1;

            focusActionItem(nextActionItem);
          }

          // stop the page from moving downwards
          event.preventDefault();
        } else if (event.keyCode === KEYS.HOME) {
          focusActionItem(0);

          // stop the page from moving upwards
          event.preventDefault();
        } else if (event.keyCode === KEYS.END) {
          if (refsMenuItems && refsMenuItems.current) {
            focusActionItem(lastIndex);
          }

          // stop the page from moving downwards
          event.preventDefault();
        } else if (event.keyCode === KEYS.ENTER || event.keyCode === KEYS.SPACE) {
          // The menuitem has been designed to contain a button ಠ_ಠ (but it could easily be a <a> instead)
          // We need to let keyboard users focus the menuitem and then when they press ENTER/SPACE
          // it should activate the button/link inside it.
          // and we should allow mouse/touch users to click/tap the button/a as well
          if (activeElement && activeElement !== refOpenButton.current) {
            const clickableElements: string = 'a, button';
            const clickable: HTMLAnchorElement | HTMLButtonElement | null = activeElement.querySelector(
              clickableElements,
            );

            if (clickable) {
              // stop the ENTER/SPACE event bubbling to refOpenButton.current
              event.preventDefault();
              clickable.click();
            }
          }
        }
      }
    },
    [actionItems, closeMenu, focusActionItem, isActive],
  );

  // Look for keydowns so later we can check for 'Esc' presses and UP/DOWN arrows
  useEffect(() => {
    document.addEventListener('keydown', handleKeyDown, false);

    return () => {
      document.removeEventListener('keydown', handleKeyDown, false);
    };
  }, [handleKeyDown]);

  const handleDocumentClick = useCallback(
    (event: MouseEvent): void => {
      if (isActive !== true || !refWrapper.current) {
        return;
      }

      if (!refWrapper.current.contains(event.target as Node)) {
        closeMenu();
      }
    },
    [closeMenu, isActive],
  );

  // Look for clicks so we can see if they occur outside the element
  useEffect(() => {
    document.addEventListener('click', handleDocumentClick, false);

    return () => {
      document.removeEventListener('click', handleDocumentClick, false);
    };
  }, [handleDocumentClick]);

  return (
    <Wrapper ref={refWrapper}>
      <CreateButton
        ref={refOpenButton}
        aria-controls={menuId}
        aria-expanded={isActive}
        aria-haspopup="true"
        type="button"
        onClick={handleOnToggleButtonClick}
      >
        Create strategies{' '}
        <ButtonIcon>
          <ChevronDownIconComponent />
        </ButtonIcon>
      </CreateButton>
      <Menu hidden={!isActive} id={menuId}>
        <ul role="menu">
          {actionItems.map((actionItem: CreateStrategiesActionItem, index: number) => (
            <CreateStrategiesMenuItem
              key={actionItem.id}
              ref={(element: HTMLLIElement) => (refsMenuItems.current[index] = element)}
              actionItem={actionItem}
              onClick={handleOnActionButtonClick}
            />
          ))}
          <li role="menuitem" tabIndex={-1}>
            <CloseButton
              ref={(element: HTMLButtonElement) => (refsMenuItems.current[actionItems.length] = element)}
              type="button"
              onClick={handleOnCloseButtonClick}
            >
              Close
            </CloseButton>
          </li>
        </ul>
      </Menu>
    </Wrapper>
  );
});
