const rawData: number[][] = [
  [0.5, 0.15],
  [1.56, 0.58],
  [2.61, 1.0],
  [3.67, 1.41],
  [4.72, 1.83],
  [5.78, 2.21],
  [6.83, 2.55],
  [7.89, 2.85],
  [8.94, 3.14],
  [10.0, 3.42],
];

// Adds a seeded "random" value in the range [-0.2 .. +0.2] to a number
const perturb = (value: number, seed: number) =>
  value + 0.4 * Math.sin(1000 * (value + seed) + Math.sin(50 * (value + seed)));

// Returns deterministically different values for each optimization id
export const getData = (id: number) => {
  return id === 0
    ? [...rawData]
    : rawData.map(([x, y]) => [Number(perturb(x, id).toFixed(2)), Number(perturb(y, id).toFixed(2))]);
};

// Normalise column values to a total of 100
const normaliseArrayColumns = (arrays: number[][]): number[][] => {
  const transpose = (arrs: number[][]) => arrs[0].map((_, index) => arrs.map((array) => array[index]));

  return transpose(
    transpose(arrays).map((column) => {
      const max = column.reduce((total, x) => total + x, 0);
      return column.map((x) => x * (100 / max));
    }),
  );
};

export const getPercentageData = (): number[][] => {
  const percentageData: number[][] = [
    [0, 0, 0, 0, 0, 0, 1, 5, 10, 15, 20],
    [0, 3, 6, 9, 12, 15, 13, 11, 9, 7, 5],
    [0, 1, 2, 3, 4, 5, 7.5, 10, 20, 30, 40],
    [0, 7.5, 15, 22.5, 30, 50, 70, 60, 50, 40, 30],
    [100, 87.5, 75, 62.5, 50, 25, 2.5, 0, 0, 0, 0],
  ];

  return normaliseArrayColumns(percentageData);
};
