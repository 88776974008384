import { Box } from '@risk-first/ui-core';
import { withTheme } from 'emotion-theming';
import React, { FormEvent, useCallback, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Axis } from '../../components';
import {
  selectCurrentAssessmentChartAxes,
  currentAssessmentChartAxesUpdated,
} from '../../features/assessmentCharts/assessmentChartSlice';

export const AxisForm: React.FC = withTheme((props) => {
  const [formValid, setFormValid] = useState(true);
  const refForm = useRef<HTMLFormElement>(null);

  const dispatch = useDispatch();
  const formValues = useSelector(selectCurrentAssessmentChartAxes);

  // State for form fields
  const [form, setForm] = useState(formValues && { ...formValues });

  // Check is form is valid or not - relies on good HTML attributes on form elements
  const validateForm = useCallback(() => {
    if (refForm.current) {
      if (refForm.current.checkValidity()) {
        setFormValid(true);
      } else {
        setFormValid(false);
      }
    }
  }, []);

  const updateField = useCallback(
    (axisName: 'x' | 'y') => (name: string, value: string) => {
      validateForm();
      if (form) {
        setForm({
          ...form,
          [axisName]: {
            ...form[axisName],
            [name]: value,
          },
        });
      }
    },
    [form, validateForm],
  );
  const updateFieldX = useCallback((name: string, value: string) => updateField('x')(name, value), [updateField]);
  const updateFieldY = useCallback((name: string, value: string) => updateField('y')(name, value), [updateField]);

  useEffect(() => {
    if (form && formValid) {
      dispatch(currentAssessmentChartAxesUpdated(form));
    }
  }, [dispatch, form, formValid]);

  // Check form is valid or not on first render
  useEffect(() => {
    validateForm();
  }, [validateForm]);

  const setAxes = (event: FormEvent) => {
    event.preventDefault();
  };

  return form ? (
    <Box py={3}>
      <form ref={refForm} action="" method="post" noValidate={true} onSubmit={setAxes}>
        <Axis type="x" values={form.x} onFieldChange={updateFieldX} />
        <Axis type="y" values={form.y} onFieldChange={updateFieldY} />
      </form>
    </Box>
  ) : null;
});
