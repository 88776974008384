import { callAPI } from '.';

/**
 * Retrieve the Strategies Data
 */
export function getStrategies(date?: string | undefined) {
  const json = callAPI(`/api/strategies.json`);

  return json;
}
