import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ReactText } from 'react';

const initialState: ReactText[] = [];

const metricsSlice = createSlice({
  name: 'metrics',
  initialState,
  reducers: {
    updateMetrics: (_, action: PayloadAction<ReactText[]>) => [...action.payload],
  },
});

export const { updateMetrics } = metricsSlice.actions;

export const { reducer: metricsReducer } = metricsSlice;
