import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { getSavedAnalysisSettings } from '../../lib/localStorage';
import { PortfolioState, Portfolio } from './PortfolioState';

const analysisSavedSettings = getSavedAnalysisSettings();
const initialState: PortfolioState = {
  availablePortfolios: undefined,
  selectedPortfolio: analysisSavedSettings?.selectedPortfolio || undefined,
};

const portfoliosSlice = createSlice({
  name: 'portfolios',
  initialState,
  reducers: {
    updateAvailablePortfolios: (state, action: PayloadAction<Portfolio[] | undefined>) => {
      state.availablePortfolios = action.payload;
    },
    updateSelectedPortfolio: (state, action: PayloadAction<string>) => {
      state.selectedPortfolio = action.payload;
    },
    removeSelectedPortfolio: (state, action: PayloadAction<undefined>) => {
      state.selectedPortfolio = undefined;
    },
  },
});

export const { removeSelectedPortfolio, updateAvailablePortfolios, updateSelectedPortfolio } = portfoliosSlice.actions;

export const { reducer: portfoliosReducer } = portfoliosSlice;
