import styled from '@emotion/styled';
import { Flex } from '@risk-first/ui-core';
import { themeGet } from '@styled-system/theme-get';

export const CreateStrategiesPanelSelector = styled(Flex)`
  align-items: center;
  display: flex;
  font-size: ${themeGet('fontSizes.1')};
  margin-right: 1.25rem;

  /* We want a nice big label as an accessible click region */
  input + label {
    padding: 1.25rem 0 1.25rem 0.625rem;
  }
`;
