import styled from '@emotion/styled/macro';
import { themeGet } from '@styled-system/theme-get';
import { withTheme } from 'emotion-theming';
import React from 'react';
import { NavLink } from 'react-router-dom';

const StyledNav = styled.nav`
  > ul {
    align-items: center;
    display: flex;
    justify-content: center;
    margin: 0;
    padding: 0;
  }

  li {
    list-style: none;
    margin: 0;
    padding: 0;
  }

  a {
    color: ${themeGet('colors.brand.primary')};
    display: inline-block;
    padding: 0.25em;
    text-decoration: none;
  }

  .is-selected {
    text-decoration: underline;
  }
`;

export const Nav: React.FC = withTheme(() => {
  return (
    <StyledNav>
      <ul>
        <li>
          <NavLink exact activeClassName="is-selected" to="/">
            Home
          </NavLink>
        </li>
        <li>
          <NavLink activeClassName="is-selected" to="/analysis">
            Analysis
          </NavLink>
        </li>
      </ul>
    </StyledNav>
  );
});
