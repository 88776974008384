import styled from '@emotion/styled';
import { Box } from '@risk-first/ui-core';
import { themeGet } from '@styled-system/theme-get';
import { rem } from 'polished';

export const EmptyPortfolio = styled(Box)`
  place-items: center;
  background: ${themeGet('colors.white')};
  display: flex;
  justify-content: center;
  margin: ${themeGet('space.3')}px 0;
  min-height: 10em;
`;

export const OverviewGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 0;

  /* 60em = 960px - a good place to break before the sidebar covers the right-side charts */
  ${themeGet('mediaQueries.medium')} {
    display: block;
  }
`;

export const ChartContainer = styled(Box)`
  padding-bottom: ${rem(5)};
  padding-left: ${rem(0)};
  padding-right: ${[rem(2), rem(8)]};
  padding-top: ${rem(25)};
`;

export const ChartTitle = styled.h3`
  letter-spacing: ${rem(-0.12)};
  font-size: ${themeGet('fontSizes.1')};
  font-weight: ${themeGet('fontWeight.medium')};
  margin-left: ${rem(33)};
`;
