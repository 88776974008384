import styled from '@emotion/styled/macro';
import { CalendarIconComponent } from '@risk-first/ui-assets';
import { Box, Flex, H1 } from '@risk-first/ui-core';
import { DatePickerInput } from '@risk-first/ui-date-picker-input';
import { Columns, RowData, Table, TableRowClickedEvent } from '@risk-first/ui-table';
import { themeGet } from '@styled-system/theme-get';
import { GridApi, GridReadyEvent, RowClickedEvent } from 'ag-grid-community';
import { withTheme } from 'emotion-theming';
import moment from 'moment';
import { rem } from 'polished';
import React, { useCallback, useRef, useState, useEffect } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { useMountedState, useSearchParam } from 'react-use';
import { getLineOfBusiness } from '../api/lineOfBusiness';
import { Layout, Loading } from '../components';
import { useResizeTable } from '../hooks/useResizeTable';
import { DEFAULT_DATE } from '../lib/constants/dates';
import { APP_NAME } from '../lib/constants/pageTitles';
import { LOBTableWrapper } from '../styles';
import { getFixedLocaleNumberFormatter } from '../utils';

const numberFormatter = getFixedLocaleNumberFormatter();

// take the date value from row's props data
const SchemeNameRenderer = (props: any) => {
  const { date } = props.data;
  const url = date ? `/strategies/${props.data.id}?date=${encodeURIComponent(date)}` : `/strategies/${props.data.id}`;

  return <Link to={url}>{props.value}</Link>;
};

const DatePickerInputWrapper = styled.div`
  z-index: 1;
  input {
    font-size: ${themeGet('fontSizes.0')};
    width: 100%;
  }
`;

export const Home: React.FC = withTheme((props) => {
  const history = useHistory();
  const refTableWrapper = useRef<HTMLDivElement | null>(null);
  const isMounted = useMountedState();
  const [gridApi, setGridApi] = useState<GridApi>();
  const currentSelectedDate: string = useSearchParam('date') || '';
  const selectedDate: Date = moment(currentSelectedDate).toDate();
  const [date, setDate] = useState<Date>(currentSelectedDate ? selectedDate : DEFAULT_DATE);
  const [loading, setLoading] = useState<boolean>(true);

  const columns: Columns = [
    {
      headerName: 'Line of Business',
      field: 'lineOfBusiness',
      sortable: false,
      width: 700,
      cellRendererFramework: SchemeNameRenderer,
    },
    {
      headerName: 'Liability Value (Regulatory)',
      field: 'liabilityValueRegulatory',
      sortable: false,
      valueFormatter: (params) => numberFormatter.format(parseInt(params.value, 10)),
      flex: 1,
      cellStyle: {
        'justify-content': 'flex-end',
      },
    },
    {
      headerName: 'Liability Value (Accounting)',
      field: 'liabilityValueAccounting',
      sortable: false,
      valueFormatter: (params) => numberFormatter.format(parseInt(params.value, 10)),
      flex: 1,
      cellStyle: {
        'justify-content': 'flex-end',
      },
    },
  ];

  // Ensure AG Grid-powered table resizes as the viewport does
  const [rowData, setRowData] = useState<RowData | undefined>(undefined);

  useEffect(() => {
    async function fetchData() {
      const importedData = await getLineOfBusiness();

      if (importedData && isMounted()) {
        setRowData(importedData);
        setLoading(false);
      }
    }

    fetchData();
  }, [isMounted]);

  // Ensure AG Grid-powered table resizes as the viewport does
  const handleGridReady = useCallback(
    (event: GridReadyEvent) => {
      if (isMounted()) {
        setGridApi(event.api);
      }
    },
    [isMounted],
  );

  useResizeTable(refTableWrapper.current, gridApi);

  // update rowData with date when it changes
  useEffect(() => {
    if (rowData && rowData.length && rowData[0].date !== currentSelectedDate) {
      setRowData(
        rowData.map((row) => ({
          ...row,
          date: currentSelectedDate,
        })),
      );
    }
  }, [currentSelectedDate, rowData]);

  const onDateChange = useCallback(
    (date: Date) => {
      setDate(date);
      const selectedDate = moment(date).format('YYYY-MM-DD');
      const params = new URLSearchParams(history.location.search);
      params.set('date', selectedDate);
      history.push(`${history.location.pathname}?${params}`);
    },
    [history],
  );

  useEffect(() => {
    document.title = APP_NAME;
  }, []);

  const handleRowClicked = useCallback(
    (event: TableRowClickedEvent) => {
      const originalRowClickedEvent = event.originalEvent as RowClickedEvent;
      const id = originalRowClickedEvent.data.id;
      const url = currentSelectedDate
        ? `/strategies/${id}?date=${encodeURIComponent(currentSelectedDate)}`
        : `/strategies/${id}`;
      history.push(url);
    },
    [currentSelectedDate, history],
  );

  return (
    <Layout>
      <Box p={[3, 4]}>
        <Flex alignItems="center" mb={3}>
          <H1 mb={rem(5)}>Line of Business</H1>
          <Box ml="auto" width={rem(130)}>
            <Flex alignItems="center">
              <DatePickerInputWrapper>
                <DatePickerInput date={date} format="dd/mm/yyyy" onDateChange={onDateChange} />
              </DatePickerInputWrapper>
              <Box color={themeGet('colors.darkPink')(props)} ml={2}>
                <CalendarIconComponent />
              </Box>
            </Flex>
          </Box>
        </Flex>
        <LOBTableWrapper ref={refTableWrapper}>
          {loading && (
            <Flex bg="white" height={'calc(100vh - 180px)'} justifyContent={'center'} mt={rem(20)}>
              <Loading />
            </Flex>
          )}
          {!loading && (
            <Table
              columns={columns}
              frameworkComponents={{ SchemeNameRenderer: SchemeNameRenderer }}
              rowData={rowData}
              suppressCellSelection={true}
              suppressRowClickSelection={true}
              onGridReady={handleGridReady}
              onRowClicked={handleRowClicked}
            />
          )}
        </LOBTableWrapper>
      </Box>
    </Layout>
  );
});
