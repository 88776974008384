import { callAPI } from '.';

/**
 * Retrieve the ValueAtRisk (VaR) attribution data
 */
export function getValueAtRiskAttribution(portfolioId: string) {
  const filename = `var-attribution.json`;
  const json = callAPI(`/api/${filename}`);

  return json;
}

/**
 * Retrieve the ValueAtRisk (VaR) breakdown data
 */
export function getValueAtRiskBreakdown(portfolioId: string) {
  const filename = `var-breakdown.json`;
  const json = callAPI(`/api/${filename}`);

  return json;
}
