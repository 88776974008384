import { withAuthenticationRequired } from '@auth0/auth0-react';
import React, { useMemo } from 'react';
import { Route, RouteProps } from 'react-router-dom';
import { Loading } from '../../components/Loading';

interface PrivateRouteProps extends RouteProps {
  path: string;
  component: React.FC;
}

export const PrivateRoute: React.FC<PrivateRouteProps> = ({ component, ...args }) => {
  const memoizedComponent = useMemo(
    () => withAuthenticationRequired(component, { onRedirecting: () => <Loading height={'100vh'} /> }),
    [component],
  );

  return <Route component={memoizedComponent} {...args} />;
};
