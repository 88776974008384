import { Box, H2 } from '@risk-first/ui-core';
import { LineChart } from '@risk-first/ui-line-chart';
import { themeGet } from '@styled-system/theme-get';
import { withTheme } from 'emotion-theming';
import { Options, Point, SeriesLineOptions } from 'highcharts';
import { rem } from 'polished';
import React from 'react';
import { htmlEntities } from '../../../utils';
import { getData } from './demoData';
import { EfficientFrontierChartContainer, EfficientFrontierDataTable } from './styles';
import { OptimizationItem } from './types';

interface GenerateEfficientFrontierSubPanelProps {
  optimization: OptimizationItem;
}

export const GenerateEfficientFrontierSubPanel: React.FC<GenerateEfficientFrontierSubPanelProps> = withTheme(
  (props: GenerateEfficientFrontierSubPanelProps) => {
    const { optimization } = props;
    const labelFormatter = (value: number) => `${value.toFixed(2)}%`;

    const xAxisLabel = 'Volatility of Return vs Benchmark (%pa)';
    const yAxisLabel = 'Expected Nominal Return vs Benchmark (%pa)';

    const data = getData(optimization.id || 0);

    const formatTooltip: Highcharts.FormatterCallbackFunction<Point> = function (this) {
      const { x, y, series } = this;
      let tooltipContent = `<b>${htmlEntities(series.name)}</b><br/>`;
      tooltipContent += `<table class="coordinate-info">
                        <tbody>
                          <tr>
                            <th>${htmlEntities(xAxisLabel)}:</th>
                            <td>${labelFormatter(x)}</td>
                          </tr>
                          <tr>
                            <th>${htmlEntities(yAxisLabel)}:</th>
                            <td>${labelFormatter(y!)}</td>
                          </tr>
                        </tbody>
                      </table>`;
      return tooltipContent;
    };

    const chartOptions: Options = {
      chart: {
        style: {
          fontSize: rem(10),
        },
        marginRight: 11,
      },
      plotOptions: {
        line: {
          marker: {
            enabled: true,
            radius: 6,
          },
          lineWidth: 1,
        },
      },
      tooltip: {
        formatter: undefined, // remove the default formatter
        pointFormatter: formatTooltip,
        useHTML: true,
      },
      legend: {
        align: 'left',
        enabled: true,
        itemStyle: {
          color: themeGet('colors.chartTextColor')(props),
          fontSize: rem(10),
          fontWeight: themeGet('fontWeight.regular')(props),
          marginRight: rem(5),
        },
        symbolHeight: 10,
        symbolRadius: 5,
        symbolWidth: 10,
        verticalAlign: 'bottom',
        x: 11,
      },
      xAxis: {
        title: {
          text: xAxisLabel,
          style: {
            color: themeGet('colors.chartTextColor')(props),
            fontSize: rem(10),
            fontWeight: themeGet('fontWeight.medium')(props),
          },
        },
        tickWidth: 0,
        labels: {
          formatter: function (this) {
            return labelFormatter(this.value);
          },
          style: {
            fontSize: rem(10),
          },
        },
      },
      yAxis: {
        title: {
          text: yAxisLabel,
          style: {
            color: themeGet('colors.chartTextColor')(props),
            fontSize: rem(10),
            fontWeight: themeGet('fontWeight.medium')(props),
          },
        },
        labels: {
          formatter: function (this) {
            return labelFormatter(this.value);
          },
          style: {
            fontSize: rem(10),
          },
        },
        tickPixelInterval: 40,
      },
      series: [
        {
          name: optimization.name,
          color: '#789BCF',
          data: data,
        },
      ] as SeriesLineOptions[],
    };

    return (
      <>
        <H2 fontWeight={themeGet('fontWeight.medium')(props)} mb={rem(10)} ml={rem(33)} mt={rem(25)}>
          Efficient Frontier
        </H2>
        <EfficientFrontierChartContainer
          borderBottom={`1px solid ${themeGet('colors.border')(props)}`}
          height={rem(450)}
        >
          <LineChart options={chartOptions} />
        </EfficientFrontierChartContainer>
        <Box p={rem(20)}>
          <EfficientFrontierDataTable>
            <thead>
              <tr>
                <th>Metric</th>
                {data.map((_, index) => (
                  <th key={index}>
                    {index + 1 >= 10 ? '' : '\u00A0'}F{index + 1}
                  </th>
                ))}
              </tr>
            </thead>
            <tbody>
              <tr>
                <th>{xAxisLabel}</th>
                {data.map(([x, _], index) => (
                  <td key={index}>{x.toFixed(2)}%</td>
                ))}
              </tr>
              <tr>
                <th>{yAxisLabel}</th>
                {data.map(([_, y], index) => (
                  <td key={index}>{y.toFixed(2)}%</td>
                ))}
              </tr>
            </tbody>
          </EfficientFrontierDataTable>
        </Box>
      </>
    );
  },
);
