import { Box } from '@risk-first/ui-core';
import { rem } from 'polished';
import * as React from 'react';
import { Portfolio, PortfolioAsset } from '../../features/portfolios/PortfolioState';
import { StyledAssetAllocationTable } from './styles';

export interface AssetAllocationTableProps {
  portfolio: Portfolio;
}

export const AssetAllocationTable = ({ portfolio }: AssetAllocationTableProps) => {
  const nonZeroAssets: PortfolioAsset[] = React.useMemo(() => portfolio.assets.filter((asset) => asset.value), [
    portfolio,
  ]);

  return (
    <Box p={rem(10)}>
      <StyledAssetAllocationTable>
        <thead>
          <tr>
            <th>Asset Class</th>
            <th>
              <span>Allocation %</span>
            </th>
          </tr>
        </thead>
        <tbody>
          {nonZeroAssets.map((asset) => (
            <tr key={asset.name}>
              <td>{asset.name}</td>
              <td>
                <span>{asset.value}</span>
              </td>
            </tr>
          ))}
        </tbody>
      </StyledAssetAllocationTable>
    </Box>
  );
};
