import { themeGet } from '@styled-system/theme-get';
import { Options, XAxisOptions, YAxisOptions } from 'highcharts';
import { rem } from 'polished';

export interface DefaultOptions extends Options {
  xAxis: XAxisOptions;
  yAxis: YAxisOptions;
}

export const getDefaultChartOptions = (props: { theme: any }) => {
  return {
    chart: {
      backgroundColor: 'transparent',
    },
    xAxis: {
      labels: {
        style: {
          color: themeGet('colors.chartTextColor')(props),
          fontSize: rem(9),
          fontWeight: themeGet('fontWeight.medium')(props),
          opacity: 0.8,
        },
      },
      lineColor: themeGet('colors.chartDashes')(props),
      title: {
        style: {
          color: themeGet('colors.chartTextColor')(props),
          fontSize: rem(10),
          opacity: 0.5,
        },
      },
    },
    yAxis: {
      gridLineColor: themeGet('colors.chartDashes')(props),
      gridLineDashStyle: 'ShortDash',
      labels: {
        style: {
          color: themeGet('colors.chartTextColor')(props),
          fontSize: rem(9),
          fontWeight: themeGet('fontWeight.medium')(props),
          opacity: 0.8,
        },
      },
      title: {
        style: {
          color: themeGet('colors.chartTextColor')(props),
          fontSize: rem(10),
          opacity: 0.5,
        },
      },
    },
  };
};
