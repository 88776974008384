import { useAuth0 } from '@auth0/auth0-react';
import React, { useEffect } from 'react';
import { Loading } from '../components';

export const Callback = (props: any) => {
  const { isAuthenticated } = useAuth0();

  useEffect(() => {
    if (isAuthenticated) {
      props.history.replace('/');
    } else {
      props.history.replace('/login');
    }
  }, [isAuthenticated, props.history]);

  return <Loading height="100vh" />;
};
