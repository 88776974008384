import { AssessmentChart } from '../features/assessmentCharts/AssessmentChartState';
import { FilterStateValues } from '../features/filter/FilterState';
import { Portfolio } from '../features/portfolios/PortfolioState';
import { LS_KEY } from '../lib/constants/localStorage';

export interface AnalysisSettings {
  charts?: AssessmentChart[];
  filters?: FilterStateValues[];
  selectedPortfolio?: string | undefined;
  defaultStrategyForAnalysis?: Portfolio;
}

// Test for localStorage availability. Copy the approach of Modernizer
function localStorageTest() {
  const test = 'test';

  try {
    localStorage.setItem(test, test);
    localStorage.removeItem(test);

    return true;
  } catch (e) {
    return false;
  }
}

export const localStorageExists = localStorageTest();

export function getSavedAnalysisSettings(): AnalysisSettings | undefined {
  const analysisSettings: string | null = localStorageExists ? localStorage.getItem(LS_KEY) : null;

  if (analysisSettings) {
    try {
      const settings: AnalysisSettings = JSON.parse(analysisSettings);
      return settings;
    } catch (error) {
      console.error(error);
    }
  }

  return undefined;
}

export function saveAnalysisSettingsLocalStorage(settings: AnalysisSettings) {
  localStorage.setItem(LS_KEY, JSON.stringify(settings));
}

export function clearAnalysisSettingsLocalStorage() {
  localStorage.removeItem(LS_KEY);
}
