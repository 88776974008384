import { Box } from '@risk-first/ui-core';
import { TabMenu } from '@risk-first/ui-tab-menu';
import { TabItem } from '@risk-first/ui-tab-menu/dist/TabMenu';
import Highcharts from 'highcharts';
import { rem } from 'polished';
import React, { MouseEvent, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { Portfolio } from '../../../features/portfolios/PortfolioState';
import { useThemeVal } from '../../../lib/style-helper/theme';
import { extractStrategyIdForFetching, isAncestorOf } from '../../../utils';
import { Cashflow } from '../../Cashflow';
import { Curve } from '../../Curve';
import { MarketEvents } from '../../MarketEvents';
import { ChartContainer, ChartTitle } from '../../SelectedPortfolio/styles';
import { ValueAtRisk } from '../../ValueAtRisk';
import { ValueAtRiskTypes } from '../../ValueAtRisk/types';

const tabs = [
  {
    controlsId: 'tabpanel-var',
    id: 'tab-var',
    label: 'VaR',
  },
  {
    controlsId: 'tabpanel-curve',
    id: 'tab-curve',
    label: 'Curve Exposure',
  },
  {
    controlsId: 'tabpanel-cashflow',
    id: 'tab-cashflow',
    label: 'Cashflow',
  },
  {
    controlsId: 'tabpanel-marketEvents',
    id: 'tab-marketEvents',
    label: 'Market Events',
  },
];

interface StrategyOverviewProps {
  existingStrategy: Portfolio;
  newStrategy?: Portfolio;
}

export const StrategyOverview = (props: StrategyOverviewProps) => {
  const { existingStrategy, newStrategy } = props;

  const [activeTab, setActiveTab] = useState(tabs[0].controlsId);
  const [existingMarketEvent, setExistingMarketEvent] = useState<string | undefined>(undefined);
  const [newMarketEvent, setNewMarketEvent] = useState<string | undefined>(undefined);

  const newStrategyIdForApi = useMemo(
    () => (newStrategy ? extractStrategyIdForFetching(newStrategy?.strategyID) : undefined),
    [newStrategy],
  );

  const chartsContainerRef = useRef<HTMLDivElement>(null);

  const themeVal = useThemeVal();
  const panelBorderStyle = `1px solid ${themeVal('colors.border')}`;

  const handleTabItemClick = useCallback((event: MouseEvent<HTMLButtonElement>) => {
    const button = event.currentTarget;
    const newActiveTab = button.getAttribute('aria-controls')!.toString();

    setActiveTab(newActiveTab);
  }, []);

  const tabMenuItems: TabItem[] = useMemo(
    () =>
      tabs.map(({ id, controlsId, label }) => ({
        'aria-controls': controlsId,
        'aria-selected': activeTab === controlsId,
        id,
        label,
        role: 'tab',
        type: 'button',
        onClick: handleTabItemClick,
      })),
    [activeTab, handleTabItemClick],
  );

  useEffect(() => {
    // Any children of the charts container should reflow if the size changes (e.g. if the assets panel opens/closes)
    if (chartsContainerRef) {
      Highcharts.charts.forEach((chart) => {
        if (
          chart &&
          isAncestorOf(chartsContainerRef.current, chart.container) &&
          chart.container.offsetWidth !== chartsContainerRef.current?.offsetWidth
        ) {
          chart.reflow();
        }
      });
    }
  });

  return (
    <Box>
      <Box borderBottom={panelBorderStyle} px={3}>
        <TabMenu items={tabMenuItems} variant="default" />
      </Box>

      <Box ref={chartsContainerRef}>
        {activeTab === 'tabpanel-var' && (
          <Box id="tabpanel-var" role="tabpanel">
            <ChartContainer>
              <ChartTitle>
                <abbr title="Value at Risk">VaR</abbr> &ndash; {existingStrategy.name}
              </ChartTitle>
              <ValueAtRisk
                height={rem(300)}
                portfolio={existingStrategy.strategyID}
                type={ValueAtRiskTypes.Attribution}
              />

              {newStrategy && (
                <>
                  <ChartTitle>
                    <abbr title="Value at Risk">VaR</abbr> &ndash; {newStrategy.name}
                  </ChartTitle>
                  <ValueAtRisk height={rem(300)} portfolio={newStrategyIdForApi} type={ValueAtRiskTypes.Attribution} />
                </>
              )}
            </ChartContainer>
          </Box>
        )}

        {activeTab === 'tabpanel-curve' && (
          <Box id="tabpanel-curve" role="tabpanel">
            <ChartContainer>
              <ChartTitle>Curve Exposure &ndash; {existingStrategy.name}</ChartTitle>
              <Curve height={rem(300)} portfolio={existingStrategy.strategyID} />
            </ChartContainer>

            {newStrategy && (
              <ChartContainer>
                <ChartTitle>Curve Exposure &ndash; {newStrategy.name}</ChartTitle>
                <Curve height={rem(300)} portfolio={newStrategyIdForApi} />
              </ChartContainer>
            )}
          </Box>
        )}

        {activeTab === 'tabpanel-cashflow' && (
          <Box id="tabpanel-cashflow" role="tabpanel">
            <ChartContainer>
              <ChartTitle>Annual Cashflow &ndash; {existingStrategy.name}</ChartTitle>
              <Cashflow height={rem(300)} portfolio={existingStrategy.strategyID} showDetailedAssets={true} />
            </ChartContainer>

            {newStrategy && (
              <ChartContainer>
                <ChartTitle>Annual Cashflow &ndash; {newStrategy.name}</ChartTitle>
                <Cashflow height={rem(300)} portfolio={newStrategyIdForApi} showDetailedAssets={true} />
              </ChartContainer>
            )}
          </Box>
        )}

        {activeTab === 'tabpanel-marketEvents' && (
          <Box id="tabpanel-marketEvents" pt={rem(25)} role="tabpanel">
            <ChartTitle>Market events &ndash; {existingStrategy.name}</ChartTitle>
            <MarketEvents
              marketEvent={existingMarketEvent}
              setMarketEvent={setExistingMarketEvent}
              strategyId={existingStrategy.strategyID}
            />

            {newStrategy && newStrategyIdForApi && (
              <>
                <ChartTitle>Market events &ndash; {newStrategy.name}</ChartTitle>
                <MarketEvents
                  marketEvent={newMarketEvent}
                  setMarketEvent={setNewMarketEvent}
                  strategyId={newStrategyIdForApi}
                />
              </>
            )}
          </Box>
        )}
      </Box>
    </Box>
  );
};
