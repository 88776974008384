import { defaultTheme } from '@risk-first/ui-theme';
import merge from 'deepmerge';
import { rem } from 'polished';

const boxShadow = `0 ${rem(2)} ${rem(4)} 0 rgba(169, 25, 99, 0.5);`;

const breakpoints = ['48em', '60em', '80em'];

const colors = {
  border: '#e6e6eb',
  chartDashes: '#d8d8dd',
  chartTextColor: defaultTheme.colors.darkGrayishBlue,
  hyphen: '#c7c7cd',
  saffronYellow: '#f8c741',
  errorTextColor: '#e22c2c',
};

const fontWeight = {
  regular: 400,
  medium: 500,
  bold: 700,
};

const appTheme = {
  backgroundColors: {
    analysisSidebar: '#fafafb',
  },
  buttons: {
    icon: {
      default: {
        bg: 'transparent',
      },
      osiris: {
        bg: 'transparent',
      },
    },
    base: {
      default: {
        border: {
          radius: rem(100),
        },
        focus: {
          shadow: boxShadow,
        },
        hover: {
          shadow: boxShadow,
        },
      },
      ghost: {
        border: {
          radius: rem(100),
        },
        focus: {
          shadow: boxShadow,
        },
        hover: {
          shadow: boxShadow,
        },
      },
      clear: {
        bg: 'transparent',
        contentColor: defaultTheme.colors.brand.primary,
        paddingLeft: 0,
        paddingRight: 0,
      },
    },
  },
  breakpoints,
  colors,
  fontFamily: 'Roboto, sans-serif;',
  fontWeight,
  mediaQueries: {
    small: `@media screen and (max-width: ${breakpoints[0]})`,
    medium: `@media screen and (max-width: ${breakpoints[1]})`,
    large: `@media screen and (max-width: ${breakpoints[2]})`,
  },
  tableDataFont: 'Roboto, sans-serif;',
  tableHeaderFont: 'Roboto, sans-serif;',
  space: [0, 4, 8, 16, 32, 64],
  tabMenu: {
    item: {
      default: {
        fontWeight: fontWeight.regular,
        padding: `${rem(19)} ${rem(24)} ${rem(15)}`,

        ':focus': {
          backgroundColor: defaultTheme.colors.white,
        },
      },
      secondary: {
        alignItems: 'center',
        backgroundColor: defaultTheme.colors.lightGrayishBlue,
        border: `1px solid ${colors.border}`,
        borderLeft: 'none',
        color: defaultTheme.colors.brand.primary,
        display: 'flex',
        fontSize: rem(14),
        fontWeight: fontWeight.regular,
        height: rem(48),
        justifyContent: 'center',
        minWidth: rem(170),
        padding: 0,

        ':first-of-type': {
          borderLeft: `1px solid ${colors.border}`,
        },

        ':hover': {
          backgroundColor: defaultTheme.colors.ghostWhite,
          cursor: 'pointer',
        },

        '&[aria-selected="true"]': {
          backgroundColor: defaultTheme.colors.white,
          borderBottomColor: 'transparent',
          fontWeight: fontWeight.medium,
        },
      },
    },
  },
  toggles: {
    icon: {
      default: {
        sizes: {
          large: {
            width: rem(35),
            height: rem(35),
          },
        },
      },
    },
    text: {
      default: {
        sizes: {
          large: {
            height: rem(35),
          },
        },
      },
    },
  },
};

export const theme = merge(defaultTheme, appTheme, {
  arrayMerge: (_, source) => source, // Overwrite instead of merging arrays
});
