import styled from '@emotion/styled';
import { Flex } from '@risk-first/ui-core';
import { themeGet } from '@styled-system/theme-get';
import { rgba } from 'polished';

export const Wrapper = styled(Flex)`
  background: ${themeGet('colors.white')};
  border: 1px solid ${themeGet('colors.border')};
  width: 100%;

  .highcharts-legend-box {
    height: 100%;
  }

  /* 1. Override inline style that can't be removed */
  .highcharts-legend-title text[style],
  .highcharts-legend-item text[style] {
    font-weight: inherit !important; /* [1] */
  }

  /* 2. Line up the legend title with the title's baseline */
  .highcharts-legend-title text[style] {
    transform: translateY(-12px); /* [2] */
  }
`;

export const ChartWrapper = styled(Flex)`
  flex: 3;
  flex-direction: row;
  div:first-of-type {
    width: 100%;
  }

  /* This adds the background odd/even row shading. The second gradient puts a border along the top of the data
     area of the chart, including the left legends. */
  svg {
    background: 0 66px no-repeat
        repeating-linear-gradient(
          ${(props: any) => rgba(themeGet('colors.ghostWhite')(props), 0.8)} 0 40px,
          ${rgba('#fcfdfe', 0.8)} 40px 80px /* colour is the ag-grid default odd row background colour */
        ),
      0 65px linear-gradient(${themeGet('colors.paleLilac')} 1px, transparent 1px);
  }

  /* Keeps a bottom 'border' on the chart even if it's not taking up the full height of the container */
  [data-highcharts-chart] {
    box-shadow: 0 1px ${themeGet('colors.border')};
  }
`;

export const LegendWrapper = styled(Flex)`
  flex: 1;
  min-width: 302px;
  border-left: 1px solid ${themeGet('colors.border')};
`;
