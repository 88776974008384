import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ALMState } from './ALMState';

const initialState: ALMState = {
  metric: 'Group_Value',
};

const almSlice = createSlice({
  name: 'alm',
  initialState,
  reducers: {
    updateALMMetric: (state, action: PayloadAction<string>) => {
      state.metric = action.payload;
    },
  },
});

export const { updateALMMetric } = almSlice.actions;

export const { reducer: almReducer } = almSlice;
