import { Box, Button, H2, Textarea } from '@risk-first/ui-core';
import { themeGet } from '@styled-system/theme-get';
import { withTheme } from 'emotion-theming';
import { rem } from 'polished';
import React, { useCallback, MouseEvent } from 'react';
import { Portfolio } from '../../../features/portfolios/PortfolioState';

export interface UploadPanelProps {
  onSaveStrategy: (strategy?: Portfolio) => void;
}

export const UploadPanel = withTheme((props: UploadPanelProps) => {
  const { onSaveStrategy } = props;

  const handleSaveStrategy = useCallback(
    (event: MouseEvent<HTMLButtonElement>) => {
      onSaveStrategy(undefined); // Note: this would be the uploaded strategy if this wasn't a mockup
    },
    [onSaveStrategy],
  );

  return (
    <Box pl={rem(13)}>
      <H2 fontWeight={themeGet('fontWeight.medium')(props)} py={rem(20)}>
        Upload
      </H2>

      <Box>
        <Button size="small" variant="primary">
          Download Template
        </Button>
      </Box>

      <Textarea
        placeholder="Download template, fill in and paste table here."
        rows={20}
        style={{ marginTop: '12px', width: '100%' }}
      ></Textarea>

      <Box mt={rem(10)} textAlign="right">
        <Button size="small" variant="primary" onClick={handleSaveStrategy}>
          Upload Strategies
        </Button>
      </Box>
    </Box>
  );
});
