/**
 * A list of common styles
 * If deemed common enough they should be moved into @risk-first/ui-*
 */
import isPropValid from '@emotion/is-prop-valid';
import styled from '@emotion/styled';
import { Box, Input } from '@risk-first/ui-core';
import { Select as UiSelect } from '@risk-first/ui-select';
import { themeGet } from '@styled-system/theme-get';
import { rgba, rem } from 'polished';
import {
  SpaceProps,
  BorderProps,
  WidthProps,
  HeightProps,
  MinHeightProps,
  MinWidthProps,
  MaxWidthProps,
  MaxHeightProps,
  FontSizeProps,
} from 'styled-system';

export interface StyledButtonProps
  extends SpaceProps,
    BorderProps,
    WidthProps,
    HeightProps,
    MinHeightProps,
    MinWidthProps,
    MaxHeightProps,
    MaxWidthProps,
    FontSizeProps {}

export const Breadcrumb = styled.div`
  font-size: ${themeGet('fontSizes.0')};
  line-height: ${20 / 12};

  a {
    color: inherit;
    font-weight: ${themeGet('fontWeight.medium')};
    text-decoration: none;
  }

  a:focus,
  a:hover {
    text-decoration: underline;
  }

  svg {
    color: ${themeGet('colors.brand.primary')};
    display: inline-block;
    margin: 0 ${rem(5)};
    vertical-align: middle;
  }
`;

export const DateTime = styled(Box)`
  font-size: ${themeGet('fontSizes.2')};
  line-height: ${20 / 12};
`;

export const TabButton = styled('button')`
  height: 50px;
  border: none;
  border-bottom: 3px solid transparent;
  border-radius: 0;
  font-weight: ${themeGet('fontWeight.bold')};
  background-color: ${themeGet('colors.white')};
  color: ${themeGet('colors.brand.primary')};
  padding: 0 ${themeGet('space.3', 16)}px;
  font-size: ${themeGet('fontSizes.0', '0.75rem')};

  &:focus,
  &:hover {
    background-color: ${themeGet('colors.athensGray')};
    border-bottom-color: ${themeGet('colors.darkPink')};
    box-shadow: none;
  }

  &.selected {
    border-bottom-color: ${themeGet('colors.darkPink')};
  }

  button + & {
    border-left: 1px solid ${themeGet('colors.border')};
  }
`;

export const TableWrapper = styled(Box)`
  background: ${themeGet('colors.white')};
  border: 1px solid ${themeGet('colors.border')};
  height: 100%;

  /* ag-grid does not appear without these 2 rules ¯\_(ツ)_/¯ */
  .ag-root-wrapper-body.ag-layout-normal {
    height: auto;
    min-height: initial;
  }

  .ag-header-row {
    .ag-header-cell {
      &:nth-of-type(2) {
        span {
          padding-left: 10px;
        }
      }
    }
  }

  .ag-row {
    .ag-cell {
      &:last-child {
        .ag-react-container {
          padding-top: 4px;
          button {
            background-color: inherit;
            &:hover {
              background-color: ${themeGet('colors.border')};
            }
          }
        }
      }
    }
    &:hover {
      box-shadow: ${themeGet('shadows.tableRow')};
    }
  }

  a {
    color: inherit;
    text-decoration: none;
  }
`;

export const OverflowingTableWrapper = styled(TableWrapper)`
  /* allows content in ag-Grid heading to spill out of that container (for e.g. header menus) */
  .ag-header,
  .ag-header-row,
  .ag-header-viewport,
  .ag-root,
  .ag-root-wrapper {
    overflow: visible;
  }
  .ag-header-container {
    transform: none !important;
  }
`;

export const BaseMenu = styled.div`
  background-color: ${themeGet('colors.white')};
  border: ${rem(1)} solid ${themeGet('colors.lavender')};
  border-radius: ${rem(4)};
  box-shadow: 0 ${rem(10)} ${rem(11)} ${rem(-7)} ${themeGet('colors.quartz')};
  max-width: 100vw;
  position: absolute;
  z-index: 1;
`;

export const ButtonIcon = styled.span`
  align-items: center;
  border: ${rem(1)} solid;
  border-radius: 50%;
  display: inline-flex;
  height: ${rem(18)};
  justify-content: center;
  margin-left: ${rem(1)};
  position: relative;
  transform: none;
  transition: transform;
  vertical-align: middle;
  width: ${rem(18)};

  /* Rotate when its active so the chevron points the opposite way */
  [aria-expanded='true'] & {
    transform: rotate(180deg);
  }
`;

export const LOBTableWrapper = styled(Box)`
  background: ${themeGet('colors.white')};
  border: 1px solid ${themeGet('colors.border')};
  height: 100%;

  /* ag-grid does not appear without these 2 rules ¯\_(ツ)_/¯ */
  .ag-root-wrapper-body.ag-layout-normal {
    height: auto;
    min-height: initial;
  }
  .ag-header-row {
    .ag-header-cell {
      display: flex;
      flex-direction: row;
      justify-content: flex-end;

      &:nth-of-type(1) {
        padding-left: 20px;
        justify-content: flex-start;
      }
      &:nth-of-type(3) {
        padding-right: 20px;
      }
    }
  }
  .ag-row {
    &:hover {
      box-shadow: ${themeGet('shadows.tableRow')};
      cursor: pointer;
    }

    .ag-cell {
      &:nth-of-type(1) {
        padding-left: 20px;
      }
      &:nth-of-type(3) {
        padding-right: 20px;
      }
    }
  }

  a {
    color: inherit;
    text-decoration: none;
  }
`;

export const FlatButton = styled.button`
  cursor: pointer;
  align-items: center;
  background: transparent;
  border: 1px solid transparent;
  color: ${themeGet('colors.darkPink')};
  display: flex;
  font: inherit;
  font-size: ${themeGet('fontSizes.1')};
  padding: 0;
`;

export const Label = styled.label`
  display: block;
  font-size: ${rem(10)};
  margin-bottom: ${rem(9)};
`;

export const Select = styled(UiSelect)`
  font-size: ${themeGet('fontSizes.0')};
  width: 100%;

  > select {
    background: ${themeGet('colors.white')};
  }
`;

export const NumberInput = styled(Input)`
  border-color: ${(props: any) => rgba(themeGet('colors.darkGrayishBlue')(props), 0.25)};
  font-size: ${themeGet('fontSizes.0')};
  height: 35px; /* match the height of the ui-select */
  text-align: center;
  width: 100%;
`;

export const Hyphen = styled.div`
  color: ${themeGet('colors.hyphen')};
  margin: 0 ${themeGet('space.1')}px;
  position: relative;
  top: 8px;
`;

export const OptimizerButton = styled.button`
  border: none;
  border-radius: 50%;
  padding: 0;
  font: inherit;
  color: inherit;
  background: none;
  color: ${themeGet('colors.darkPink')};
  height: 14px;
  position: absolute;
  top: 18px;
  &:hover {
    cursor: pointer;
  }
  svg {
    display: block;
  }
`;

export const OptimizerContainer = styled.div`
  position: relative;
  height: 50px;
  width: 14px;
  left: 10px;
  &:before {
    content: '';
    position: absolute;
    background-color: ${themeGet('colors.border')};
    width: 1px;
    height: 40px;
    left: -10px;
    top: 5px;
  }
  &:after {
    content: '';
    position: absolute;
    background-color: ${themeGet('colors.border')};
    width: 1px;
    height: 14px;
    top: 18px;
    right: -10px;
  }
`;

/* A wrapper that ensure Highcharts fully fill their container */
export const ResponsiveChartContainer = styled(Box, {
  shouldForwardProp: (prop) => isPropValid(prop) && prop !== 'height',
})`
  height: 0 !important; /* Without !important the height prop for Box will override this value */
  overflow: hidden;
  padding-bottom: ${(props) => (props.height ? props.height : rem(400))};
  position: relative;
  width: 100%;

  > div {
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
  }

  .tooltip-title {
    font-size: ${rem(10)};
    font-weight: ${themeGet('fontWeight.medium')};
    margin-bottom: 0.25em;
  }

  .tooltip-point {
    font-size: ${rem(11)};
    margin-bottom: 0.25em;
  }

  .tooltip-marker {
    border-radius: 50%;
    display: inline-block;
    height: ${rem(8)};
    margin-right: ${rem(8)};
    width: ${rem(8)};
  }

  .tooltip-value {
    font-weight: ${themeGet('fontWeight.regular')};
  }

  // Ensure transparent legend items have a subtle outline
  .highcharts-point[fill='transparent'] {
    stroke: rgba(0, 0, 0, 0.25);
    stroke-width: 1px;
  }
`;
