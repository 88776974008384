import { BarChart } from '@risk-first/ui-bar-chart';
import { themeGet } from '@styled-system/theme-get';
import { withTheme } from 'emotion-theming';
import { rgba } from 'polished';
import React, { useMemo } from 'react';
import { getFixedLocaleNumberFormatter } from '../../utils';
import { TableValue } from './types';

interface NetAssetsChartProps {
  chartData: TableValue[];
}

const numberFormatter = getFixedLocaleNumberFormatter();

export const NetAssetsChart = withTheme((props: NetAssetsChartProps) => {
  const { chartData } = props;

  // If there isn't any data, don't render the chart
  if (chartData.every((value) => value === undefined || value === null)) return null;

  const textColor: string = rgba(themeGet('colors.text')(props), 0.8);
  const labelFontSize: string = themeGet('fontSizes.1')(props);

  const options: Highcharts.Options = useMemo(
    () => ({
      chart: {
        height: 280,
        backgroundColor: 'transparent',
        marginTop: 0,
        marginBottom: 40,
      },
      series: [
        {
          type: 'bar',
          color: '#799acd',
          data: chartData as number[],
          borderWidth: 0,
          pointWidth: 6,
        },
      ],
      xAxis: {
        lineColor: 'transparent',
        labels: {
          enabled: false,
        },
        // The bars should fill the chart with no space above or below
        minPadding: 0,
        maxPadding: 0,
      },
      yAxis: {
        title: {
          text: '',
        },
        labels: {
          style: {
            color: textColor,
            fontSize: labelFontSize,
          },
          formatter: function (this: { value: number }) {
            return numberFormatter.format(this.value / 1_000_000);
          },
        },
      },
    }),
    [chartData, labelFontSize, textColor],
  );

  return <BarChart options={options} />;
});
