import { callAPI } from '.';

/**
 * Retrieve the Line Of Business Data
 */
export function getLineOfBusiness(date?: string | undefined) {
  const json = callAPI(`/api/line-of-business.json`);

  return json;
}
