import { OptionsIconComponent } from '@risk-first/ui-assets';
import { Menu, MenuItem } from '@risk-first/ui-dropdown-menu';
import React, { MouseEvent, useCallback, useState, useRef } from 'react';
import { Portfolio } from '../../features/portfolios/PortfolioState';
import { StyledOptionsIconContainer } from './styles';

interface StrategyDetailMenuProps {
  data: Portfolio;
  detailPortfolioId: string | undefined;
  onCreateFromExisting: (existingId: string) => void;
  onDeleteStrategy: (strategyId: string) => void;
  onSelectDetailPortfolio: (strategyId: string | undefined) => void;
}

export const StrategyDetailMenu = (props: StrategyDetailMenuProps) => {
  const {
    data: { strategyID: strategyId },
    detailPortfolioId,
    onCreateFromExisting,
    onDeleteStrategy,
    onSelectDetailPortfolio,
  } = props;
  const [isOpen, setOpen] = useState(false);
  const menuRef = useRef<HTMLUListElement>(null);
  const iconRef = useRef<HTMLDivElement>(null);

  const handleToggleOpen = useCallback((event: MouseEvent<SVGElement>) => {
    setOpen((open) => !open);
    event.stopPropagation();
  }, []);
  const handleClose = useCallback((event: KeyboardEvent) => {
    setOpen(false);
    event.stopPropagation();
  }, []);

  const handleCreateFromExisting = useCallback(
    (event: MouseEvent<HTMLButtonElement>) => {
      onCreateFromExisting(strategyId);
      event.stopPropagation();
    },
    [onCreateFromExisting, strategyId],
  );

  const handleDeleteStrategy = useCallback(
    (event: MouseEvent<HTMLButtonElement>) => {
      onDeleteStrategy(strategyId);
      setOpen(false);
      event.stopPropagation();
    },
    [onDeleteStrategy, strategyId],
  );

  const handleViewDetails = useCallback(
    (event: MouseEvent<HTMLButtonElement>) => {
      onSelectDetailPortfolio(detailPortfolioId !== strategyId ? strategyId : undefined);
      setOpen(false);
      event.stopPropagation();
    },
    [detailPortfolioId, onSelectDetailPortfolio, strategyId],
  );

  return (
    <div ref={iconRef}>
      <StyledOptionsIconContainer>
        {/* The style for this when hovered isn't perfect, but once the ui-component IconButton
                supports the new style we should be able to add a variant and not have the container.
                TODO: replace with IconButton when new style is available
            */}
        <OptionsIconComponent aria-expanded={isOpen} onClick={handleToggleOpen} />
      </StyledOptionsIconContainer>
      {isOpen && (
        <Menu
          ref={menuRef}
          additionalOffset={{ top: 24 }}
          triggerRef={iconRef}
          value="create"
          onClickAway={handleClose}
        >
          <MenuItem onClick={handleCreateFromExisting}>Create strategy from existing</MenuItem>
          <MenuItem onClick={handleDeleteStrategy}>Delete strategy</MenuItem>
          <MenuItem onClick={handleViewDetails}>
            {detailPortfolioId !== strategyId ? 'View details' : 'Hide details'}
          </MenuItem>
        </Menu>
      )}
    </div>
  );
};
