import { callAPI } from '.';

/**
 * Get the Market events filename from the ID given.
 * The id passed in will be a zero-indexed number in a string like '0', or '9' but the file name
 * will be market-events_00.json or market-events_09.json
 */
function getMarketEventsFilenameById(id: string) {
  const zeroIndexedSelectedPortfolio: string = id;
  const paddedSelectedPortfolio = zeroIndexedSelectedPortfolio.padStart(2, '0');
  const filename = `market-events-${paddedSelectedPortfolio}.json`;

  return filename;
}

/**
 * Return the market events information for a specific strategy
 */
export function getMarketEventsForStrategy(strategyId: string, abortController: AbortController) {
  const filename = getMarketEventsFilenameById(strategyId);
  const init: RequestInit = { signal: abortController.signal };
  const json = callAPI(`/api/${filename}`, init);

  return json;
}
