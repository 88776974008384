export const metricNameMap = new Map([
  ['Fair_Value', 'Fair Value of Assets'],
  ['Liability_Value', 'Fair Liability Value'],
  ['Group_Value', 'Net Asset Value'],
  ['Total_Book_Return', 'Total Book Return'],
  ['Total_Unrealised_Gain', 'Total Unrealised Gain'],
  ['Total_Ordinary_Income', 'Total Ordinary Income'],
  ['Cash_Total_Value', 'Total Cash Value'],
  ['SCR_Ratio', 'Capital Ratio'],
  ['SCR', 'Required Capital'],
  ['SCR Rates', 'Required Capital (Rates)'],
  ['SCR Equity', 'Required Capital (Equity)'],
  ['SCR Credit', 'Required Capital (Credit)'],
  ['Duration', 'Asset Duration'],
  ['Duration Gap', 'Duration Gap'],
]);
