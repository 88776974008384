import { callAPI } from '.';

/**
 * Retrieve the StrategiesComparison Data
 */
export function getStrategiesComparison(date?: string | undefined) {
  const json = callAPI(`/api/strategies-comparison.json`);

  return json;
}
