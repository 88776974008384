import styled from '@emotion/styled/macro';
import {
  ChevronRightIconComponent,
  PlusIconComponent,
  CloseIconComponent,
  FilterOutlineIconComponent,
  AxisIconComponent,
} from '@risk-first/ui-assets';
import { Box, Flex, H1, H2 } from '@risk-first/ui-core';
import { themeGet } from '@styled-system/theme-get';
import { withTheme } from 'emotion-theming';
import { rem } from 'polished';
import React, { MouseEvent, useLayoutEffect, useEffect, useRef, useState, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useParams } from 'react-router-dom';
import { useMountedState, useSearchParam } from 'react-use';
import { getLineOfBusiness } from '../api/lineOfBusiness';
import { getStrategy } from '../api/strategy';
import {
  AxisForm,
  EfficientFrontier,
  FiltersForm,
  Layout,
  Loading,
  SaveClearAnalysisSettings,
  SelectedPortfolio,
  TabPanel,
  VerticalTabMenu,
} from '../components';
import {
  assessmentChartAdded,
  assessmentChartRemoved,
  selectAssessmentCharts,
  selectCurrentAssessmentChart,
  currentAssessmentChartChanged,
} from '../features/assessmentCharts/assessmentChartSlice';
import { updateMetrics } from '../features/metrics/metricsSlice';
import { updateSelectedPortfolio } from '../features/portfolios/portfolioSlice';
import { updateStatistics } from '../features/statistics/statisticsSlice';
import { DEFAULT_DATE } from '../lib/constants/dates';
import { ANALYSIS } from '../lib/constants/pageTitles';
import { Breadcrumb, DateTime, OptimizerButton, OptimizerContainer, TabButton } from '../styles';
import { getSchemeFromId, getUniqueStrategyValues, Scheme, formatDate } from '../utils';

const IPAD_WIDTH = 1024;

const TAB_LABELS = [
  {
    controlledId: 'tabpanel-axis',
    icon: <AxisIconComponent />,
    id: 'tab-axis',
    text: 'Axis',
  },
  {
    controlledId: 'tabpanel-filter',
    icon: <FilterOutlineIconComponent />,
    id: 'tab-filter',
    text: 'Filter',
  },
];

const Error = styled(Box)`
  background-color: ${themeGet('colors.white')};
`;

const ChartTabCloseIconComponent = styled(CloseIconComponent)`
  margin-left: 20px;
  margin-right: -6px;
`;

type SidebarProps = {
  inactive: boolean;
};

const Sidebar = styled(Flex)<SidebarProps>`
  border-left: ${rem(1)} solid ${themeGet('colors.border')};
  pointer-events: ${(props) => (props.inactive ? 'none' : 'auto')};

  ${themeGet('mediaQueries.large')} {
    border-left: none;
    min-height: 100%;
    position: absolute;
    right: ${rem(30)};
    width: ${rem(285)};
  }
`;

const VerticalTabMenuContainer = styled.div`
  ${themeGet('mediaQueries.large')} {
    border-left: 1px solid ${themeGet('colors.border')};
  }
`;

const Container = styled(Flex)`
  position: relative;
  border: 1px solid ${themeGet('colors.border')};
  background-color: ${themeGet('colors.white')};
`;

export const Analysis: React.FC = withTheme((props) => {
  const dispatch = useDispatch();
  const { id } = useParams<{ id: string }>();
  const [loading, setLoading] = useState<boolean>(true);
  const [schemes, setSchemes] = useState<Scheme[] | undefined>(undefined);
  const [scheme, setScheme] = useState<Scheme | undefined>(undefined);
  const refSidebar = useRef<HTMLDivElement>(null);
  const [activeTab, setActiveTab] = useState<string | undefined>('tabpanel-axis');
  const isMounted = useMountedState();
  const viewportWidth = window.innerWidth;
  const userSelectedDate = useSearchParam('date') || '';
  const date = formatDate(userSelectedDate || DEFAULT_DATE);
  const charts = useSelector(selectAssessmentCharts);
  const currentAssessmentChart = useSelector(selectCurrentAssessmentChart);
  const portfolioRef = useRef<HTMLDivElement>(null);

  // Collapse sidebar panel if screen is small
  useLayoutEffect(() => {
    if (viewportWidth <= IPAD_WIDTH) {
      setActiveTab(undefined);
    }
  }, [viewportWidth]);

  // Get API data
  useEffect(() => {
    async function fetchData() {
      const newSchemes = await getLineOfBusiness();

      if (newSchemes && isMounted()) {
        setSchemes(newSchemes);
        setLoading(false);
      }
    }

    fetchData();
  }, [isMounted]);

  useEffect(() => {
    if (schemes && isMounted()) {
      const newScheme = id && schemes ? getSchemeFromId(schemes, parseInt(id, 10)) : undefined;
      setScheme(newScheme);
    }
  }, [id, schemes, isMounted]);

  // Fetch the available dropdown values used in Axis and Filter
  useEffect(() => {
    async function fetchData(id: string) {
      const data = await getStrategy(id);

      if (data) {
        dispatch(updateMetrics(getUniqueStrategyValues('metric', data)));
        dispatch(updateStatistics(getUniqueStrategyValues('statistic', data)));
      }
    }

    if (id) {
      fetchData(id);
    }
  }, [id, dispatch]);

  // Toggle the tabs. If not open, open if already open, close.
  // Note: All tabs can be closed at same time
  const handleTabItemClick = useCallback((event: MouseEvent<HTMLButtonElement>) => {
    const button = event.currentTarget;
    const newActiveTab = button.getAttribute('aria-controls')!.toString();
    const currentStatus = button.getAttribute('aria-selected');

    if (currentStatus === 'true') {
      setActiveTab(undefined);
      button.focus();
    } else {
      setActiveTab(newActiveTab);
    }
  }, []);

  const handleNewChartClick = () => {
    dispatch(assessmentChartAdded());
  };

  const handleChartTabClick = (id: number) => () => {
    dispatch(currentAssessmentChartChanged(id));
  };

  const handleCloseChartClick = (id: number) => (event: MouseEvent) => {
    event.stopPropagation(); // otherwise the tab gets the event too
    dispatch(assessmentChartRemoved(id));
  };

  // Focus the active tabPanel (for a11y reasons)
  useLayoutEffect(() => {
    if (refSidebar && refSidebar.current && activeTab !== undefined) {
      const activeTabPanel: HTMLDivElement | null = refSidebar?.current?.querySelector(`#${activeTab}`);
      if (activeTabPanel) {
        activeTabPanel.setAttribute('tabindex', '-1');
        activeTabPanel.focus({ preventScroll: true }); // preventScroll doesn't work on IE or Safari currently
      }
    }
  }, [activeTab]);

  useEffect(() => {
    document.title = scheme ? `${scheme.lineOfBusiness} - ${ANALYSIS}` : ANALYSIS;
  }, [scheme]);

  const handleTooltipPortfolioClick = useCallback(
    ({ id }) => {
      dispatch(updateSelectedPortfolio(id));

      // Assumes dispatch above is synchronous and by now the portfoliio is selected and rendered
      portfolioRef.current?.scrollIntoView();
    },
    [dispatch, portfolioRef],
  );

  return (
    <Layout>
      <div>
        <Box p={[3, 4]}>
          <H1 mb={rem(5)}>Investment Strategy Assessment</H1>
          {loading && (
            <>
              <Flex>
                <Breadcrumb>
                  <Link to="/">Line of Business </Link>
                </Breadcrumb>
                <DateTime ml="auto">{date}</DateTime>
              </Flex>
              <Flex
                bg={'white'}
                border={`1px solid ${themeGet('colors.border')(props)}`}
                height={'calc(100vh - 190px)'}
                justifyContent={'center'}
                mt={rem(20)}
              >
                <Loading />
              </Flex>
            </>
          )}
          {!scheme && !loading && (
            <Error p={4}>
              <H2 mb={3}>Error</H2>
              <p>
                Go back and <Link to="/">choose a line of business</Link>
              </p>
            </Error>
          )}
          {scheme && !loading && (
            <>
              <Flex>
                <Breadcrumb>
                  <Link to="/">Line of Business </Link> <ChevronRightIconComponent />{' '}
                  <Link to={`/strategies/${id}`}>{scheme.lineOfBusiness}</Link> <ChevronRightIconComponent />
                  Assessment
                </Breadcrumb>
                <DateTime ml="auto">{date}</DateTime>
              </Flex>
              <Flex
                alignItems="center"
                bg={'white'}
                border={`1px solid ${themeGet('colors.border')(props)}`}
                borderBottom={'none'}
                mt={rem(20)}
                pl={3}
                pr={rem(33)}
              >
                {charts.map(({ id, name }) => (
                  <TabButton
                    key={id}
                    className={id === currentAssessmentChart?.id ? 'selected' : ''}
                    onClick={handleChartTabClick(id)}
                  >
                    {name}
                    {charts.length > 1 && <ChartTabCloseIconComponent onClick={handleCloseChartClick(id)} />}
                  </TabButton>
                ))}
                <OptimizerContainer>
                  <OptimizerButton aria-label="Add" role="button" type="button" onClick={handleNewChartClick}>
                    <PlusIconComponent />
                  </OptimizerButton>
                </OptimizerContainer>
                <SaveClearAnalysisSettings />
              </Flex>
              <Container>
                <Box flex={1} pt={rem(21)}>
                  <H2 fontSize={3} fontWeight={themeGet('fontWeight.medium')(props)} px={[rem(10), rem(33)]}>
                    Investment Strategy Comparison
                  </H2>
                  <EfficientFrontier onTooltipClick={handleTooltipPortfolioClick} />
                  <SelectedPortfolio ref={portfolioRef} />
                </Box>
                <Sidebar ref={refSidebar} inactive={!activeTab}>
                  <TabPanel
                    bg={themeGet('backgroundColors.analysisSidebar')(props)}
                    hidden={activeTab !== 'tabpanel-axis'}
                    id="tabpanel-axis"
                    ml={'auto'}
                    role="tabpanel"
                  >
                    <Box borderBottom={`1px solid ${themeGet('colors.border')(props)}`} pt={rem(21)} px={rem(20)}>
                      <H2 fontSize={rem(18)} fontWeight={themeGet('fontWeight.medium')(props)} mb={rem(20)}>
                        Axis
                      </H2>
                    </Box>
                    <AxisForm key={currentAssessmentChart?.id} />{' '}
                    {/* forces the component to reset it's state if the key changes */}
                  </TabPanel>
                  <TabPanel
                    bg={themeGet('backgroundColors.analysisSidebar')(props)}
                    hidden={activeTab !== 'tabpanel-filter'}
                    id="tabpanel-filter"
                    ml={'auto'}
                    role="tabpanel"
                  >
                    <Box borderBottom={`1px solid ${themeGet('colors.border')(props)}`} pt={rem(21)} px={rem(20)}>
                      <H2 fontSize={rem(18)} fontWeight={themeGet('fontWeight.medium')(props)} mb={rem(20)}>
                        Filters
                      </H2>
                    </Box>
                    <FiltersForm />
                  </TabPanel>
                </Sidebar>
                <VerticalTabMenuContainer>
                  <VerticalTabMenu activeTab={activeTab} tabLabels={TAB_LABELS} onTabItemClick={handleTabItemClick} />
                </VerticalTabMenuContainer>
              </Container>
            </>
          )}
        </Box>
      </div>
    </Layout>
  );
});
