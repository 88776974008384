import { StackedChart } from '@risk-first/ui-stacked-chart';
import { themeGet } from '@styled-system/theme-get';
import { withTheme } from 'emotion-theming';
import { SeriesBarOptions } from 'highcharts';
import { rem } from 'polished';
import React from 'react';
import { Wrapper, ChartWrapper, LegendWrapper } from '../../components/InvestmentStrategyComparisonSummary/styles';

interface ChildStrategyComparisonProps {
  data: number;
  name: string;
}
export interface StrategyComparisonProps {
  color: string;
  data: Array<ChildStrategyComparisonProps>;
  id: string | number;
  name: string | undefined;
}
interface InvestmentStrategyComparisonSummaryProps {
  data: Array<StrategyComparisonProps>;
}

export const InvestmentStrategyComparisonSummary = withTheme(
  ({ data, ...props }: InvestmentStrategyComparisonSummaryProps) => {
    const series = data.map((item) => ({
      data: item.data.map((child) => child.data),
      name: item.name,
    }));
    const xAxisCategories = data[0].data.map((item: any) => item.name);
    // Colours can derived from the CSV (but as they are not great, we will hardcode them for now)
    const colors = [
      '#789bcf',
      '#cfc264',
      '#e2823c',
      '#c67aa9',
      '#f8c741',
      '#b21ab9',
      '#ee77bd',
      '#4186a2',
      '#158b81',
      '#c85e33',
      '#8c8ab4',
      '#417505',
      '#a7d6d2',
      '#930505',
      '#b38a1d',
      '#e43c3c',
      '#c4a081',
    ];

    // Assign enough space for the headings and all the items of the charts
    const chartHeight = 65 + 40 * xAxisCategories.length;
    const legendHeight = 120 + 37 * series.length;

    return (
      <Wrapper>
        <ChartWrapper>
          <StackedChart
            options={{
              series: series as SeriesBarOptions[],
              colors: colors || null,
              chart: {
                height: chartHeight,
                type: 'bar',
                marginLeft: 260,
                backgroundColor: 'transparent',
              },
              plotOptions: {
                series: {
                  events: {
                    legendItemClick: (event) => event.preventDefault(),
                  },
                  stacking: 'percent',
                  states: {
                    inactive: {
                      opacity: 0.1,
                    },
                  },
                },
              },
              title: {
                align: 'left',
                style: {
                  'font-size': rem(14),
                  'font-family': 'inherit',
                  'font-weight': themeGet('fontWeight.medium')(props),
                },
                text: 'Investment Strategies',
                x: 3, // to line up with strategy labels
              },
              tooltip: {
                backgroundColor: themeGet('colors.white')(props),
                borderColor: themeGet('colors.saffronYellow')(props),
                borderRadius: 1,
                style: {
                  'font-size': rem(12),
                },
              },
              xAxis: {
                categories: xAxisCategories,
                labels: {
                  align: 'left',
                  enabled: true,
                  reserveSpace: true,
                  step: 1,
                  style: {
                    color: themeGet('colors.text')(props),
                    'font-size': rem(14),
                  },
                },
              },
              yAxis: {
                gridLineWidth: 1,
                labels: {
                  enabled: true,
                  format: '{value}%',
                  step: 2,
                },
                min: 0,
                max: 100,
                opposite: true,
                reversed: false,
                stackLabels: {
                  useHTML: false,
                },
              },
            }}
            stackLabels={[]}
          />
        </ChartWrapper>
        <LegendWrapper>
          <StackedChart
            options={{
              series: series as SeriesBarOptions[],
              colors: colors || null,
              chart: {
                width: 270,
                height: legendHeight,
              },
              title: {
                align: 'left',
                style: {
                  'font-size': rem(14), // Gets ignored when useHTML is true
                  'font-family': 'inherit',
                  'font-weight': themeGet('fontWeight.medium')(props),
                },
                text: 'Legend',
                x: 3, // match main chart title offset
              },
              legend: {
                align: 'left',
                enabled: true,
                layout: 'vertical', // change to 'horizontal' if items are greater than 20
                verticalAlign: 'top',
                margin: 0,
                padding: 9,
                symbolHeight: 10,
                symbolPadding: 12,
                symbolRadius: 10,
                symbolWidth: 10,
                itemStyle: {
                  'font-size': rem(14),
                },
                y: 60,
              },
              xAxis: {
                width: 0,
                height: 0,
                visible: false,
              },
              yAxis: {
                width: 0,
                height: 0,
                visible: false,
              },
            }}
            stackLabels={[]}
          />
        </LegendWrapper>
      </Wrapper>
    );
  },
);
