import moment from 'moment';
import { SingleStrategy } from '../components/EfficientFrontier/types';
import { DATE_FORMAT } from '../lib/constants/dates';
import { metricNameMap } from '../lib/constants/metricNameMap';
/**
 * Take 'a string that looks like this' and return
 * 'aStringThatLooksLikeThis'
 */
export const toCamelCase = (string: string) => {
  let convertedString = string.replace(/\w\S*/g, (w) => w.replace(/^\w/, (c) => c.toUpperCase())).replace(/[^\w]/g, '');
  convertedString = convertedString.charAt(0).toLowerCase() + convertedString.slice(1);
  return convertedString;
};

/**
 * encode certain HTML entities to prevent XSS
 * @copright https://css-tricks.com/snippets/javascript/htmlentities-for-javascript/
 */
export const htmlEntities = (value: string | number | null) => {
  if (typeof value !== 'string') {
    return value;
  }

  const escapedString = String(value)
    .replace(/&/g, '&amp;')
    .replace(/</g, '&lt;')
    .replace(/>/g, '&gt;')
    .replace(/"/g, '&quot;');

  return escapedString;
};

/**
 * Determines if an element is the ancestor (parent of parent etc) of another element.
 * @param ancestor
 * @param element
 */
export const isAncestorOf = (ancestor: Node | null, element: Node): boolean => {
  if (ancestor === null) return false;
  const parent = element.parentNode;
  if (parent === null) return false;
  if (parent === ancestor) return true;
  return isAncestorOf(ancestor, parent);
};

export interface Scheme {
  id: number;
  lineOfBusiness: string;
  liabilityValueRegulatory: string;
  liabilityValueAccounting: string;
  fairValueOfAssets: string;
  bookValueOfAssets: string;
  economicNAV: string;
  accountingNBV: string;
  [key: number]: number;
}

/**
 * Get a single scheme from an array
 */
export const getSchemeFromId = (schemes: Scheme[], id: number) => {
  return schemes.find((scheme: Scheme) => scheme.id === id);
};

export const getUniqueStrategyValues = (key: string, items: SingleStrategy[]) => {
  const allValues = items.map((item) => item[key]);
  const set = new Set([...allValues]);
  const uniqueValues = Array.from(set);

  return uniqueValues;
};

/**
 * Returns true only if the metric is a known percentage metric.
 * @param metric - a chart metric name
 */
export const isPercentageMetric = (metric: string | undefined) => {
  const percentageMetrics = ['SCR_Ratio', 'Total_Book_Return'];
  return (metric && percentageMetrics.includes(metric)) || false;
};

/**
 * Returns true only if the metric is a known year duration metric.
 * @param metric - a chart metric name
 */
export const isYearMetric = (metric: string | undefined) => {
  const yearMetrics = ['Duration', 'Duration Gap'];
  return (metric && yearMetrics.includes(metric)) || false;
};

/**
 * Returns true if the metric is not any other known type of metric.
 * By default we assume chart metrics are currency. Undefined or null values
 * are not recognised as currency metrics.
 * @param metric - a chart metric name
 */
export const isCurrencyMetric = (metric: string | undefined) =>
  (metric && !(isPercentageMetric(metric) || isYearMetric(metric))) || false;

export const formatDate = (dateStr: string | Date) => {
  const date = moment(dateStr).format(DATE_FORMAT);
  return date;
};

export const getDisplayableMetricName = (metric: string) => metricNameMap.get(metric) || metric.replace(/_/g, ' ');

// For the demo, hardcode the locale to UK so we don't encounter situations for Continental Europeans
// where commas and periods are swapped around compared to UK/US number formatting
// e.g. 12,345.99 (en-GB) === 12.345,99 (de-DE)
const fixedLocale = 'en-GB';
const basicFixedLocaleNumberFormat = new Intl.NumberFormat(fixedLocale);

export const getFixedLocaleNumberFormatter = (options?: Intl.NumberFormatOptions) => {
  if (options) {
    return new Intl.NumberFormat(fixedLocale, options);
  } else {
    return basicFixedLocaleNumberFormat;
  }
};

/**
 * Locally generated strategies do not have any remote data associated with them, but they do have
 * a default strategy that was provided at the time of their creation that "donates" it's data.
 * By appending the donor strategy ID to the generated ID, we can make IDs that allow us to extract
 * the donor strategy ID later.
 *
 * @param generatedStrategyId the ID for the newly generated (transient) stategy
 * @param staticStrategyId the ID of the strategy donating the data for analysis
 */
export const composeIdForGeneratedStrategy = (generatedStrategyId: string, staticStrategyId: string) =>
  `${generatedStrategyId}-${staticStrategyId}`;

/**
 * The complement to the function above, allows the extraction of the underlying strategy ID from an ID
 * which work whether it's a generate strategy or an existing static one.
 * @param strategyId any strategy ID
 * @returns an ID of a static strategy that can be used to fetch data
 */
export const extractStrategyIdForFetching = (strategyId: string) => {
  // We assume the donor ID is at the end of the string, or for a static strategy it's all of the string
  const baseStrategy = /\d+$/.exec(strategyId);
  if (!baseStrategy) throw new Error(`Bad stategyID: ${strategyId}`);
  return baseStrategy[0];
};

/**
 * Lets us know if this is a generated strategy. By having this, we isolate the need to know the strategy ID format.
 * @param strategyId the id to test
 */
export const isGeneratedStrategy = (strategyId: string) => /\d+-\d+/.test(strategyId);

/**
 * Adds/sets or removes keys from the URL search parameters. If a supplied parameter is undefined, it will be removed
 * from the existing parameters, otherwise the value will be set for that key.
 * @param search The original URL search parameters
 * @param params An object containing URL search parameter keys and values
 * @returns an updated URL search component, modified by the supplied params
 */
export const transformSearchParams = (search: string, params: { [key: string]: string | undefined }): string => {
  const searchParams = new URLSearchParams(search);
  Object.entries(params).forEach(([key, value]) => {
    if (value === undefined) {
      searchParams.delete(key);
    } else {
      searchParams.set(key, value);
    }
  });

  return searchParams.toString();
};
