import { Box, Flex, H2 } from '@risk-first/ui-core';
import { RadioButtonInput } from '@risk-first/ui-radio-button';
import { themeGet } from '@styled-system/theme-get';
import { withTheme } from 'emotion-theming';
import { rem } from 'polished';
import React, { useCallback, useMemo } from 'react';
import { useHistory, useParams } from 'react-router';
import { Portfolio } from '../../features/portfolios/PortfolioState';
import { transformSearchParams } from '../../utils';
import { FromExistingPanel } from './FromExisting/FromExistingPanel';
import { GeneratePanel } from './Generate/GeneratePanel';
import { UploadPanel } from './Upload/UploadPanel';
import { CreateStrategiesPanelSelector } from './styles';

interface PanelInfo {
  label: string;
  pathParam: string;
}

const panels: { [key: string]: PanelInfo } = {
  generate: {
    label: 'Generate',
    pathParam: '',
  },
  upload: {
    label: 'Upload',
    pathParam: 'upload',
  },
  fromExisting: {
    label: 'Create from existing',
    pathParam: 'from-existing',
  },
};

interface CreateStrategiesProps {
  strategies: Portfolio[] | undefined;
  onSaveStrategy: (strategy?: Portfolio) => void;
}

export const CreateStrategies = withTheme((props: CreateStrategiesProps) => {
  const { onSaveStrategy, strategies } = props;
  const history = useHistory();
  const { id, subtab } = useParams<{ id: string; subtab?: string }>();

  const activePanel = useMemo(
    () => Object.values(panels).find((panel) => panel.pathParam === subtab) || panels.generate,
    [subtab],
  );

  const setActivePanel = useCallback(
    (newPanel: PanelInfo) => {
      const newUrl = {
        pathname: `/strategies/${id}/create${newPanel.pathParam ? `/${newPanel.pathParam}` : ''}`,
        // If we're moving away from the create from existing tab, clear the existing strategy ID param
        search: transformSearchParams(history.location.search, { existingId: undefined }),
      };
      history.push(newUrl);
    },
    [history, id],
  );

  return (
    <Box backgroundColor={themeGet('colors.white')(props)} border={`1px solid ${themeGet('colors.border')(props)}`}>
      {/* Heading and panel selection controls */}
      <Box ml="0.8125rem" p="1.25rem" pb={0}>
        <H2 fontWeight={themeGet('fontWeight.medium')(props)}>Create Strategies</H2>
        <Flex role="tablist">
          {Object.entries(panels).map(([name, panel]) => (
            <CreateStrategiesPanelSelector key={name}>
              <RadioButtonInput
                aria-selected={activePanel === panel}
                checked={activePanel === panel}
                id={`create-strategies-tab-control-${name}`}
                name={`create-strategies-tab-controls`}
                role="tab"
                onChange={useCallback(() => setActivePanel(panel), [panel])}
              />
              <label htmlFor={`create-strategies-tab-control-${name}`}>{panel.label}</label>
            </CreateStrategiesPanelSelector>
          ))}
        </Flex>
      </Box>

      {/* Panel contents */}
      <Box borderTop={`1px solid ${themeGet('colors.border')(props)}`}>
        {activePanel === panels.generate && (
          <Box p="1.25rem" pt={0} role="tabpanel">
            <GeneratePanel />
          </Box>
        )}
        {activePanel === panels.upload && (
          <Box p={rem(20)} pt={0} role="tabpanel">
            <UploadPanel onSaveStrategy={onSaveStrategy} />
          </Box>
        )}
        {activePanel === panels.fromExisting && (
          <div role="tabpanel">
            <FromExistingPanel strategies={strategies} onSaveStrategy={onSaveStrategy} />
          </div>
        )}
      </Box>
    </Box>
  );
});
