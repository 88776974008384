import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../../app/RootState';
import { getSavedAnalysisSettings } from '../../lib/localStorage';
import { AssessmentChartsState, AssessmentChart } from './AssessmentChartState';
import { AxesState } from './AxesState';

const analysisSavedSettings = getSavedAnalysisSettings();
const defaultChart: AssessmentChart = {
  id: 0,
  name: '',
  axes: {
    x: {
      metric: 'Fair_Value',
      statistic: 'StDev',
      timestep: '5',
    },
    y: {
      metric: 'Fair_Value',
      statistic: 'Mean',
      timestep: '5',
    },
  },
};

// x-axis: Fair Value, Std Deviation, 5 Years
// y-axis: Fair Value, Mean, 5 Years

const initialState: AssessmentChartsState = {
  charts: analysisSavedSettings?.charts || [{ ...defaultChart, name: 'Analysis 1' }],
  currentChartId: 0,
};

const getCurrentChart = (state: AssessmentChartsState): AssessmentChart | undefined => {
  return state.charts.find(({ id }) => id === state.currentChartId);
};

const assessmentChartsSlice = createSlice({
  name: 'assessmentCharts',
  initialState,
  reducers: {
    assessmentChartAdded: (state: AssessmentChartsState, action: PayloadAction<AssessmentChart | undefined>) => {
      const newId = state.charts.reduce((maxId, { id }) => Math.max(maxId, id), 0) + 1;
      const newChart = { ...(action.payload ? action.payload : defaultChart), id: newId };
      if (!newChart.name) {
        newChart.name = `Analysis ${newId + 1}`;
      }
      state.charts.push(newChart);
      state.currentChartId = newId;
    },
    currentAssessmentChartChanged: (state, action: PayloadAction<number>) => {
      state.currentChartId = action.payload;
    },
    currentAssessmentChartAxesUpdated: (state, action: PayloadAction<AxesState>) => {
      const currentChart = getCurrentChart(state);
      if (currentChart) {
        currentChart.axes = action.payload;
      }
    },
    assessmentChartRemoved: (state, action: PayloadAction<number>) => {
      const idToRemove = action.payload;
      const indexToRemove = state.charts.findIndex(({ id }) => id === idToRemove);
      if (indexToRemove < 0) {
        return;
      }

      state.charts.splice(indexToRemove, 1);

      if (idToRemove === state.currentChartId) {
        // Pick the next or failing that previous chart in the list
        const newSelectedIndex = indexToRemove >= state.charts.length ? state.charts.length - 1 : indexToRemove;
        state.currentChartId = state.charts[newSelectedIndex].id;
      }
    },
  },
});

export const {
  assessmentChartAdded,
  assessmentChartRemoved,
  currentAssessmentChartChanged,
  currentAssessmentChartAxesUpdated,
} = assessmentChartsSlice.actions;

export const { reducer: assessmentChartsReducer } = assessmentChartsSlice;

export const selectAssessmentCharts = (state: RootState) => state.assessmentCharts.charts;

export const selectCurrentAssessmentChart = (state: RootState) => {
  return getCurrentChart(state.assessmentCharts);
};

export const selectCurrentAssessmentChartAxes = (state: RootState) => selectCurrentAssessmentChart(state)?.axes;
