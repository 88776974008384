import { Box, Button, Flex, H2, TextCtaButton } from '@risk-first/ui-core';
import { Dialog } from '@risk-first/ui-dialog';
import { Select } from '@risk-first/ui-select';
import { rem } from 'polished';
import React, { FormEvent, useCallback, useEffect, useMemo, useState } from 'react';
import { Portfolio } from '../../../features/portfolios/PortfolioState';
import {
  getSavedAnalysisSettings,
  localStorageExists,
  saveAnalysisSettingsLocalStorage,
} from '../../../lib/localStorage';
import { useThemeVal } from '../../../lib/style-helper/theme';
import { Label } from '../../../styles';

interface DefaultAnalysisStrategyDialogProps {
  isOpen: boolean;
  setOpen: (isOpen: boolean) => void;
  strategies?: Portfolio[];
}

export const DefaultAnalysisStrategyDialog = ({ strategies, isOpen, setOpen }: DefaultAnalysisStrategyDialogProps) => {
  const [defaultStrategy, setDefaultStrategy] = useState<Portfolio | undefined>(undefined);

  useEffect(() => {
    // When the dialog is opened, make sure it has the current stored value (if existing)
    if (isOpen) {
      const storedDefaultStrategy = getSavedAnalysisSettings()?.defaultStrategyForAnalysis;
      if (storedDefaultStrategy) {
        setDefaultStrategy(storedDefaultStrategy);
      }
    }
  }, [isOpen]);

  const handleDialogToggle = useCallback(() => setOpen(!isOpen), [isOpen, setOpen]);

  const changeDefaultStrategy = useMemo(
    () => (strategyId?: string) => {
      const defaultStrategy = strategies?.find(({ strategyID }) => strategyID === strategyId);
      setDefaultStrategy(defaultStrategy);
    },
    [strategies],
  );

  const handleChange = useCallback(
    (event: FormEvent<HTMLSelectElement>) => changeDefaultStrategy(event.currentTarget.value),
    [changeDefaultStrategy],
  );
  const handleClear = useCallback(() => changeDefaultStrategy(undefined), [changeDefaultStrategy]);

  // NOTE: if this was lifted up to the FromExistingPanel component we could update the default
  // analysis strategy ID if it was reset during a new strategy creation (maybe nice to have).
  const handleSave = useCallback(() => {
    if (localStorageExists) {
      const existingSettings = getSavedAnalysisSettings();
      saveAnalysisSettingsLocalStorage({
        ...existingSettings,
        defaultStrategyForAnalysis: defaultStrategy,
      });
    }
    handleDialogToggle(); // Close the dialog
  }, [defaultStrategy, handleDialogToggle]);

  const themeVal = useThemeVal();
  const panelBorderStyle = `1px solid ${themeVal('colors.border')}`;

  return (
    <Dialog ariaLabel="Default New Strategy Data" isOpen={isOpen} toggleDialog={handleDialogToggle}>
      <H2 fontWeight={`${themeVal('fontWeight.medium')}`} marginBottom={rem(20)}>
        Select analytics
      </H2>
      <Box borderTop={panelBorderStyle} py={rem(20)}>
        <Label htmlFor="default-analysis-strategy-id">Strategy</Label>
        <Select
          id="default-analysis-strategy-id"
          isClearEnabled={true}
          name="default-analysis-strategy"
          value={defaultStrategy?.strategyID || ''}
          variant="default"
          onChange={handleChange}
          onClear={handleClear}
        >
          <option value="">No strategy selected</option>
          {strategies?.map((strategy: Portfolio) => (
            <option key={strategy.strategyID} value={strategy.strategyID}>
              {strategy.name}
            </option>
          ))}
        </Select>
      </Box>

      <Flex>
        <Button size="small" variant="primary" onClick={handleSave}>
          Save settings
        </Button>
        <Box width={rem(20)} />
        <TextCtaButton ml={rem(20)} onClick={handleDialogToggle}>
          Cancel
        </TextCtaButton>
      </Flex>
    </Dialog>
  );
};
