import styled from '@emotion/styled';
import { LargerChevronRightComponent } from '@risk-first/ui-assets';
import { Box } from '@risk-first/ui-core';
import { themeGet } from '@styled-system/theme-get';
import { rem } from 'polished';
import { ClosableSection } from '../ClosableSection';

export const StrategiesDetailClosableSection = styled(ClosableSection)`
  header {
    height: 2.1875rem;
    padding: 0 1.25rem;
  }
`;

export const StrategiesTableWrapper = styled(Box)`
  margin-bottom: -1px; /* trim off content border if the content fits this container */

  table {
    border-bottom: 1px solid ${themeGet('colors.border')};
  }
  th {
    position: static; /* Overrides 'sticky' - we don't want the th's to be above the source filter menu */
    font-weight: ${themeGet('fontWeight.medium')};
    text-align: left;
  }

  /* Add some padding to make space for the pink border of the detail marker */
  th:first-of-type,
  td:first-of-type {
    padding-left: 16px;
  }

  &[aria-expanded='true'] {
    /* NativeTable doesn't like if we change the number of the columns during it's lifespan, so we hide them instead */
    th:nth-of-type(3),
    td:nth-of-type(3),
    th:nth-of-type(4),
    td:nth-of-type(4) {
      display: none;
    }
    td:last-of-type {
      border-right: 1px solid ${themeGet('colors.border')};
    }

    tbody {
      /* This is so that the box-shadow of the detail marker doesn't overflow the top of the tbody */
      /* It's not obvious to me why the default visible area of the tbody is offset into the thead! */
      clip-path: inset(0px 0px -50px 0);
    }
  }

  &[aria-expanded='false'] {
    th:nth-of-type(2),
    td:nth-of-type(2) {
      width: 30%;
    }

    th:nth-of-type(3),
    td:nth-of-type(3),
    th:nth-of-type(4),
    td:nth-of-type(4) {
      width: 7.5%;
    }
  }

  td:last-of-type {
    & > div {
      justify-content: flex-end;
    }
  }
`;

/* This stretches across the table row, providing the left accent and drop shadow that wouldn't be
   possible otherwise because we can't easily select the tr of the detail strategy in a `NativeTable` */
export const SelectedDetailStrategyMarker = styled.div`
  border-width: 0;
  border-left: 16px solid ${themeGet('colors.brand.primary')};
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.25);
  height: 40px; /* default table row height */
  pointer-events: none;
  position: absolute;
  /* the box for the marker is wider than the table row so that the box shadow doesn't fade off at the sides */
  left: -10px;
  width: calc(100% + 20px);
`;

export const ClosableSectionIcon = styled(LargerChevronRightComponent)`
  [aria-expanded='true'] & {
    transform: rotate(90deg);
  }
`;

export const StyledOptionsIconContainer = styled.div`
  svg {
    cursor: pointer;
    color: ${themeGet('colors.darkPink')};
    transition: transform 0.1s ease-in-out;
  }

  svg[aria-expanded='true'] {
    transform: rotate(90deg);
  }
`;

export const StyledAssetAllocationTable = styled.table`
  border: 1px solid ${themeGet('colors.border')};
  font-size: ${themeGet('fontSizes.1')};
  width: 100%;

  tr {
    height: ${rem(35)};
  }

  th {
    font-weight: ${themeGet('fontWeight.bold')};
    background-color: ${themeGet('colors.white')};
  }

  th,
  td {
    text-align: left;
    vertical-align: middle;
    padding-left: ${rem(20)};
    white-space: nowrap;
  }

  th:nth-of-type(1),
  td:nth-of-type(1) {
    /* Take a minimum of horizontal space */
    width: 1%;
  }
  th:last-of-type,
  td:last-of-type {
    span {
      /* leaves shrinkable space at the end of the row */
      min-width: 35%; /* Fallback for old browsers that don't support 'max' */
      min-width: max(35%, 5.5em);

      display: inline-block;
      text-align: right;
    }
  }

  tr:nth-of-type(even) {
    background-color: ${themeGet('colors.white')};
  }
`;
