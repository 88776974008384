// Colours are hardcoded until the spreadsheet is updated with the colours specified by Design
export const colors: { [name: string]: string } = {
  '0': '#6d7278',
  '1': '#e2823c',
  '2': '#c67aa9',
  '3': '#cfc264',
  '4': '#cfc264',
  '5': '#cfc264',
  '6': '#158b81',
  '7': '#158b81',
  '8': '#158b81',
  '9': '#789bcf',
  '10': '#789bcf',
  '11': '#789bcf',
  '12': '#2e2e43',
  '13': '#2e2e43',
  '14': '#2e2e43',
  '15': '#f8c741',
  '16': '#f8c741',
  '17': '#f8c741',
  '18': '#e43c3c',
  '19': '#e43c3c',
  '20': '#c85e33',
  '21': '#c85e33',
  '22': '#c85e33',
  '23': '#c85e33',
  '24': '#c85e33',
  '25': '#c85e33',
};
