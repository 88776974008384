import { PlusIconComponent } from '@risk-first/ui-assets';
import { Checkbox } from '@risk-first/ui-checkbox';
import { Box, Flex, H2, VisuallyHiddenLabel } from '@risk-first/ui-core';
import { themeGet } from '@styled-system/theme-get';
import { withTheme } from 'emotion-theming';
import { rem } from 'polished';
import React, { FormEvent, useCallback, useEffect, useMemo, useState } from 'react';
import { useMountedState } from 'react-use';
import { getStrategiesComparison } from '../../../api/strategiesComparison';
import { StrategyComparisonProps } from '../../InvestmentStrategyComparisonSummary/InvestmentStrategyComparisonSummary';
import { ConstraintItem } from './ConstraintItem';
import { SmallerFlatButton } from './styles';
import { EditableConstraint } from './types';

let lastConstraintId = 0;

export const ConstraintsList = withTheme((props) => {
  const isMounted = useMountedState();
  const [assetNames, setAssetNames] = useState<string[] | null>(null);
  const [constraints, setConstraints] = useState<EditableConstraint[]>([]);

  useEffect(() => {
    // Not sure where this data should come from, but this is the same as the Strategies page
    async function fetchData() {
      getStrategiesComparison().then((newStrategiesComparison: StrategyComparisonProps[]) => {
        if (isMounted()) {
          if (newStrategiesComparison) {
            const names = newStrategiesComparison.map((asset) => asset.name).filter((name) => name) as string[];
            setAssetNames(names);
          }
        }
      });
    }

    fetchData();
  }, [isMounted]);

  const handleAddConstraint = useCallback(() => {
    // Make a constraint name that's not currently in use
    const highestNameIndex = constraints.reduce((max, constraint) => {
      const match = constraint.name.match(/Constraint ([0-9]+)/);
      return match ? Math.max(Number(match[1]), max) : max;
    }, 0);
    const name = `Constraint ${highestNameIndex + 1}`;

    setConstraints([
      ...constraints,
      {
        id: lastConstraintId++,
        name,
        active: false,
        controlAssets: new Set<string>(),
        referenceAssets: new Set<string>(),
        editorOpen: false,
      },
    ]);
  }, [constraints]);

  const handleActivateAllClick = useCallback(
    (event: FormEvent<HTMLInputElement>) => {
      const activeState: boolean = event.currentTarget.checked;
      setConstraints(constraints.map((c) => ({ ...c, active: activeState })));
    },
    [constraints],
  );

  const updateConstraint = useCallback(
    (constraint: EditableConstraint) =>
      setConstraints(constraints.map((c) => (c.id === constraint.id ? constraint : c))),
    [constraints],
  );

  const deleteConstraint = useCallback(
    (constraint: EditableConstraint) => {
      setConstraints(constraints.filter((c) => c !== constraint));
    },
    [constraints],
  );

  const allActive = useMemo(() => constraints.every((c) => c.active), [constraints]);

  return (
    <>
      <Flex
        alignItems="center"
        borderBottom={`1px solid ${themeGet('colors.border')(props)}`}
        height="3.125rem"
        px="1rem"
      >
        <H2 fontWeight={themeGet('fontWeight.medium')(props)}>Constraints</H2>
      </Flex>
      <Flex
        alignItems="center"
        borderBottom={`1px solid ${themeGet('colors.border')(props)}`}
        height="2.5rem"
        justifyContent="space-between"
        px={3}
      >
        {constraints.length ? (
          <>
            <VisuallyHiddenLabel htmlFor="constraint-select-all">Apply all constraints</VisuallyHiddenLabel>
            <Checkbox checked={allActive} id="constraint-select-all" onChange={handleActivateAllClick} />
          </>
        ) : (
          /* Set a height to maintain the layout as the checkbox is visually toggled */
          <div style={{ height: rem(18) }} />
        )}
        <SmallerFlatButton type="button" onClick={handleAddConstraint}>
          <PlusIconComponent />
          <Box ml={2}>New constraint</Box>
        </SmallerFlatButton>
      </Flex>
      {constraints.map((constraint) => (
        <ConstraintItem
          key={constraint.id}
          assetNames={assetNames}
          constraint={constraint}
          deleteConstraint={deleteConstraint}
          updateConstraint={updateConstraint}
        />
      ))}
    </>
  );
});
