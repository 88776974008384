import { Auth0Provider } from '@auth0/auth0-react';
import React from 'react';
import { useHistory } from 'react-router-dom';

export const Auth0ProviderWithHistory: React.FC = ({ children }) => {
  const history = useHistory();

  const onRedirectCallback = React.useCallback(
    (appState: { returnTo?: string }) => {
      history.push(appState?.returnTo || window.location.pathname);
    },
    [history],
  );

  return (
    <Auth0Provider
      audience={window.REACT_APP_AUTH_AUDIENCE}
      cacheLocation="localstorage"
      clientId={window.REACT_APP_CLIENT_ID}
      domain={window.REACT_APP_AUTH_DOMAIN}
      redirectUri={window.location.origin}
      onRedirectCallback={onRedirectCallback}
    >
      {children}
    </Auth0Provider>
  );
};
