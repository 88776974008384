import { Box, Flex } from '@risk-first/ui-core';
import { NativeColumn, NativeTable } from '@risk-first/ui-native-table';
import { themeGet } from '@styled-system/theme-get';
import { withTheme } from 'emotion-theming';
import React, { FormEvent, useCallback, useEffect, useMemo, useState } from 'react';
import { getMarketEventsForStrategy } from '../../api/marketEvents';
import { Select } from '../../styles';
import { getFixedLocaleNumberFormatter } from '../../utils';
import { Loading } from '../Loading';
import { NetAssetsChart } from './NetAssetsChart';
import { MarketEventsTableWrapper, NetAssetsMovementDescriptionList } from './style';
import { EventData, RowDataItem, StrategyMarketEvents, TableValue } from './types';

const numberFormatter = getFixedLocaleNumberFormatter();

const valueFormatter = (value: TableValue) => {
  if (value === undefined) {
    return '-';
  } else if (value === null) {
    return '';
  } else if (value < 0) {
    return `(${numberFormatter.format(Math.round(-value))})`;
  } else {
    return numberFormatter.format(Math.round(value));
  }
};

const orderedRowNames: (keyof EventData)[] = [
  'Interest rate',
  'Credit',
  'Equity',
  'Property',
  'Diversification',
  'Total',
];

const noDataLastRow: RowDataItem = {
  id: orderedRowNames.length,
  rowName: '',
  assets: null,
  liabilities: null,
  netAssets: null,
};

const emptyTableRowData: RowDataItem[] = orderedRowNames
  .map(
    (rowName, index): RowDataItem => ({
      id: index,
      rowName,
      assets: undefined,
      liabilities: undefined,
      netAssets: undefined,
    }),
  )
  .concat(noDataLastRow);

interface MarketEventsProps {
  strategyId: string;
  marketEvent: string | undefined;
  setMarketEvent: (marketEvent: string) => void;
}

export const MarketEvents = withTheme((props: MarketEventsProps) => {
  const { strategyId, marketEvent, setMarketEvent } = props;
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [marketEventsData, setMarketEventsData] = useState<StrategyMarketEvents | undefined>(undefined);
  const [rowData, setRowData] = useState<RowDataItem[]>(emptyTableRowData);

  useEffect(() => {
    const abortController = new AbortController();

    if (strategyId !== undefined) {
      const getData = async () => {
        const data = await getMarketEventsForStrategy(strategyId, abortController);
        setMarketEventsData(data);
        setIsLoading(false);
      };

      getData();
    }

    return () => {
      abortController.abort();
    };
  }, [strategyId]);

  useEffect(() => {
    if (marketEventsData && marketEvent) {
      // Make sure the rows are in the required order
      const newRowData: RowDataItem[] = orderedRowNames
        .map(
          (rowName: keyof EventData, index): RowDataItem => {
            const { Assets, Liabilities, Surplus } = marketEventsData.events[marketEvent][rowName];
            return {
              id: index,
              rowName,
              assets: Assets,
              liabilities: Liabilities,
              netAssets: Surplus,
            };
          },
        )
        .concat(noDataLastRow);

      setRowData(newRowData);
    } else {
      setRowData(emptyTableRowData);
    }
  }, [marketEventsData, marketEvent]);

  const netAssetsMovement: string | undefined = useMemo(
    () =>
      valueFormatter(marketEventsData && marketEvent ? marketEventsData.events[marketEvent].Total.Surplus : undefined),
    [marketEventsData, marketEvent],
  );

  const columns: NativeColumn[] = useMemo(
    () => [
      {
        field: 'rowName',
        headerName: '',
      },
      {
        field: 'assets',
        headerName: 'Assets',
        valueFormatter,
      },
      {
        field: 'liabilities',
        headerName: 'Liabilities',
        valueFormatter,
      },
      {
        field: 'netAssets',
        headerName: 'Net assets',
        valueFormatter,
      },
      {
        field: '',
        headerName: 'Net assets',
        cellRendererFramework: (props: any) => (props.data.id === 0 ? <NetAssetsChart {...props} /> : null),
        cellRendererParams: { chartData: rowData.slice(0, rowData.length - 1).map((row) => row['netAssets']) },
      },
    ],
    [rowData],
  );

  const handleMarketEventSelection = useCallback(
    (event: FormEvent<HTMLSelectElement>) => {
      setMarketEvent(event.currentTarget.value);
    },
    [setMarketEvent],
  );

  const getRowId = useCallback((row: any) => row.id, []);

  return (
    <Box mb={4} mt={3} px={4}>
      {isLoading && <Loading height="calc(4.25rem + 332px)" />}
      {marketEventsData !== undefined && (
        <>
          <Flex alignItems="center" justifyContent="space-between" minHeight="3rem">
            <Box width="15.625rem">
              <Select
                aria-label="Market event"
                id="market-event-id"
                name="market-event"
                value={marketEvent}
                variant="default"
                onChange={handleMarketEventSelection}
              >
                <option value="">Select an event</option>
                {Object.keys(marketEventsData?.events || {}).map((eventName, index) => (
                  <option key={index} value={eventName}>
                    {eventName}
                  </option>
                ))}
              </Select>
            </Box>
            <NetAssetsMovementDescriptionList>
              <dt>Net assets movement</dt>
              <dd>{netAssetsMovement}</dd>
            </NetAssetsMovementDescriptionList>
          </Flex>
          <MarketEventsTableWrapper border={`1px solid ${themeGet('colors.border')(props)}`} mt="1.25rem">
            <NativeTable columns={columns} getRowNodeId={getRowId} headerHeight={50} rowData={rowData} rowHeight={40} />
          </MarketEventsTableWrapper>
        </>
      )}
    </Box>
  );
});
