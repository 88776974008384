import { CurvePointOptionsObject } from './types';
/**
 * Build bucketed arrays of data
 * To get the DV01 or KRD for an individual bucket, sum the values at individual points - so KRD(0-2) = KRD(1)+KRD(2),
 * and DV01(3-5)= DV01(3)+DV01(4)+DV01(5) etc.
 */
export const buildBuckets = (data: any[], key: string, bucketsConfig: number[][]): number[] => {
  const buckets: number[] = [];
  for (let i = 0; i < bucketsConfig.length; i++) {
    let bucketValue = 0;

    bucketsConfig[i].forEach((bucket) => {
      if (data[bucket] && data[bucket][key]) {
        bucketValue = bucketValue + Math.trunc(parseFloat(data[bucket][key]));
      }
    });

    buckets[i] = bucketValue;
  }
  return buckets;
};

/**
 * Return an array of results, by subtracting one array value from another.
 * We should end up with the same number of items as there are 'buckets'.
 */
export const calculateNetData = (
  assets: CurvePointOptionsObject,
  liabilities: CurvePointOptionsObject,
  bucketsConfig: number[][],
): number[] => {
  const data = [];
  for (let i = 0; i < bucketsConfig.length; i++) {
    data.push(Math.trunc(assets[i]) - Math.trunc(liabilities[i]));
  }
  return data;
};
