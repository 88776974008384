import { ScatterChart } from '@risk-first/ui-scatter-chart';
import { themeGet } from '@styled-system/theme-get';
import { withTheme } from 'emotion-theming';
import Highcharts, { SeriesScatterOptions } from 'highcharts';
import { rem } from 'polished';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useMountedState, useSearchParam } from 'react-use';
import { isEqual } from 'underscore';
import { getStrategies } from '../../api/strategies';
import { getStrategiesByIds } from '../../api/strategy';
import { RootState } from '../../app/RootState';
import { getRecordValueByAxes, reduceDataByActiveFilters } from '../../components/EfficientFrontier/utils';
import { Loading } from '../../components/Loading';
import { AxesState } from '../../features/assessmentCharts/AxesState';
import { selectCurrentAssessmentChart } from '../../features/assessmentCharts/assessmentChartSlice';
import { FilterStateValues } from '../../features/filter/FilterState';
import { Portfolio, PortfolioAsset } from '../../features/portfolios/PortfolioState';
import { updateAvailablePortfolios } from '../../features/portfolios/portfolioSlice';
import { colors } from '../../lib/constants/colors';
import { ResponsiveChartContainer } from '../../styles';
import {
  getDisplayableMetricName,
  getFixedLocaleNumberFormatter,
  htmlEntities,
  isAncestorOf,
  isCurrencyMetric,
  isPercentageMetric,
} from '../../utils';
import { Wrapper } from './styles';
import { EfficientFrontierProps, ExtendedPoint, SeriesProps, SingleStrategies } from './types';

const numberFormatter = getFixedLocaleNumberFormatter();

// TODO currently, the ids are hard-coded here, and is not yet clear how we'll be using them in the final product
// If any 'strategies' are being selected, the below ids are being filtered and only the selected persist; If nothing is selected on
// Strategies screen, all of those ids are being passed to `getStrategiesByIds`
const ids: string[] = Array.from({ length: 26 }).map((_, index) => `${index}`); // "0" .. "25"

interface ChartViewSettings {
  axes: AxesState;
  x: {
    minExtreme: number;
    maxExtreme: number;
  };
  y: {
    minExtreme: number;
    maxExtreme: number;
  };
}

export const EfficientFrontier: React.FC<EfficientFrontierProps> = withTheme(
  ({ onTooltipClick, ...props }: EfficientFrontierProps) => {
    const dispatch = useDispatch();
    const { selectedPortfolio } = useSelector((state: RootState) => state.portfolios);
    const { filters } = useSelector((state: RootState) => state.filter);
    const [validActiveFilters, setValidActiveFilters] = useState<FilterStateValues[] | undefined>(undefined);
    const [filteredData, setFilteredData] = useState<SingleStrategies[] | undefined>(undefined);
    const [loading, setLoading] = useState<boolean>(false);
    const [strategies, setStrategies] = useState<{ [id: string]: Portfolio } | undefined>(undefined);
    const [strategyNames, setStrategyNames] = useState<{ [id: string]: string } | undefined>(undefined);
    const [data, setData] = useState<SingleStrategies[] | undefined>(undefined);
    const isMounted = useMountedState();
    const containerRef = useRef<HTMLDivElement>(null);
    const selectedIds = useSearchParam('selected') || undefined;
    const selected = useMemo(() => selectedIds?.split(','), [selectedIds]);
    const filteredIds = selected ? useMemo(() => ids.filter((id) => selected?.includes(id)), [selected]) : ids;
    const currentChart = useSelector(selectCurrentAssessmentChart);
    const [chartViewSettings, setChartViewSettings] = useState<{ [id: number]: ChartViewSettings }>(() => ({}));

    useEffect(() => {
      if (containerRef.current) {
        const chart = Highcharts.charts.find(
          (ch) => ch && containerRef.current && isAncestorOf(containerRef.current, ch.container),
        );

        if (chart && chart.container.offsetWidth !== containerRef.current.offsetWidth) {
          chart.reflow();
        }
      }
    });

    useEffect(() => {
      async function fetchData() {
        setLoading(true);
        const newData = await getStrategiesByIds(filteredIds);

        if (newData && isMounted()) {
          setData(newData);
          setFilteredData(newData);
          setLoading(false);
        }
      }

      if (filteredIds) {
        fetchData();
      }
    }, [filteredIds, isMounted]);

    // Get API data
    useEffect(() => {
      async function fetchData() {
        setLoading(true);
        const newStrategies = (await getStrategies()) as Portfolio[];

        if (newStrategies && isMounted()) {
          const strategiesMap = newStrategies.reduce((obj: { [id: string]: Portfolio }, strategy) => {
            obj[strategy.strategyID] = strategy;
            return obj;
          }, {});
          setStrategies(strategiesMap);
          setLoading(false);
        }
      }

      fetchData();
    }, [isMounted]);

    useEffect(() => {
      if (strategies) {
        const newStrategyNames: { [name: string]: string } = {};
        Object.values(strategies).forEach(
          (strategy: Portfolio) => (newStrategyNames[strategy.strategyID] = strategy.name),
        );

        if (newStrategyNames) {
          setStrategyNames(newStrategyNames);
        }
      }
    }, [strategies]);

    const axes = currentChart?.axes;

    const formatAxisTitle = (metric: string, statistic: string, timestep: string) => {
      const isPercentage = isPercentageMetric(metric);
      return `${getDisplayableMetricName(metric)}${isPercentage ? ' %' : ''} - ${statistic.replace(
        /_/g,
        ' ',
      )} @ ${timestep} Year${timestep !== '1' ? 's' : ''}`;
    };

    const xAxisTitleText =
      axes && axes.x.metric && axes.x.statistic
        ? formatAxisTitle(axes.x.metric, axes.x.statistic, axes.x.timestep)
        : 'X Axis details';
    const xAxisTitleTextWithUnits = isCurrencyMetric(axes?.x.metric) ? `${xAxisTitleText} £m` : xAxisTitleText;
    const yAxisTitleText =
      axes && axes.y.metric && axes.y.statistic
        ? formatAxisTitle(axes.y.metric, axes.y.statistic, axes.y.timestep)
        : 'Y Axis details';
    const yAxisTitleTextWithUnits = isCurrencyMetric(axes?.y.metric) ? `${yAxisTitleText} £m` : yAxisTitleText;

    const [series, setSeries] = useState<SeriesProps[] | []>([]);

    useEffect(() => {
      const activeFilters: FilterStateValues[] = [...filters].filter((filter: FilterStateValues) => filter.active);
      const validFilters: FilterStateValues[] = activeFilters.filter(
        (item) =>
          item.metric &&
          item.statistic &&
          item.timestep !== undefined &&
          item.minValue !== undefined &&
          item.maxValue !== undefined,
      );

      setValidActiveFilters(validFilters);
    }, [filters]);

    const getAssetsBreakdown = useCallback(
      (assets: PortfolioAsset[]) => assets.filter((asset: PortfolioAsset) => asset.value !== null),
      [],
    );

    // If filters have been applied... Reduce data down to records that match those filters
    useEffect(() => {
      if (data && isMounted()) {
        if (validActiveFilters && validActiveFilters.length > 0) {
          const newFilteredData = reduceDataByActiveFilters(data, validActiveFilters);

          setFilteredData(newFilteredData);
        } else {
          setFilteredData(data);
        }
      }
    }, [data, isMounted, validActiveFilters]);

    // Get all the strategy values that match our axes values
    useEffect(() => {
      const seriesData: SeriesProps[] = [];

      if (!strategies || !strategyNames || !filteredData) {
        return;
      }

      if (axes && axes.x.metric && axes.x.statistic && axes.y.metric && axes.y.statistic) {
        for (let i = 0; i < filteredData.length; i++) {
          const strategyId = filteredData[i].strategyId;
          seriesData[i] = {
            name: strategyNames[strategyId],
            color: colors[strategyId] || 'grey',
            data: [
              {
                x: getRecordValueByAxes(axes.x, filteredData[i].data),
                y: getRecordValueByAxes(axes.y, filteredData[i].data),
                assets: getAssetsBreakdown(strategies[strategyId].assets),
                strategyId: strategyId,
              },
            ],
            marker: { symbol: 'circle' },
          };
        }
        setSeries(seriesData);
      }
    }, [axes, dispatch, filteredData, getAssetsBreakdown, strategies, strategyNames]);

    // Update the available portfolios
    useEffect(() => {
      if (strategies && filteredData) {
        const availablePortfolios = [];

        for (let i = 0; i < filteredData.length; i++) {
          if (filteredData[i]) {
            const strategyId = filteredData[i].strategyId;
            availablePortfolios.push(strategies[strategyId]);
          }
        }

        dispatch(updateAvailablePortfolios(availablePortfolios));
      }
    }, [dispatch, filteredData, strategies]);

    // Display the tooltip with the point data beneath the name and a link to the portfolio
    const formatTooltip: Highcharts.FormatterCallbackFunction<ExtendedPoint> = function (this) {
      const { x, y, options, series } = this;
      let tooltipContent = `<b id="asset-title-${options.strategyId}">${htmlEntities(series.name)}</b><br/>`;
      tooltipContent += `<table class="coordinate-info">
                          <tbody>
                            <tr>
                              <th>${htmlEntities(xAxisTitleText)}:</th>
                              <td>${numberFormatter.format(isPercentageMetric(axes?.x.metric) ? 100 * x : x)}</td>
                            </tr>
                            <tr>
                              <th>${htmlEntities(yAxisTitleText)}:</th>
                              <td>${numberFormatter.format(isPercentageMetric(axes?.y.metric) ? 100 * y! : y!)}</td>
                            </tr>
                          </tbody>
                        </table>`;
      // Show button only if slots are free and it's not already been added
      if (!selectedPortfolio || selectedPortfolio !== options.strategyId) {
        tooltipContent += ` <button
                            class="show-portfolio-detail"
                            aria-labelledby="asset-title-${options.strategyId}"
                            onClick="window.dispatchEvent(new CustomEvent('EfficientFrontier.tooltipClick', { detail: { id: '${options.strategyId}' } }));"
                          >
                            View portfolio detail
                          </button>`;
      }
      return tooltipContent;
    };

    useEffect(() => {
      const tooltipClick = (event: Event) => {
        function isCustomEvent(event: Event): event is CustomEvent {
          return 'detail' in event;
        }

        if (!isCustomEvent(event)) {
          throw new Error('not a custom event');
        }

        onTooltipClick({ id: event.detail.id });
      };

      window.addEventListener('EfficientFrontier.tooltipClick', tooltipClick);

      return () => window.removeEventListener('EfficientFrontier.tooltipClick', tooltipClick);
    }, [dispatch, onTooltipClick]);

    const formatAxisLabelValue = (value: number, metric: string | undefined) => {
      if (isCurrencyMetric(metric)) return numberFormatter.format(value / 1000000);
      if (isPercentageMetric(metric)) return numberFormatter.format(value * 100);
      return numberFormatter.format(value);
    };

    const loadHandler = function (this: Highcharts.Chart, _: Event) {
      if (currentChart) {
        // Restore previous view settings
        const previousSettings = chartViewSettings[currentChart.id];

        if (previousSettings && isEqual(previousSettings.axes, currentChart.axes)) {
          if (
            previousSettings.x.minExtreme !== undefined ||
            previousSettings.x.maxExtreme !== undefined ||
            previousSettings.y.minExtreme !== undefined ||
            previousSettings.y.maxExtreme !== undefined
          ) {
            this.xAxis[0].setExtremes(previousSettings.x.minExtreme, previousSettings.x.maxExtreme);
            this.yAxis[0].setExtremes(previousSettings.y.minExtreme, previousSettings.y.maxExtreme);
            this.showResetZoom();
          }
        } else {
          // The axes values must have changed, so clear the old settings
          const newViewSettings = { ...chartViewSettings };
          delete newViewSettings[currentChart.id];
          setChartViewSettings(newViewSettings);
        }
      }
    };

    const selectionHandler = function (this: Highcharts.Chart, event: Highcharts.ChartSelectionContextObject) {
      if (currentChart) {
        if (!event.xAxis || !event.yAxis) {
          // This variant of the event isn't documented, but it happens when you reset the zoom
          delete chartViewSettings[currentChart.id];
        } else {
          const newViewSettings = { ...chartViewSettings };
          newViewSettings[currentChart.id] = {
            axes: currentChart.axes,
            x: {
              minExtreme: event.xAxis[0].min,
              maxExtreme: event.xAxis[0].max,
            },
            y: {
              minExtreme: event.yAxis[0].min,
              maxExtreme: event.yAxis[0].max,
            },
          };
          setChartViewSettings(newViewSettings);
        }
      }

      return true; // return true to allow selection/zoom area updates
    };

    return (
      <Wrapper mt={3} pb={rem(17)} pr={[0, rem(10)]}>
        <ResponsiveChartContainer ref={containerRef} height={rem(442)}>
          {loading && <Loading />}
          {!loading && (
            <ScatterChart
              key={JSON.stringify(currentChart)} // We need to force remounting of the chart if the chart or axes change to clear the view state
              options={{
                chart: {
                  showAxes: true,
                  spacingLeft: 33,
                  events: {
                    load: loadHandler,
                    selection: selectionHandler,
                  },
                },
                tooltip: {
                  formatter: undefined, // We need to remove the default formatter
                  pointFormatter: formatTooltip,
                  shared: true,
                  stickOnContact: true,
                  useHTML: true,
                },
                xAxis: {
                  gridLineWidth: 0,
                  labels: {
                    formatter: function (this) {
                      return formatAxisLabelValue(this.value, axes?.x.metric);
                    },
                    style: {
                      color: themeGet('colors.chartTextColor')(props),
                      fontSize: rem(9),
                      fontWeight: themeGet('fontWeight.medium')(props),
                      opacity: 0.8,
                    },
                  },
                  lineColor: themeGet('colors.chartDashes')(props),
                  showEmpty: true,
                  tickWidth: 0,
                  title: {
                    text: xAxisTitleTextWithUnits,
                    style: {
                      color: themeGet('colors.chartTextColor')(props),
                      fontSize: rem(9),
                      fontWeight: themeGet('fontWeight.medium')(props),
                      opacity: 0.5,
                    },
                    y: 2,
                  },
                },
                yAxis: {
                  labels: {
                    formatter: function (this) {
                      return formatAxisLabelValue(this.value, axes?.y.metric);
                    },
                    style: {
                      color: themeGet('colors.chartTextColor')(props),
                      fontSize: rem(9),
                      fontWeight: themeGet('fontWeight.medium')(props),
                      opacity: 0.8,
                    },
                  },
                  gridLineColor: themeGet('colors.chartDashes')(props),
                  gridLineDashStyle: 'ShortDash',
                  showEmpty: true,
                  title: {
                    reserveSpace: false,
                    style: {
                      color: themeGet('colors.chartTextColor')(props),
                      fontSize: rem(9),
                      fontWeight: themeGet('fontWeight.medium')(props),
                      opacity: 0.5,
                    },
                    text: yAxisTitleTextWithUnits,
                    x: -12,
                  },
                },
                series: series as SeriesScatterOptions[],
                legend: {
                  enabled: false,
                },
                plotOptions: {
                  series: {
                    stickyTracking: false,
                  },
                },
              }}
            />
          )}
        </ResponsiveChartContainer>
      </Wrapper>
    );
  },
);
