import { SingleStrategies, SingleStrategy } from '../../components/EfficientFrontier/types';
import { FilterStateValues } from '../../features/filter/FilterState';
import { isPercentageMetric } from '../../utils';

interface AxisRecord {
  metric: string | undefined;
  statistic: string | undefined;
  timestep: string;
}

// Get an individual record that matches our axis
export const getRecordValueByAxes = (axis: AxisRecord, data: SingleStrategy[]) => {
  if (data === undefined) {
    return undefined;
  }

  const record = data.find(
    (item: AxisRecord) =>
      item.metric === axis.metric &&
      item.statistic === axis.statistic &&
      item.timestep.toString() === axis.timestep?.toString(),
  );

  if (record && record.value) {
    return parseFloat(record.value);
  }
  return undefined;
};

export const parseStrategyValue = ({ metric, value }: SingleStrategy): number => {
  const num = parseFloat(value);
  return isPercentageMetric(metric) ? num * 100 : num;
};

export const getRecordByFilter = (filter: FilterStateValues, records: SingleStrategy[]) =>
  records.find(
    (item: SingleStrategy) =>
      item.metric === filter.metric &&
      item.statistic === filter.statistic &&
      item.timestep.toString() === filter.timestep.toString() &&
      (filter.minValue === undefined || filter.minValue < parseStrategyValue(item)) &&
      (filter.maxValue === undefined || parseStrategyValue(item) <= filter.maxValue),
  );

// Go through data and filters and find data that matches EVERY filter
export const reduceDataByActiveFilters = (data: SingleStrategies[], validActiveFilters: FilterStateValues[]) =>
  data.filter((d) => validActiveFilters.every((filter) => getRecordByFilter(filter, d.data)));
