import { combineReducers } from '@reduxjs/toolkit';
import { almReducer } from '../features/alm/almSlice';
import { assessmentChartsReducer } from '../features/assessmentCharts/assessmentChartSlice';
import { filterReducer } from '../features/filter/filterSlice';
import { metricsReducer } from '../features/metrics/metricsSlice';
import { portfoliosReducer } from '../features/portfolios/portfolioSlice';
import { statisticsReducer } from '../features/statistics/statisticsSlice';

export const rootReducer = combineReducers({
  alm: almReducer,
  assessmentCharts: assessmentChartsReducer,
  filter: filterReducer,
  metrics: metricsReducer,
  portfolios: portfoliosReducer,
  statistics: statisticsReducer,
});
