import React, { MouseEvent } from 'react';
import { VerticalMenu, VerticalMenuButton } from './styles';

interface TabLabel {
  text: string;
  icon?: React.ReactNode;
  id: string;
  controlledId: string;
}

interface VerticalTabMenuProps {
  tabLabels: TabLabel[];
  activeTab?: string | undefined;
  onTabItemClick: (event: MouseEvent<HTMLButtonElement>) => void;
}

export const VerticalTabMenu: React.FC<VerticalTabMenuProps> = ({ activeTab, onTabItemClick, tabLabels }) => {
  return (
    <VerticalMenu>
      {tabLabels.map(({ controlledId, icon, id, text }) => (
        <VerticalMenuButton
          key={id}
          aria-controls={controlledId}
          aria-selected={activeTab === controlledId}
          id={id}
          role="tab"
          type="button"
          onClick={onTabItemClick}
        >
          {icon}
          {text}
        </VerticalMenuButton>
      ))}
    </VerticalMenu>
  );
};
