import { BoxProps } from '@risk-first/ui-core';
import { withTheme } from 'emotion-theming';
import React, { useCallback, useEffect, useState } from 'react';
import { HeadingIcon, OuterBox, SectionHeading } from './style';

export interface ClosableSectionProps extends BoxProps {
  children: React.ReactNode;
  icon?: React.ReactNode;
  iconAlignment?: 'left' | 'right';
  isOpen: boolean;
  setOpen: (state: boolean | ((prevState: boolean) => boolean)) => void;
  title: string;
}

export const ClosableSection = withTheme((props: ClosableSectionProps) => {
  const { children, icon = <HeadingIcon />, iconAlignment = 'left', isOpen, setOpen, title, ...otherProps } = props;

  // We don't want to render child components if they aren't initially visible
  // but we do want to retain them so that they don't need to e.g. fetch data again
  const [wasShown, setWasShown] = useState(isOpen);

  useEffect(() => {
    if (isOpen && !wasShown) {
      setWasShown(true);
    }
  }, [isOpen, wasShown]);

  const handleHeadingClick = useCallback(() => setOpen((isOpen) => !isOpen), [setOpen]);

  return (
    <OuterBox {...otherProps}>
      <SectionHeading aria-expanded={isOpen} onClick={handleHeadingClick}>
        {iconAlignment === 'left' && icon}
        <span style={{ flex: 1 }}>{title}</span>
        {iconAlignment === 'right' && icon}
      </SectionHeading>
      {/* Note: we use wasShown on the following line instead of (isOpen || wasShown) because */}
      {/* the effect updates wasShown when isOpen is true and we can save a re-render */}
      {wasShown && <section style={isOpen ? {} : { display: 'none' }}>{children}</section>}
    </OuterBox>
  );
});
