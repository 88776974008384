import { WaterfallChart } from '@risk-first/ui-waterfall-chart';
import merge from 'deepmerge';
import { withTheme } from 'emotion-theming';
import { Options, SeriesWaterfallOptions } from 'highcharts';
import React, { useEffect, useMemo, useState } from 'react';
import { useMountedState } from 'react-use';
import { Loading } from '..';
import { getValueAtRiskAttribution, getValueAtRiskBreakdown } from '../../api/valueAtRisk';
import { DefaultOptions, getDefaultChartOptions } from '../../lib/charts/defaultChartOptions';
import { ResponsiveChartContainer } from '../../styles';
import { getFixedLocaleNumberFormatter } from '../../utils';
import { ValueAtRiskDataPoint, ValueAtRiskJSON, ValueAtRiskProps, ValueAtRiskTypes } from './types';

const numberFormatter = getFixedLocaleNumberFormatter();

const getComponentDefaultChartOptions = (props: { theme: any }) => ({
  chart: {
    spacingLeft: 8,
  },
  yAxis: {
    min: 0,
    title: {
      text: '£m',
    },
    y: 2,
  },
});

export const ValueAtRisk: React.FC<ValueAtRiskProps> = withTheme((props) => {
  const { height, portfolio, type } = props as ValueAtRiskProps;
  const defaultOptions = useMemo(
    () =>
      merge(
        getDefaultChartOptions({ theme: props.theme }),
        getComponentDefaultChartOptions({ theme: props.theme }),
      ) as DefaultOptions,
    [props.theme],
  );
  const [loading, setLoading] = useState<boolean>(true);
  const [rawData, setRawData] = useState<ValueAtRiskJSON | undefined>(undefined);
  const [waterfallOptions, setWaterfallOptions] = useState<Options>(defaultOptions);
  const isMounted = useMountedState();

  // Fetch the correct JSON file based on selectedPortfolio
  useEffect(() => {
    async function fetchData(selectedPortfolio: string) {
      let importedData: ValueAtRiskJSON[] | undefined = undefined;

      if (type === ValueAtRiskTypes.Attribution) {
        importedData = await getValueAtRiskAttribution(selectedPortfolio);
      } else if (type === ValueAtRiskTypes.Breakdown) {
        importedData = await getValueAtRiskBreakdown(selectedPortfolio);
      }

      if (isMounted() && importedData) {
        const reducedImportedData = importedData.find((record) => record.strategyId === selectedPortfolio);

        setRawData(reducedImportedData);
        setLoading(false);
      }
    }

    if (portfolio !== undefined) {
      setLoading(true);
      fetchData(portfolio);
    }
  }, [isMounted, portfolio, type]);

  useEffect(() => {
    let newWaterfallOptions = {};

    if (rawData && portfolio !== undefined) {
      const data: ValueAtRiskDataPoint[] = [];

      rawData.data.forEach((record) => {
        const dataPoint: ValueAtRiskDataPoint = {
          name: record.name,
          y: Math.trunc(record.data),
        };

        // While this product is a demo, we can't be 100% sure of naming conventions so it's better to be safe
        const simpleName = record.name.toLowerCase().replace(/ /g, '');

        // We want 'Diversification' and 'Net Risk' to visually differ from other values
        // We also want 'Net Risk' to always be a negative number - despite the data
        if (simpleName === 'diversification') {
          dataPoint.color = '#1596c8';
          dataPoint.y = Math.trunc(record.data);
        } else if (simpleName === 'netrisk') {
          dataPoint.color = '#581bb8';
          dataPoint.y = Math.trunc(-Math.abs(record.data));
        }

        data.push(dataPoint);
      });

      // build series array
      if (data) {
        const series: SeriesWaterfallOptions[] = [
          {
            data: data,
            color: '#356ac2',
            upColor: '#581bb8',
            type: 'waterfall',
          },
        ];
        newWaterfallOptions = {
          yAxis: {
            labels: {
              formatter: function (this: { value: number }) {
                return numberFormatter.format(this.value / 1000000);
              },
            },
          },
          series,
        };
      }
    }

    setWaterfallOptions(
      merge(defaultOptions, newWaterfallOptions, {
        arrayMerge: (destinationArray, sourceArray) => sourceArray,
      }),
    );
  }, [defaultOptions, rawData, portfolio]);

  return (
    <ResponsiveChartContainer height={height} mt={3}>
      {loading && <Loading />}
      {!loading && <WaterfallChart options={waterfallOptions} />}
    </ResponsiveChartContainer>
  );
});
