import { Checkbox } from '@risk-first/ui-checkbox';
import { Box, H2 } from '@risk-first/ui-core';
import { PercentageAreaChart } from '@risk-first/ui-percentage-area-chart';
import { themeGet } from '@styled-system/theme-get';
import { withTheme } from 'emotion-theming';
import { Options, Point, SeriesAreaOptions } from 'highcharts';
import { rem } from 'polished';
import React from 'react';
import { htmlEntities } from '../../../utils';
import { getData, getPercentageData } from './demoData';
import { EfficientFrontierChartContainer, EfficientPortfolioDataTable, NoLabelCheckboxWrapper } from './styles';
import { OptimizationItem } from './types';

interface GenerateEfficientPortfoliosSubPanelProps {
  optimization: OptimizationItem;
}

export const GenerateEfficientPortfoliosSubPanel: React.FC<GenerateEfficientPortfoliosSubPanelProps> = withTheme(
  (props: GenerateEfficientPortfoliosSubPanelProps) => {
    const { optimization } = props;
    const labelFormatter = (value: number) => `${value.toFixed(2)}%`;

    const xAxisLabel = 'Fund ID';
    const yAxisLabel = 'Allocation';

    const tableData = getData(optimization.id || 0);

    const rawPercentageData = getPercentageData();
    const percentageChartColors = ['#80a0d6', '#356ac2', '#7667d0', '#a91963', '#581bb8'];
    const percentageChartData: SeriesAreaOptions[] = rawPercentageData.map((data, index) => ({
      name: `${rawPercentageData.length - index}`,
      color: percentageChartColors[index] || `hsl(${110 * index}, 60%, 50%)`, // fallback in case there are more series than colors
      data,
      type: 'area',
    }));

    const formatTooltip: Highcharts.FormatterCallbackFunction<Point> = function (this) {
      const { x, y, series } = this;
      let tooltipContent = `<b>${htmlEntities(series.name)}</b><br/>`;
      tooltipContent += `<table class="coordinate-info">
                        <tbody>
                          <tr>
                            <th>${htmlEntities(xAxisLabel)}:</th>
                            <td>${x}</td>
                          </tr>
                          <tr>
                            <th>${htmlEntities(yAxisLabel)}:</th>
                            <td>${labelFormatter(y!)}</td>
                          </tr>
                        </tbody>
                      </table>`;
      return tooltipContent;
    };

    const chartOptions: Options = {
      chart: {
        style: {
          fontSize: rem(10),
        },
        marginRight: 11,
      },
      legend: {
        enabled: false,
      },
      plotOptions: {
        area: {
          lineWidth: 0,
          marker: {
            enabled: false,
          },
          stacking: 'normal',
        },
        series: {
          pointPlacement: 'on',
        },
      },
      tooltip: {
        formatter: undefined, // remove the default formatter
        pointFormatter: formatTooltip,
        useHTML: true,
      },
      xAxis: {
        margin: 0,
        title: {
          text: xAxisLabel,
          style: {
            color: themeGet('colors.chartTextColor')(props),
            fontSize: rem(10),
            fontWeight: themeGet('fontWeight.medium')(props),
          },
        },
        tickWidth: 0,
        labels: {
          formatter: function (this) {
            return this.pos.toString();
          },
          style: {
            fontSize: rem(10),
          },
        },
      },
      yAxis: {
        max: 100,
        title: {
          text: yAxisLabel,
          style: {
            color: themeGet('colors.chartTextColor')(props),
            fontSize: rem(10),
            fontWeight: themeGet('fontWeight.medium')(props),
          },
        },
        labels: {
          style: {
            fontSize: rem(10),
          },
        },
        tickPixelInterval: 40,
      },
      series: percentageChartData,
    };

    return (
      <>
        <H2 fontWeight={themeGet('fontWeight.medium')(props)} mb={rem(10)} ml={rem(33)} mt={rem(25)}>
          Efficient Portfolios
        </H2>
        <EfficientFrontierChartContainer
          borderBottom={`1px solid ${themeGet('colors.border')(props)}`}
          height={rem(450)}
        >
          <PercentageAreaChart options={chartOptions} />
        </EfficientFrontierChartContainer>

        <H2 fontWeight={themeGet('fontWeight.medium')(props)} mb={rem(10)} ml={rem(33)} mt={rem(25)}>
          Efficient Portfolios Allocations
        </H2>
        <Box p={rem(20)}>
          <EfficientPortfolioDataTable>
            <thead>
              <tr>
                <th>
                  <NoLabelCheckboxWrapper>
                    <Checkbox id="sg-efficient-portfolios-0" onChange={() => {}} />
                  </NoLabelCheckboxWrapper>
                </th>
                {tableData.map((_, index) => (
                  <th key={index}>
                    <NoLabelCheckboxWrapper>
                      <Checkbox id={`sg-efficient-portfolios-${index + 1}`} onChange={() => {}} />
                    </NoLabelCheckboxWrapper>
                  </th>
                ))}
              </tr>
              <tr>
                <th>Metric</th>
                {tableData.map((_, index) => (
                  <th key={index}>
                    {index + 1 >= 10 ? '' : '\u00A0'}F{index + 1}
                  </th>
                ))}
              </tr>
            </thead>
            <tbody>
              <tr>
                <th>Volatility (%pa) vs Benchmark</th>
                {tableData.map(([x, _], index) => (
                  <td key={index}>{x.toFixed(2)}%</td>
                ))}
              </tr>
              <tr>
                <th>Expected Return (%pa) vs Benchmark</th>
                {tableData.map(([_, y], index) => (
                  <td key={index}>{y.toFixed(2)}%</td>
                ))}
              </tr>
            </tbody>
          </EfficientPortfolioDataTable>
        </Box>
      </>
    );
  },
);
