import { Box, Flex, Input, Textarea } from '@risk-first/ui-core';
import { themeGet } from '@styled-system/theme-get';
import { withTheme } from 'emotion-theming';
import { rem } from 'polished';
import React, { useState } from 'react';
import { Label, Select } from '../../../styles';
import { ClosableSection } from '../../ClosableSection';
import { ConstraintsList } from './ConstraintsList';
import { HalfWidthInputBox, ScenarioDataInputBox, ScenarioDataLink } from './styles';

export const GenerateConfigureSubPanel = withTheme((props) => {
  // Closable section states
  const [isScenarioDataOpen, setScenarioDataOpen] = useState<boolean>(true);
  const [isConfigureOpen, setConfigureOpen] = useState<boolean>(true);
  const [isLogOpen, setLogOpen] = useState<boolean>(true);

  // Temporary style to meet the mockup requirements
  const disabledStyle = {
    backgroundColor: 'transparent',
    opacity: 0.8,
  };

  return (
    <Flex marginBottom="-1px" /* Trim off the bottom borders because we're already in a bordered container */>
      <Box borderRight={`1px solid ${themeGet('colors.border')(props)}`} flex="1">
        <form>
          <ClosableSection isOpen={isScenarioDataOpen} setOpen={setScenarioDataOpen} title="Scenario Data">
            <Flex
              backgroundColor={themeGet('colors.athensGray')(props)}
              borderBottom={`1px solid ${themeGet('colors.border')(props)}`}
              minHeight={rem(40)}
            >
              <Flex alignItems="center" flex="1" flexWrap="wrap" pl="20px">
                <ScenarioDataInputBox flex="64 0 0" minWidth="max-content">
                  <label htmlFor="sg-scenario-set">Scenario set</label>
                  <Input defaultValue="MA Scenarios Q2 2020" id="sg-scenario-set" style={{ width: '12em' }} />
                </ScenarioDataInputBox>
                <ScenarioDataInputBox flex="36 0 0" minWidth="max-content">
                  <label htmlFor="sg-time-horizon">Time Horizon</label>
                  <Input defaultValue={5} id="sg-time-horizon" />
                </ScenarioDataInputBox>
              </Flex>
              <ScenarioDataLink>Configure Scenario Data</ScenarioDataLink>
            </Flex>
            <Flex
              backgroundColor={themeGet('colors.athensGray')(props)}
              /* this row doesn't have a border and we need to match the size of the above one (box-sizing breaks pixel alignment) */
              minHeight={`calc(${rem(40)} - 1px)`}
            >
              <Flex alignItems="center" flex="1" flexWrap="wrap" pl="20px">
                <ScenarioDataInputBox flex="32 0 0" minWidth="max-content">
                  <label htmlFor="sg-timesteps">Timesteps</label>
                  <Input defaultValue={40} id="sg-timesteps" />
                </ScenarioDataInputBox>
                <ScenarioDataInputBox flex="32 0 0" minWidth="max-content">
                  <label htmlFor="sg-assets">Assets</label>
                  <Input defaultValue={87} id="sg-assets" />
                </ScenarioDataInputBox>
                <ScenarioDataInputBox flex="36 0 0" minWidth="max-content">
                  <label htmlFor="sg-currency">Currency</label>
                  <Input defaultValue={'-'} id="sg-currency" maxLength={3} />
                </ScenarioDataInputBox>
              </Flex>
              <ScenarioDataLink>Calculate Asset Statistics</ScenarioDataLink>
            </Flex>
          </ClosableSection>

          <ClosableSection isOpen={isConfigureOpen} setOpen={setConfigureOpen} title="Configure">
            <Box backgroundColor={themeGet('colors.athensGray')(props)} padding="20px">
              <Flex alignItems="center" justifyContent="space-between" mb={3}>
                <HalfWidthInputBox>
                  <Label htmlFor="sg-efficient-portfolios">Efficient Portfolios</Label>
                  <Input defaultValue="10" id="sg-efficient-portfolios"></Input>
                </HalfWidthInputBox>
                <HalfWidthInputBox>
                  <Label htmlFor="sg-benchmark">Benchmark</Label>
                  <Select
                    id="sg-benchmark"
                    name="sg-benchmark"
                    required={true}
                    value="1"
                    variant="default"
                    onChange={() => {}}
                  >
                    <option value="1">Benchmark Gov 6-10Y</option>
                  </Select>
                </HalfWidthInputBox>
              </Flex>
              <Flex alignItems="center" justifyContent="space-between">
                <HalfWidthInputBox>
                  <Label htmlFor="sg-minimum-volatility">Minimum Volatility (%)</Label>
                  <Input defaultValue="0.50" id="sg-minimum-volatility"></Input>
                </HalfWidthInputBox>
                <HalfWidthInputBox>
                  <Label htmlFor="sg-maximum-volatility">Maximum Volatility (%)</Label>
                  <Input defaultValue="10.0" id="sg-maximum-volatility"></Input>
                </HalfWidthInputBox>
              </Flex>
            </Box>
          </ClosableSection>

          <ClosableSection isOpen={isLogOpen} setOpen={setLogOpen} title="Log">
            <Box bg={themeGet('colors.athensGray')(props)}>
              <Textarea
                aria-label="Log text"
                defaultValue={'Launching Optimization Optimization 1...'}
                rows={10}
                style={{ width: '100%', ...disabledStyle }}
              />
            </Box>
          </ClosableSection>
        </form>
      </Box>

      <Box flex="1" maxWidth="336px">
        <ConstraintsList />
      </Box>
    </Flex>
  );
});
