import { CloseIconComponent } from '@risk-first/ui-assets';
import { Checkbox } from '@risk-first/ui-checkbox';
import { Box, Flex, VisuallyHiddenLabel } from '@risk-first/ui-core';
import { themeGet } from '@styled-system/theme-get';
import { withTheme } from 'emotion-theming';
import { rem } from 'polished';
import React, { FormEvent, useCallback, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { isEqual } from 'underscore';
import { RootState } from '../../app/RootState';
import { FilterStateValues } from '../../features/filter/FilterState';
import { deleteFilter, updateFilter } from '../../features/filter/filterSlice';
import { MIN_MAX_FIELD_VALIDITY } from '../../lib/constants/validation';
import { FlatButton, Label, NumberInput, Select, Hyphen } from '../../styles';
import { getDisplayableMetricName, getFixedLocaleNumberFormatter } from '../../utils';
import { FieldsGrid, Legend } from './styles';

interface FilterProps {
  filter: FilterStateValues;
  filterIndex: number;
}

// In the form these values are strings, which we need or you couldn't type a "."
// but the redux model has them as (numeric | undefined)
const forceNumericRange = ({ minValue, maxValue, ...rest }: FilterStateValues): FilterStateValues => ({
  ...rest,
  minValue: minValue !== undefined ? Number(minValue) : undefined,
  maxValue: maxValue !== undefined ? Number(maxValue) : undefined,
});

const numberFormatter = getFixedLocaleNumberFormatter({
  maximumFractionDigits: 10,
  minimumFractionDigits: 0,
});

export const Filter: React.FC<FilterProps> = withTheme(({ filter, index, filterIndex, ...props }) => {
  const refForm = useRef<HTMLFormElement>(null);
  const dispatch = useDispatch();

  // State for form fields
  const [form, setForm] = useState<FilterStateValues>({ ...filter });

  const updateField = useCallback(
    (event: FormEvent<HTMLInputElement | HTMLSelectElement>) => {
      const oldForm = form;

      const newForm = {
        ...oldForm,
        active: filter.active,
        [event.currentTarget.name]: event.currentTarget.value,
      };

      const isFormValid = refForm?.current?.checkValidity();

      setForm(newForm);

      // If all the data is present AND the data has been changed then dispatch
      // We don't dispatch if filter isn't active as it is waste of resource to
      // potentially call APIs for a non-active filter.
      const isNewDataEqual: boolean = isEqual(newForm, filter);

      if (isFormValid && filter.active && !isNewDataEqual) {
        dispatch(updateFilter(forceNumericRange(newForm)));
      }
    },
    [dispatch, filter, form],
  );

  const handleNumberInputBlur = useCallback((event: FormEvent<HTMLInputElement>) => {
    const value: number = parseFloat(event.currentTarget.value);
    if (value) {
      event.currentTarget.value = numberFormatter.format(value);
    }
  }, []);

  const handleNumberInputFocus = useCallback((event: FormEvent<HTMLInputElement>) => {
    const value: string = event.currentTarget.value.toString();
    event.currentTarget.value = value.replace(/,/g, '');
  }, []);

  const handleDeleteClick = useCallback(() => {
    dispatch(deleteFilter(filter.id));
  }, [dispatch, filter]);

  const handleCheckboxChange = useCallback(
    (event: FormEvent<HTMLInputElement>) => {
      const isFormValid = refForm?.current?.checkValidity();
      // The checkbox we use _like_ a submit button
      const activeState = event.currentTarget.checked;
      let newFilter: FilterStateValues;
      // if the form is valid and the activeState is true then dispatch the form
      // otherwise just dispatch the active state and the old values
      if (activeState) {
        newFilter = {
          ...form,
          active: activeState,
        };
      } else {
        newFilter = {
          ...filter,
          active: activeState,
        };
      }
      // When set to active, only dispatch a valid form so we don't pass bad data
      if ((activeState && isFormValid) || !activeState) {
        dispatch(updateFilter(forceNumericRange(newFilter)));
      }
    },
    [dispatch, filter, form],
  );

  // Set the values for the metrics/statistics dropdowns/inputs
  const metrics = useSelector((state: RootState) => state.metrics);
  const statistics = useSelector((state: RootState) => state.statistics);
  const years = 11; // remember years is zero-indexed so 11 = 10

  // Build year <option>s
  const yearOptions = [];
  for (let i = 0; i < years; i++) {
    const yearPlural: string = i === 1 ? 'year' : 'years';
    yearOptions.push(
      <option key={i} value={i}>
        {i} {yearPlural}
      </option>,
    );
  }

  const handleFilterSubmit = useCallback(
    (event: FormEvent) => {
      event.preventDefault();

      const newFilter = {
        ...form,
        active: filter.active,
      };

      dispatch(updateFilter(forceNumericRange(newFilter)));
    },
    [dispatch, filter, form],
  );

  return (
    <Box>
      <form ref={refForm} action="" method="post" noValidate={true} onSubmit={handleFilterSubmit}>
        <Flex borderBottom={`1px solid ${themeGet('colors.border')(props)}`} flexWrap="wrap" px={rem(20)} py={3}>
          <Flex alignItems="center" justifyContent="space-between" pb={3} width="100%">
            <Flex>
              <VisuallyHiddenLabel htmlFor={`${filter.id}-active`}>
                Toggle on/off filter {filterIndex + 1}
              </VisuallyHiddenLabel>
              <Checkbox checked={filter.active} id={`${filter.id}-active`} onChange={handleCheckboxChange} />
              <Legend>Filter {filterIndex + 1}</Legend>
            </Flex>
            <FlatButton title="Remove filter {filterIndex + 1}" type="button" onClick={handleDeleteClick}>
              <CloseIconComponent />
            </FlatButton>
          </Flex>
          <FieldsGrid>
            <Box>
              <Label htmlFor={`${filter.id}-metric`}>Select metric</Label>
              <Select
                id={`${filter.id}-metric`}
                name="metric"
                required={true}
                value={form.metric}
                variant="default"
                onChange={updateField}
              >
                <option value="">Select metric</option>
                {metrics?.map((value) => (
                  <option key={value} value={value}>
                    {getDisplayableMetricName(value.toString())}
                  </option>
                ))}
              </Select>
            </Box>
            <Box>
              <Label htmlFor={`${filter.id}-statistic`}>Select statistic</Label>
              <Select
                id={`${filter.id}-statistic`}
                name="statistic"
                required={true}
                value={form.statistic}
                variant="default"
                onChange={updateField}
              >
                <option value="">Select statistic</option>
                {statistics?.map((value) => (
                  <option key={value} value={value}>
                    {value.toString().replace(/_/g, ' ')}
                  </option>
                ))}
              </Select>
            </Box>

            <div>
              <Label htmlFor={`${filter.id}-timestep`}>Select term</Label>
              <Select
                id={`${filter.id}-timestep`}
                name="timestep"
                required={true}
                value={form.timestep}
                variant="default"
                onChange={updateField}
              >
                {yearOptions}
              </Select>
            </div>
            <Flex alignItems="center" justifyContent="space-between">
              <Box flex={1} textAlign="center">
                <Label htmlFor={`${filter.id}-minValue`}>Minimum</Label>
                <NumberInput
                  id={`${filter.id}-minValue`}
                  inputMode="numeric"
                  name="minValue"
                  pattern={MIN_MAX_FIELD_VALIDITY}
                  required={true}
                  style={{ padding: '8px 2px' }}
                  type="text"
                  value={form.minValue || ''}
                  variant="default"
                  onBlur={handleNumberInputBlur}
                  onChange={updateField}
                  onFocus={handleNumberInputFocus}
                />
              </Box>
              <Hyphen>&ndash;</Hyphen>
              <Box flex={1} textAlign="center">
                <Label htmlFor={`${filter.id}-maxValue`}>Maximum</Label>
                <NumberInput
                  id={`${filter.id}-maxValue`}
                  inputMode="numeric"
                  name="maxValue"
                  pattern={MIN_MAX_FIELD_VALIDITY}
                  required={true}
                  style={{ padding: '8px 2px' }}
                  type="text"
                  value={form.maxValue || ''}
                  variant="default"
                  onBlur={handleNumberInputBlur}
                  onChange={updateField}
                  onFocus={handleNumberInputFocus}
                />
              </Box>
            </Flex>
          </FieldsGrid>
        </Flex>
      </form>
    </Box>
  );
});
