import styled from '@emotion/styled';
import { themeGet } from '@styled-system/theme-get';
import { rem } from 'polished';

export const VerticalMenu = styled.div`
  display: flex;
  height: ${rem(33)};
  transform: rotate(90deg) translateX(50%);
  width: ${rem(30)};
`;

export const VerticalMenuButton = styled.button`
  align-items: center;
  background: transparent;
  border: ${rem(1)} solid transparent;
  bottom: ${rem(-2)};
  color: ${themeGet('colors.darkPink')};
  cursor: pointer;
  display: flex;
  font-size: ${themeGet('fontSizes.1')};
  height: ${rem(29)};
  line-height: 1;
  padding: ${rem(7)} ${rem(14)} ${rem(9)};
  position: relative;
  text-align: center;
  white-space: nowrap;
  writing-mode: inherit;

  &::after {
    background: currentColor;
    bottom: -${rem(3)};
    content: '';
    display: block;
    flex: none;
    height: ${rem(3)};
    left: 0;
    opacity: 0;
    position: absolute;
    width: 100%;
  }

  &:hover,
  &:focus {
    background: ${themeGet('colors.athensGray')};
  }

  &:hover::after,
  &:focus::after {
    background: ${themeGet('colors.darkPink')};
    opacity: 1;
  }

  &[aria-selected='true'] {
    font-weight: ${themeGet('fontWeight.medium')};
    padding-left: ${rem(13.5)};
    padding-right: ${rem(13.5)};
  }

  &[aria-selected='true']::after {
    opacity: 1;
  }

  svg {
    margin-right: ${rem(5)};
    position: relative;
    top: ${rem(2)};
  }
`;
