import styled from '@emotion/styled';
import { themeGet } from '@styled-system/theme-get';
import { rem } from 'polished';
import { BaseMenu, ButtonIcon } from '../../styles';

export const FilterButton = styled.button`
  background: transparent;
  border: ${rem(1)} solid transparent;
  color: ${themeGet('colors.tableHeader')};
  cursor: pointer;
  display: block;
  font-family: inherit;
  font-size: ${themeGet('fontSizes.1')};
  font-weight: ${themeGet('fontWeight.medium')};
  height: ${themeGet('button.height')};
  line-height: ${themeGet('button.height')};
  margin-right: ${rem(20)};
  padding: 2px;
  text-decoration: none;

  &:hover {
    text-decoration: none;
  }
`;

export const FilterButtonIcon = styled(ButtonIcon)`
  background-color: ${themeGet('colors.ghostWhite')};
  border: ${rem(1)} solid transparent;
`;

export const MenuContainer = styled(BaseMenu)`
  margin-left: 3.5em;

  color: ${themeGet('colors.dark')};
  padding: 10px 20px 0 20px;

  & label div,
  & button {
    font-size: ${themeGet('fontSizes.1')};
    font-weight: 400;
  }
`;
