import { Box, Flex, H2, VisuallyHiddenLabel } from '@risk-first/ui-core';
import { TabMenu } from '@risk-first/ui-tab-menu';
import { themeGet } from '@styled-system/theme-get';
import { withTheme } from 'emotion-theming';
import { rem } from 'polished';
import React, { forwardRef, FormEvent, MouseEvent, useCallback, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../app/RootState';
import { ALMProjection, Cashflow, Curve, MarketEvents, ValueAtRisk } from '../../components';
import { ValueAtRiskTypes } from '../../components/ValueAtRisk/types';
import { selectCurrentAssessmentChartAxes } from '../../features/assessmentCharts/assessmentChartSlice';
import { Portfolio } from '../../features/portfolios/PortfolioState';
import { removeSelectedPortfolio, updateSelectedPortfolio } from '../../features/portfolios/portfolioSlice';
import { Select } from '../../styles';
import { ChartContainer, ChartTitle, EmptyPortfolio, OverviewGrid } from './styles';

export const SelectedPortfolio = withTheme(
  forwardRef<HTMLDivElement>((props, forwardedRef) => {
    const dispatch = useDispatch();
    const { availablePortfolios, selectedPortfolio } = useSelector((state: RootState) => state.portfolios);
    const axes = useSelector(selectCurrentAssessmentChartAxes);
    const axesSet = axes && axes.x.metric && axes.x.statistic && axes.y.metric && axes.y.statistic;
    const [marketEvent, setMarketEvent] = useState<string | undefined>(undefined);

    // Remove selected portfolio
    const handleClearClick = useCallback(() => {
      if (selectedPortfolio !== undefined) {
        dispatch(removeSelectedPortfolio());
      }
    }, [dispatch, selectedPortfolio]);

    // Change selected portfolio
    const handleUpdateSubmit = useCallback((event: FormEvent<HTMLFormElement>) => {
      event.preventDefault();
    }, []);

    const handleSelectChange = useCallback(
      (event: FormEvent<HTMLSelectElement>) => {
        const newValue: string = event.currentTarget.value;

        if (newValue) {
          dispatch(updateSelectedPortfolio(newValue));
        }
      },
      [dispatch],
    );

    // Tabs - for different chart types/detailed views
    const [activeTab, setActiveTab] = useState<string>('tabpanel-id-0');
    const handleTabItemClick = useCallback((event: MouseEvent<HTMLButtonElement>) => {
      const button = event.currentTarget;
      const newActiveTab = button.getAttribute('aria-controls')!.toString();

      setActiveTab(newActiveTab);
    }, []);

    return (
      <Box ref={forwardedRef} mt={3}>
        <Flex alignItems="center" mb={2} px={3}>
          <H2 fontSize={3} fontWeight={themeGet('fontWeight.medium')(props)} mr={3} pl={[rem(10), rem(18)]}>
            Selected portfolio
          </H2>
          <Box mr={2}>
            {axesSet && availablePortfolios && (
              <form action="" method="post" onSubmit={handleUpdateSubmit}>
                <Flex alignItems="center" flexWrap="wrap">
                  <VisuallyHiddenLabel htmlFor="portfolio-id">Choose</VisuallyHiddenLabel>
                  <Box mr={2}>
                    <Select
                      id="portfolio-id"
                      isClearEnabled={true}
                      name="portfolio"
                      value={selectedPortfolio !== undefined ? selectedPortfolio : ''}
                      variant="default"
                      onChange={handleSelectChange}
                      onClear={handleClearClick}
                    >
                      <option value="">No portfolio selected</option>
                      {availablePortfolios.map((strategy: Portfolio) => (
                        <option key={strategy.strategyID} value={strategy.strategyID}>
                          {strategy.name}
                        </option>
                      ))}
                    </Select>
                  </Box>
                </Flex>
              </form>
            )}
          </Box>
        </Flex>
        {selectedPortfolio !== undefined && (
          <Box>
            <Box borderBottom={`1px solid ${themeGet('colors.border')(props)}`} px={3}>
              <TabMenu
                items={[
                  {
                    'aria-controls': 'tabpanel-id-0',
                    'aria-selected': activeTab === 'tabpanel-id-0',
                    id: 'tab-id-0',
                    label: 'Overview',
                    role: 'tab',
                    type: 'button',
                    onClick: handleTabItemClick,
                  },
                  {
                    'aria-controls': 'tabpanel-id-1',
                    'aria-selected': activeTab === 'tabpanel-id-1',
                    id: 'tab-id-1',
                    label: 'ALM Projection',
                    role: 'tab',
                    type: 'button',
                    onClick: handleTabItemClick,
                  },
                  {
                    'aria-controls': 'tabpanel-id-2',
                    'aria-selected': activeTab === 'tabpanel-id-2',
                    id: 'tab-id-2',
                    label: 'VaR',
                    role: 'tab',
                    type: 'button',
                    onClick: handleTabItemClick,
                  },
                  {
                    'aria-controls': 'tabpanel-id-3',
                    'aria-selected': activeTab === 'tabpanel-id-3',
                    id: 'tab-id-3',
                    label: 'Curve Exposure',
                    role: 'tab',
                    type: 'button',
                    onClick: handleTabItemClick,
                  },
                  {
                    'aria-controls': 'tabpanel-id-4',
                    'aria-selected': activeTab === 'tabpanel-id-4',
                    id: 'tab-id-4',
                    label: 'Cashflow',
                    role: 'tab',
                    type: 'button',
                    onClick: handleTabItemClick,
                  },
                  {
                    'aria-controls': 'tabpanel-id-5',
                    'aria-selected': activeTab === 'tabpanel-id-5',
                    id: 'tab-id-5',
                    label: 'Market Events',
                    role: 'tab',
                    type: 'button',
                    onClick: handleTabItemClick,
                  },
                ]}
                variant="default"
              />
            </Box>
            {activeTab === 'tabpanel-id-0' && (
              <Box id="tabpanel-id-0" role="tabpanel">
                <OverviewGrid>
                  <Box
                    borderBottom={`1px solid ${themeGet('colors.border')(props)}`}
                    borderRight={`1px solid ${themeGet('colors.border')(props)}`}
                    pb={rem(5)}
                    pl={rem(0)}
                    pr={[rem(2), rem(8)]}
                    pt={rem(25)}
                  >
                    <ChartTitle>ALM Projection</ChartTitle>
                    <ALMProjection portfolio={selectedPortfolio} />
                  </Box>
                  <Box
                    borderBottom={`1px solid ${themeGet('colors.border')(props)}`}
                    pl={rem(0)}
                    pr={[rem(2), rem(8)]}
                    py={4}
                  >
                    <Box pb={rem(44)}>
                      <ChartTitle>
                        <abbr title="Value at Risk">VaR</abbr> Attribution
                      </ChartTitle>
                    </Box>
                    <ValueAtRisk portfolio={selectedPortfolio} type={ValueAtRiskTypes.Attribution} />
                  </Box>
                  <Box
                    borderRight={`1px solid ${themeGet('colors.border')(props)}`}
                    pb={rem(5)}
                    pl={rem(0)}
                    pr={[rem(2), rem(8)]}
                    pt={rem(25)}
                  >
                    <ChartTitle>Curve Exposure</ChartTitle>
                    <Curve portfolio={selectedPortfolio} />
                  </Box>
                  <ChartContainer>
                    <ChartTitle>Annual Cashflow, GBP m</ChartTitle>
                    <Cashflow portfolio={selectedPortfolio} showDetailedAssets={false} />
                  </ChartContainer>
                </OverviewGrid>
                <Box borderTop={`1px solid ${themeGet('colors.border')(props)}`} pt={rem(25)}>
                  <ChartTitle>Market events</ChartTitle>
                  <MarketEvents
                    marketEvent={marketEvent}
                    setMarketEvent={setMarketEvent}
                    strategyId={selectedPortfolio}
                  />
                </Box>
              </Box>
            )}
            {activeTab === 'tabpanel-id-1' && (
              <Box id="tabpanel-id-1" role="tabpanel">
                <ChartContainer>
                  <ChartTitle>ALM Projection</ChartTitle>
                  <ALMProjection portfolio={selectedPortfolio} />
                </ChartContainer>
              </Box>
            )}
            {activeTab === 'tabpanel-id-2' && (
              <Box id="tabpanel-id-2" role="tabpanel">
                <ChartContainer>
                  <ChartTitle>
                    <abbr title="Value at Risk">VaR</abbr> Attribution
                  </ChartTitle>
                  <ValueAtRisk portfolio={selectedPortfolio} type={ValueAtRiskTypes.Attribution} />
                  <ChartTitle>
                    <abbr title="Value at Risk">VaR</abbr> Breakdown
                  </ChartTitle>
                  <ValueAtRisk portfolio={selectedPortfolio} type={ValueAtRiskTypes.Breakdown} />
                </ChartContainer>
              </Box>
            )}
            {activeTab === 'tabpanel-id-3' && (
              <Box id="tabpanel-id-3" role="tabpanel">
                <ChartContainer>
                  <ChartTitle>Curve Exposure</ChartTitle>
                  <Curve portfolio={selectedPortfolio} />
                </ChartContainer>
              </Box>
            )}
            {activeTab === 'tabpanel-id-4' && (
              <Box id="tabpanel-id-4" role="tabpanel">
                <ChartContainer>
                  <ChartTitle>Annual Cashflow</ChartTitle>
                  <Cashflow portfolio={selectedPortfolio} showDetailedAssets={true} />
                </ChartContainer>
              </Box>
            )}
            {activeTab === 'tabpanel-id-5' && (
              <Box id="tabpanel-id-5" pt={rem(25)} role="tabpanel">
                <ChartTitle>Market events</ChartTitle>
                <MarketEvents
                  marketEvent={marketEvent}
                  setMarketEvent={setMarketEvent}
                  strategyId={selectedPortfolio}
                />
              </Box>
            )}
          </Box>
        )}
        {selectedPortfolio === undefined && axesSet && (
          <EmptyPortfolio>Select a portfolio above to view here</EmptyPortfolio>
        )}
      </Box>
    );
  }),
);
