import styled from '@emotion/styled';
import { Box, Flex } from '@risk-first/ui-core';
import { RangeInput } from '@risk-first/ui-range-input';
import { themeGet } from '@styled-system/theme-get';
import { withTheme } from 'emotion-theming';
import { rem } from 'polished';
import React, { FormEvent, useCallback } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '../../app/RootState';
import { AxisState } from '../../features/assessmentCharts/AxisState';
import { Label, Select } from '../../styles';
import { getDisplayableMetricName } from '../../utils';

interface AxisProps {
  type: 'x' | 'y';
  values: AxisState;
  onFieldChange: (name: string, value: string) => void;
}

const StyledRangeInput = styled(RangeInput)`
  /* bumps the specificity to override the default */
  && {
    padding-top: 16px;
    padding-bottom: 16px;
  }
  padding-right: 10px;
`;

// We need to override the fieldset default of: min-width: min-content
// Otherwise the default size of the slider will push the contents out of the containing tab
const ContainedFieldSet = styled.fieldset`
  min-width: 0;
`;

export const Axis: React.FC<AxisProps> = withTheme(({ type, values, onFieldChange, ...props }: AxisProps) => {
  const years = 11; // remember years is zero-indexed so 11 = 10
  const metrics = useSelector((state: RootState) => state.metrics);
  const statistics = useSelector((state: RootState) => state.statistics);

  // Build year <option>s
  const yearOptions = [];
  for (let i = 0; i < years; i++) {
    const yearPlural: string = i === 1 ? 'year' : 'years';
    yearOptions.push(
      <option key={i} value={i}>
        {i} {yearPlural}
      </option>,
    );
  }

  const handleChange = useCallback(
    (event: FormEvent<HTMLInputElement | HTMLSelectElement>) => {
      const axisFieldName = event.currentTarget.name.replace(/^[xy]_/, '');
      onFieldChange(axisFieldName, event.currentTarget.value);
    },
    [onFieldChange],
  );

  const focusLabelledRangeInput = useCallback((event: React.MouseEvent<HTMLLabelElement>) => {
    const labelId = event.currentTarget.id;
    const target = document.querySelector(`[aria-labelledby="${labelId}"]`) as HTMLDivElement | null;
    if (target) {
      target.focus();
    }
  }, []);

  const handleTimestepChange = useCallback((value: number) => onFieldChange('timestep', value.toString()), [
    onFieldChange,
  ]);
  const getTimestepAriaValueText = useCallback((value: number) => value.toString(), []);

  return (
    <Box
      borderBottom={`1px solid ${themeGet('colors.border')(props)}`}
      mb={3}
      pb={3}
      pt={rem(4)}
      px={[rem(10), rem(20)]}
    >
      <ContainedFieldSet>
        <Box mb={3}>
          <legend
            style={{ fontSize: themeGet('fontSizes.1')(props), fontWeight: themeGet('fontWeight.medium')(props) }}
          >
            {type.toUpperCase()} axis
          </legend>
        </Box>
        <Flex alignItems="center" flexWrap="nowrap" justifyContent="space-between">
          <Box maxWidth="50%" mb={3} mr={2}>
            <Label htmlFor={`${type}-metric`}>Select metric</Label>
            <Select
              id={`${type}-metric`}
              name={`${type}_metric`}
              required={true}
              value={values.metric}
              variant="default"
              onChange={handleChange}
            >
              <option value="">Select metric</option>
              {metrics?.map((value) => (
                <option key={value} value={value}>
                  {getDisplayableMetricName(value.toString())}
                </option>
              ))}
            </Select>
          </Box>
          <Box maxWidth="50%" mb={3}>
            <Label htmlFor={`${type}-statistic`}>Select statistic</Label>
            <Select
              id={`${type}-statistic`}
              name={`${type}_statistic`}
              required={true}
              value={values.statistic}
              variant="default"
              onChange={handleChange}
            >
              <option value="">Select statistic</option>
              {statistics?.map((value) => (
                <option key={value} value={value}>
                  {value.toString().replace(/_/g, ' ')}
                </option>
              ))}
            </Select>
          </Box>
        </Flex>
        <Flex alignItems="flex-start" flexWrap="nowrap" justifyContent="space-between">
          <Box mb={3} minWidth="0">
            <Label htmlFor={`${type}-timestep-range`} id={`${type}-timestep-label`} onClick={focusLabelledRangeInput}>
              Select term
            </Label>
            <StyledRangeInput
              aria-labelledby={`${type}-timestep-label`}
              getAriaValueText={getTimestepAriaValueText}
              max={years - 1}
              min={0}
              name={`${type}_timestep`}
              step={1}
              value={Number(values.timestep)}
              variant="default"
              onChange={handleTimestepChange}
            />
          </Box>
          <Box mb={3} minWidth="fit-content">
            <Label htmlFor={`${type}-timestep-select`}>Select term</Label>
            <Select
              id={`${type}-timestep-select`}
              name={`${type}_timestep`}
              required={true}
              value={values.timestep}
              variant="default"
              onChange={handleChange}
            >
              {yearOptions}
            </Select>
          </Box>
        </Flex>
      </ContainedFieldSet>
    </Box>
  );
});
