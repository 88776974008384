/**
 * A map of keyboard `keyCode` values
 * We'll use these to check against a keydown event's event.keyCode and perform specific actions e.g.
 */

export const KEYS = {
  DOWN: 40,
  END: 35,
  ENTER: 13,
  ESC: 27,
  HOME: 36,
  LEFT: 37,
  RIGHT: 39,
  SHIFT: 16,
  SPACE: 32,
  TAB: 9,
  UP: 38,
};
