import styled from '@emotion/styled';
import { themeGet } from '@styled-system/theme-get';
import { rem } from 'polished';

export const Legend = styled.legend`
  font-size: ${themeGet('fontSizes.1')};
  font-weight: ${themeGet('fontWeight.medium')};
  position: relative;
  top: 0.175em;
`;

export const FieldsGrid = styled.div`
  align-items: center;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: ${rem(20)} ${rem(16)};
  justify-content: center;
  width: 100%;

  /* 60em = 960px - a good place to stack form fields */
  ${themeGet('mediaQueries.medium')} {
    grid-template-columns: 1fr;
  }
`;
