import { Box } from '@risk-first/ui-core';
import { DualAxesChart } from '@risk-first/ui-dual-axes-chart';
import { themeGet } from '@styled-system/theme-get';
import merge from 'deepmerge';
import { withTheme } from 'emotion-theming';
import Highcharts, { Options, Tooltip, TooltipFormatterContextObject, XAxisOptions, YAxisOptions } from 'highcharts';
import HighchartsMore from 'highcharts/highcharts-more';
import { rem } from 'polished';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useMountedState } from 'react-use';
import { getCashflow } from '../../api/cashflow';
import { Loading } from '../../components';
import { getDefaultChartOptions } from '../../lib/charts/defaultChartOptions';
import { ResponsiveChartContainer } from '../../styles';
import { getFixedLocaleNumberFormatter } from '../../utils';
import { CashflowJSON, CashflowJSONData, CashflowProps, CashflowSeriesProps } from './types';

HighchartsMore(Highcharts);

const numberFormatter = getFixedLocaleNumberFormatter();

const getComponentDefaultChartOptions = (props: { theme: any }) =>
  ({
    chart: {
      spacingBottom: 120, // leave space for legend at the bottom of the chart
      spacingLeft: 8,
    },
    legend: {
      align: 'left',
      backgroundColor: 'transparent', // unclear why the background defaults to rgba(255,255,255,0.75)
      enabled: true,
      itemStyle: {
        color: themeGet('colors.chartTextColor')(props),
        fontSize: rem(10),
        fontWeight: themeGet('fontWeight.regular')(props),
      },
      layout: 'horizontal',
      symbolHeight: 10,
      symbolRadius: 5,
      symbolWidth: 10,
      verticalAlign: 'middle',
      x: 0,
      y: 220, // Force legend to the bottom of the chart
    },
    plotOptions: {
      column: {
        stacking: 'normal',
      },
      spline: {
        marker: {
          enabled: false,
        },
      },
    },
  } as Options);

export const Cashflow: React.FC<CashflowProps> = withTheme((props) => {
  const { height, portfolio, showDetailedAssets } = props as CashflowProps;

  const defaultOptions = useMemo(() => {
    const defaultChartOptions = getDefaultChartOptions({ theme: props.theme });
    const componentDefaultChartOptions = getComponentDefaultChartOptions({ theme: props.theme });

    // Because xAxis and yAxis are arrays in this chart, the merge doesn't work quite as we like
    // so we populate the right indexes with values from the default options manually
    // NOTE: this could probably be improved
    componentDefaultChartOptions.xAxis = [
      {
        crosshair: true,
        labels: { ...defaultChartOptions.xAxis.labels },
      },
    ];
    componentDefaultChartOptions.yAxis = [
      {
        labels: { ...defaultChartOptions.yAxis.labels },
        title: {
          ...defaultChartOptions.yAxis.title,
          text: '£m',
        },
        opposite: false,
      },
      {
        title: {
          reserveSpace: false,
          text: null,
          y: 2,
        },
        labels: {
          enabled: false,
        },
      },
    ];

    return merge(defaultChartOptions, componentDefaultChartOptions) as Options;
  }, [props.theme]);
  const [loading, setLoading] = useState<boolean>(true);
  const [rawData, setRawData] = useState<CashflowJSON[] | undefined>(undefined);
  const isMounted = useMountedState();

  // Fetch the correct JSON file based on selectedPortfolio
  useEffect(() => {
    async function fetchData(selectedPortfolio: string) {
      const importedData = await getCashflow(selectedPortfolio);

      if (isMounted() && importedData) {
        setRawData(importedData);
        setLoading(false);
      }
    }

    if (portfolio !== undefined) {
      setLoading(true);
      fetchData(portfolio);
    }
  }, [isMounted, portfolio]);

  // Add classes to tooltip for styling and format the values
  const formatTooltip = useCallback(function (this: TooltipFormatterContextObject, toolip?: Tooltip) {
    const { points } = this;
    const firstPoint: TooltipFormatterContextObject | undefined = points && points[0];
    let label = `<div class="tooltip-title">${firstPoint?.key}</div>`;

    if (points) {
      points.forEach((point) => {
        const value: string = point.y && point.y > 0 ? numberFormatter.format(point.y) : '0';
        label += `<div class="tooltip-point">
            <span class="tooltip-marker" style="background-color: ${point.color};"></span>
              <span class="tooltip-name">${point.series.name}${' '}
              <span class="tooltip-value">${value.replace(/,/g, ' ')}</span>
            </span>
          </div>`;
      });
    }

    return label;
  }, []);

  // Cashflow Options - dictates how our chart appears
  const [cashflowOptions, setCashflowOptions] = useState<Options | undefined>(undefined);

  // Build up the rest of the chart data (depending upon what the user has chosen to see)
  useEffect(() => {
    const series: CashflowSeriesProps[] = [];

    if (!rawData) {
      return;
    }

    // 'Liabilities' data is always the first item in the array and 'Aggregate' is always the last
    const liabilities: CashflowJSON | undefined = rawData.shift();
    const aggregate: CashflowJSON | undefined = rawData.pop();

    // Detailed or Simplified view - show all the assets or just limit to one
    const data: (CashflowJSON | undefined)[] = showDetailedAssets === true ? rawData : [aggregate];

    if (data) {
      for (let i = 0; i <= data.length; i++) {
        const item = data[i];
        if (item) {
          // Only show series with values not zero. Note: we may want negative numbers
          const newData = item.data.map((record: CashflowJSONData) => Math.trunc(record.data));
          const hasNonZeroData = (data: number) => data !== 0;

          if (newData.some(hasNonZeroData)) {
            series.push({
              data: newData,
              name: item.name,
              type: 'column',
              yAxis: 1,
            });
          }
        }
      }

      if (liabilities) {
        series.push({
          data: liabilities.data.map((record: CashflowJSONData) => record.data),
          name: liabilities.name,
          type: 'spline',
          yAxis: 1,
        });

        // Grab all the years from our data for our xAxis
        const xAxisCategories = liabilities.data.map((record) => record.name);

        const newCashflowOptions: Options = {
          ...defaultOptions,
          series: series as Highcharts.SeriesColumnOptions[],
          tooltip: {
            formatter: formatTooltip,
            useHTML: true,
          },
          xAxis: [
            {
              ...(defaultOptions.xAxis as XAxisOptions[])[0],
              categories: xAxisCategories,
              labels: {
                ...(defaultOptions.xAxis as XAxisOptions[])[0].labels,
              },
            },
          ],
          yAxis: [
            {
              ...(defaultOptions.yAxis as YAxisOptions[])[0],
              labels: {
                ...(defaultOptions.yAxis as YAxisOptions[])[0].labels,
                formatter: function (this: { value: number }) {
                  return numberFormatter.format(this.value / 1000000);
                },
              },
            },
            ...(defaultOptions.yAxis as YAxisOptions[]).slice(1),
          ],
        };

        setCashflowOptions(newCashflowOptions);
      }
    }
  }, [defaultOptions, formatTooltip, rawData, showDetailedAssets]);

  return (
    <Box mb={2} mt={3}>
      <ResponsiveChartContainer height={height || rem(500)}>
        {loading && <Loading />}
        {!loading && cashflowOptions && cashflowOptions.series && <DualAxesChart options={cashflowOptions} />}
      </ResponsiveChartContainer>
    </Box>
  );
});
