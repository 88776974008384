import { useAuth0 } from '@auth0/auth0-react';
import { Login as LoginComponent } from '@risk-first/ui-login';
import React, { useCallback, useEffect } from 'react';
import { ReactComponent as InsuranceLogoReactComponent } from '../assets/logos/PFaroeInsurance.svg';
import { LOGIN } from '../lib/constants/pageTitles';

export const Login = (props: any) => {
  const logo = React.useMemo(() => <InsuranceLogoReactComponent />, []);

  useEffect(() => {
    document.title = LOGIN;
  }, []);

  const { loginWithRedirect } = useAuth0();

  const handleClick = useCallback(() => {
    loginWithRedirect();
  }, [loginWithRedirect]);

  const { isAuthenticated } = useAuth0();

  useEffect(() => {
    if (isAuthenticated) {
      props.history.replace('/');
    }
  }, [isAuthenticated, props.history]);

  return (
    <>
      {!isAuthenticated && (
        <LoginComponent applicationLogo={logo} applicationName="Insurance" onLoginClick={handleClick} />
      )}
    </>
  );
};
