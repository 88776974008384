import styled from '@emotion/styled';
import { Checkbox } from '@risk-first/ui-checkbox';
import { Box, Button, Flex, H2, TextCtaButton } from '@risk-first/ui-core';
import { NativeColumn, NativeTable, NativeTableRowClickedEvent } from '@risk-first/ui-native-table';
import { TextFiltersMap } from '@risk-first/ui-text-filter';
import { themeGet } from '@styled-system/theme-get';
import { withTheme } from 'emotion-theming';
import { rem } from 'polished';
import React, { useCallback, useState, useMemo } from 'react';
import { useHistory, useParams } from 'react-router';
import { Portfolio } from '../../features/portfolios/PortfolioState';
import { colors } from '../../lib/constants/colors';
import { extractStrategyIdForFetching, isGeneratedStrategy, transformSearchParams } from '../../utils';
import { ALMProjection } from '../ALMProjection';
import { Cashflow } from '../Cashflow';
import { ClosableSectionProps } from '../ClosableSection/ClosableSection';
import { Curve } from '../Curve';
import { MarketEvents } from '../MarketEvents';
import { TableFilterMenu } from '../TableFilterMenu';
import { ValueAtRisk } from '../ValueAtRisk';
import { ValueAtRiskTypes } from '../ValueAtRisk/types';
import { AssetAllocationTable } from './AssetAllocationTable';
import { StrategyDetailMenu } from './StrategyDetailMenu';
import {
  ClosableSectionIcon,
  StrategiesDetailClosableSection,
  SelectedDetailStrategyMarker,
  StrategiesTableWrapper,
} from './styles';

const StatefulClosableSection = withTheme((props: Omit<ClosableSectionProps, 'isOpen' | 'setOpen'>) => {
  const [isOpen, setOpen] = useState(false);

  return (
    <StrategiesDetailClosableSection
      {...props}
      bg={themeGet('colors.white')(props)}
      icon={<ClosableSectionIcon />}
      iconAlignment="right"
      isOpen={isOpen}
      setOpen={setOpen}
    >
      <Box bg={themeGet('colors.ghostWhite')(props)} p={rem(10)}>
        {props.children}
      </Box>
    </StrategiesDetailClosableSection>
  );
});

// This is adds an accent and a shadow around the strategy with currently displayed details
const SelectedDetailStrategyMarkerComponent = (props: any) =>
  props.data.rowMetadata.isSelectedForDetail ? <SelectedDetailStrategyMarker /> : null;

const Colour = styled(Box)`
  border-radius: 50%;
  height: ${rem(10)};
  width: ${rem(10)};
`;

const StrategyNameComponent = (props: any) => (
  <>
    <Colour bg={colors[parseInt(props.data.strategyID)] || 'grey'} mr={3} />
    {props.data.name}
  </>
);

interface StrategiesDetailTableProps {
  onDeleteStrategy: (strategyId: string) => void;
  portfolios: Portfolio[];
  selectedPortfolioIds: string[];
  setSelectedPortfolioIds: (portfolios: string[]) => void;
  sourceFilters: TextFiltersMap;
  setSourceFilters: (filters: TextFiltersMap) => void;
}

export const StrategiesDetailTable = withTheme((props: StrategiesDetailTableProps) => {
  const {
    onDeleteStrategy,
    portfolios,
    selectedPortfolioIds,
    setSelectedPortfolioIds,
    sourceFilters,
    setSourceFilters,
  } = props;
  const history = useHistory();
  const { id } = useParams<{ id: string }>();

  const [detailPortfolioId, setDetailPortfolioId] = useState<string | undefined>(undefined);
  const detailPortfolioIdForApi = useMemo(
    () => (detailPortfolioId ? extractStrategyIdForFetching(detailPortfolioId) : undefined),
    [detailPortfolioId],
  );

  const [marketEvent, setMarketEvent] = useState<string | undefined>();

  const detailPortfolio = useMemo(() => portfolios.find((p) => p.strategyID === detailPortfolioId), [
    portfolios,
    detailPortfolioId,
  ]);

  const allSelected = useMemo(() => portfolios.every((p) => selectedPortfolioIds.includes(p.strategyID)), [
    portfolios,
    selectedPortfolioIds,
  ]);
  const selectAll = useCallback(() => setSelectedPortfolioIds(allSelected ? [] : portfolios.map((p) => p.strategyID)), [
    allSelected,
    portfolios,
    setSelectedPortfolioIds,
  ]);

  const CheckBoxHeaderComponent = useCallback(
    () => (
      <Checkbox
        aria-label="Select all portfolios"
        checked={allSelected}
        id="portfolios-select-all-checkbox"
        onChange={selectAll}
      />
    ),
    [allSelected, selectAll],
  );

  const visitCreateFromExisting = useCallback(
    (existingId: string) => {
      history.push({
        pathname: `/strategies/${id}/create/from-existing`,
        search: transformSearchParams(history.location.search, { existingId }),
      });
    },
    [history, id],
  );

  const visitCreateFromExistingWithCurrent = useCallback(
    () => detailPortfolio && visitCreateFromExisting(detailPortfolio.strategyID),
    [detailPortfolio, visitCreateFromExisting],
  );

  const handleDeleteStrategy = useCallback(
    (strategyId: string) => {
      if (isGeneratedStrategy(strategyId)) {
        onDeleteStrategy(strategyId);
      }
    },
    [onDeleteStrategy],
  );

  const handleDeleteDetailStrategy = useCallback(() => detailPortfolioId && handleDeleteStrategy(detailPortfolioId), [
    detailPortfolioId,
    handleDeleteStrategy,
  ]);

  const nativeColumns: NativeColumn[] = useMemo(
    () => [
      {
        field: '',
        checkboxSelection: true,
        headerComponentFramework: CheckBoxHeaderComponent,
        cellRendererFramework: SelectedDetailStrategyMarkerComponent,
      },
      {
        field: 'name',
        headerName: 'Strategy Name',
        cellRendererFramework: StrategyNameComponent,
      },
      {
        field: 'source',
        headerName: 'Source',
        headerComponentFramework: TableFilterMenu,
        headerComponentParams: {
          title: 'Source',
          filters: sourceFilters,
          onChange: setSourceFilters,
        },
      },
      {
        field: 'transition',
        headerName: 'Transition',
      },
      {
        field: 'color',
        cellRendererFramework: StrategyDetailMenu,
        cellRendererParams: {
          detailPortfolioId,
          onCreateFromExisting: visitCreateFromExisting,
          onDeleteStrategy: handleDeleteStrategy,
          onSelectDetailPortfolio: setDetailPortfolioId,
        },
      },
    ],
    [
      detailPortfolioId,
      handleDeleteStrategy,
      setSourceFilters,
      sourceFilters,
      CheckBoxHeaderComponent,
      visitCreateFromExisting,
    ],
  );

  const nativeTableData = useMemo(
    () =>
      (portfolios || []).map((data) => ({
        ...data,
        id: data.strategyID, // NativeTable Checkbox fails if this is a number
        rowMetadata: {
          checkboxKey: data.strategyID,
          isSelected: selectedPortfolioIds.includes(data.strategyID),
          isSelectedForDetail: data.strategyID === `${detailPortfolioId}`,
        },
      })),
    [detailPortfolioId, portfolios, selectedPortfolioIds],
  );

  const handleNativeRowClick = useCallback(
    (event: NativeTableRowClickedEvent) => {
      const id = event.id;
      if (id) {
        if (selectedPortfolioIds.includes(id)) {
          setSelectedPortfolioIds(selectedPortfolioIds.filter((item) => item !== id));
        } else {
          setSelectedPortfolioIds(selectedPortfolioIds.concat(id));
        }
      }
      event.originalEvent.event.preventDefault(); // Essential, or the checkboxes don't work (the row gets selected then immediately deselected)
    },
    [selectedPortfolioIds, setSelectedPortfolioIds],
  );

  return (
    <Flex
      backgroundColor={themeGet('colors.white')(props)}
      border={`1px solid ${themeGet('colors.border')(props)}`}
      mb="-1px"
      overflow="hidden"
    >
      <StrategiesTableWrapper aria-expanded={detailPortfolio !== undefined} flex={1}>
        <NativeTable
          columns={nativeColumns}
          getRowNodeId={(data) => data.strategyID}
          rowData={nativeTableData}
          onRowClicked={handleNativeRowClick}
        />
      </StrategiesTableWrapper>

      {detailPortfolio && detailPortfolioIdForApi && (
        <Box
          bg={themeGet('colors.ghostWhite')(props)}
          borderLeft={`1px solid ${themeGet('colors.border')(props)}`}
          flex={2}
          mb="-1px"
          ml="-1px"
        >
          {/* Clear space to match the table heading to the left */}
          <Box
            backgroundColor={themeGet('colors.white')(props)}
            borderBottom={`1px solid ${themeGet('colors.border')(props)}`}
            height="50px"
          ></Box>

          <Box bg={themeGet('colors.white')(props)} borderBottom={`1px solid ${themeGet('colors.border')(props)}`}>
            <H2 fontWeight={themeGet('fontWeight.medium')(props)} px="1.25rem" py="1rem">
              {detailPortfolio.name} Detail
            </H2>
          </Box>

          <StatefulClosableSection title="Asset Allocation">
            <AssetAllocationTable portfolio={detailPortfolio} />
          </StatefulClosableSection>

          <StatefulClosableSection title="VaR">
            <ValueAtRisk height={rem(280)} portfolio={detailPortfolioIdForApi} type={ValueAtRiskTypes.Attribution} />
          </StatefulClosableSection>

          <StatefulClosableSection title="Curve">
            <Curve height={rem(280)} portfolio={detailPortfolioIdForApi} />
          </StatefulClosableSection>

          <StatefulClosableSection title="Cashflow">
            <Cashflow height={rem(350)} portfolio={detailPortfolioIdForApi} showDetailedAssets={false} />
          </StatefulClosableSection>

          <StatefulClosableSection title="ALM Projection Chart">
            <ALMProjection height={rem(313)} portfolio={detailPortfolioIdForApi} />
          </StatefulClosableSection>

          <StatefulClosableSection title="Market Events">
            <MarketEvents
              marketEvent={marketEvent}
              setMarketEvent={setMarketEvent}
              strategyId={detailPortfolioIdForApi}
            />
          </StatefulClosableSection>
          <Flex bg={themeGet('colors.white')(props)} justifyContent="space-between" px={rem(20)} py={rem(10)}>
            <Button size="small" variant="osirisSecondary" onClick={visitCreateFromExistingWithCurrent}>
              Create strategy from existing
            </Button>
            <TextCtaButton variant="link" onClick={handleDeleteDetailStrategy}>
              Delete strategy
            </TextCtaButton>
          </Flex>
        </Box>
      )}
    </Flex>
  );
});
