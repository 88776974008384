import { Box, Flex, VisuallyHiddenLabel } from '@risk-first/ui-core';
import { PercentageAreaChart } from '@risk-first/ui-percentage-area-chart';
import { themeGet } from '@styled-system/theme-get';
import merge from 'deepmerge';
import { withTheme } from 'emotion-theming';
import {
  Options,
  SeriesColumnOptions,
  Tooltip,
  TooltipFormatterContextObject,
  XAxisOptions,
  YAxisTitleOptions,
} from 'highcharts';
import { rem } from 'polished';
import React, { FormEvent, ReactText, useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getStrategy } from '../../api/strategy';
import { RootState } from '../../app/RootState';
import { Loading } from '../../components';
import { updateALMMetric } from '../../features/alm/almSlice';
import { DefaultOptions, getDefaultChartOptions } from '../../lib/charts/defaultChartOptions';
import { ResponsiveChartContainer, Select } from '../../styles';
import {
  getFixedLocaleNumberFormatter,
  getDisplayableMetricName,
  getUniqueStrategyValues,
  isCurrencyMetric,
  isPercentageMetric,
  isYearMetric,
} from '../../utils';
import { SingleStrategy } from '../EfficientFrontier/types';

const numberFormatter = getFixedLocaleNumberFormatter();

interface ExtendedXAxisOptions extends XAxisOptions {
  categories: string[];
}

interface ExtendedDefaultOptions extends DefaultOptions {
  xAxis: ExtendedXAxisOptions;
}

const getComponentDefaultChartOptions = (props: { theme: any }): Options => ({
  chart: {
    spacingLeft: 11,
  },
  legend: {
    align: 'left',
    itemMarginBottom: 6,
    itemStyle: {
      color: themeGet('colors.chartTextColor')(props),
      fontSize: rem(10),
      fontWeight: themeGet('fontWeight.regular')(props),
      marginRight: rem(5),
      opacity: 0.8,
    },
    reversed: true,
    symbolHeight: 10,
    symbolRadius: 5,
    symbolWidth: 10,
    verticalAlign: 'bottom',
    x: 16,
  },
  plotOptions: {
    area: {
      marker: {
        enabled: false,
      },
      stacking: 'normal',
    },
    series: {
      borderWidth: 0,
      shadow: false,
      animation: false,
      marker: {
        enabled: false,
      },
      pointPlacement: 'on',
      zIndex: 10,
    },
  },
  series: [],
  tooltip: {
    shared: true,
    useHTML: true,
    backgroundColor: 'rgba(255, 255, 255, 1)',
    borderWidth: 1,
    borderRadius: 3,
  },
  xAxis: {
    categories: [''],
    labels: {
      useHTML: true,
      y: 25,
      zIndex: 0,
    },
    tickInterval: 1,
    tickmarkPlacement: 'on',
    type: 'linear',
  },
  yAxis: {
    labels: {
      x: 0,
      align: 'left',
    },
    offset: 25,
    tickPixelInterval: 30,
    title: {
      x: -3,
    },
  },
});

interface ALMProjectionProps {
  height?: string;
  portfolio?: string;
}

export const ALMProjection: React.FC<ALMProjectionProps> = withTheme((props) => {
  const { height, portfolio } = props as ALMProjectionProps;

  const defaultOptions = useMemo(
    () =>
      merge(
        getDefaultChartOptions({ theme: props.theme }),
        getComponentDefaultChartOptions({ theme: props.theme }),
      ) as ExtendedDefaultOptions,
    [props.theme],
  );
  const dispatch = useDispatch();
  const [loading, setLoading] = useState<boolean>(true);
  const metric = useSelector((state: RootState) => state.alm.metric);
  const [formMetric, setFormMetric] = useState<string>(useSelector((state: RootState) => state.alm.metric));
  const [metrics, setMetrics] = useState<ReactText[] | undefined>(undefined);
  const [rawData, setRawData] = useState<SingleStrategy[] | undefined>(undefined);
  const [projections, setProjections] = useState<SingleStrategy[] | undefined>(undefined);
  // Note: the first legend item needs to be transparent to make the chart look nice
  const colors: string[] = useMemo(() => {
    return [
      'transparent',
      '#4394C3',
      '#5FA9CF',
      '#84BEDB',
      '#ABD4E7',
      '#D5E9F3',
      '#5124B1',
      '#724DBF',
      '#9679CE',
      '#B8A6DF',
      '#DCD2EF',
      '#416ABC',
      '#6285C7',
      '#86A0D2',
      '#A9BADE',
      '#CDD5E9',
    ];
  }, []);

  // Fetch the correct JSON file based on selectedPortfolio
  useEffect(() => {
    if (portfolio !== undefined) {
      const abortController = new AbortController();

      const fetchData = async (portfolio: string) => {
        const importedData = await getStrategy(portfolio, abortController);

        if (importedData) {
          setRawData(importedData);
          setLoading(false);
        }
      };

      fetchData(portfolio);

      return () => {
        abortController.abort();
      };
    }
  }, [portfolio]);

  // Grab all 'percentile' data by metric e.g. ignore anything like 'Mean' or 'StdDev'
  useEffect(() => {
    if (formMetric !== '' && rawData) {
      const newProjections = rawData.filter((item) => item.metric === formMetric);
      const newProjectionsPercentiles = newProjections.filter((item) => item.statistic.includes('Percentile'));
      setProjections(newProjectionsPercentiles);
    }
  }, [formMetric, rawData]);

  useEffect(() => {
    if (formMetric !== metric) {
      dispatch(updateALMMetric(formMetric));
    }
  }, [dispatch, formMetric, metric]);

  // Break down metric data by percentiles to build Highchart series
  const [chartOptions, setChartOptions] = useState<Options>(defaultOptions);
  useEffect(() => {
    if (!formMetric || !projections) {
      return;
    }

    const metricLegibleName = getDisplayableMetricName(formMetric);
    const series = [];
    const percentiles: ReactText[] = getUniqueStrategyValues('statistic', projections);
    const allValues: number[][] = [];

    for (let i = 0; i < percentiles.length; i++) {
      const projectionsByPercentile = projections.filter((item) => item.statistic === percentiles[i].toString());
      const values = projectionsByPercentile.map(
        (item) => parseFloat(item.value) * (isPercentageMetric(formMetric) ? 100 : 1),
      );

      // To achieve a nice funnel chart we adjust the values by subtracting the previous result
      allValues[i] = values;
      let adjustedValues = values;
      if (i > 0) {
        const previousIndex = i - 1;
        adjustedValues = values.map((value: number, index: number) => value - allValues[previousIndex][index]);
      }

      series.push({
        name: percentiles[i].toString(),
        data: adjustedValues,
        color: colors[i],
      });
    }
    series.reverse();

    // Format the Y Axis title's units
    // We want to display percentages, currency in the millions, years, and currency in the 1000s
    let title: YAxisTitleOptions = {
      text: `${metricLegibleName} £m`,
    };
    if (isPercentageMetric(formMetric)) {
      title.text = `${metricLegibleName} %`;
    } else if (isYearMetric(formMetric)) {
      title.text = `${metricLegibleName} years`;
    }

    // Display the tooltip - uses the underlying rather than difference data for the displayed values
    const formatTooltip = function (this: TooltipFormatterContextObject, tooltip: Tooltip) {
      const { x, points } = this;
      const xIndex = Number(x); // we're given a string in x, not a number

      let label = `<b style="font-weight: bold;">${x} year${`${x}` === '1' ? '' : 's'}</b><br/><br/>`;
      label += points
        ?.map(({ point, series }, seriesIndex) => {
          const originalValue = allValues[allValues.length - seriesIndex - 1][xIndex];
          // We round by formatting to fixed decimal places above the error level and stripping the extra zeros
          // It's not 100% numerically robust but should work for typical percentages (which are fractional)
          let value = originalValue.toFixed(12).replace(/0+$/, '').replace(/\.$/, '.0');
          if (isPercentageMetric(formMetric)) value += '%';
          return `<span style="color:${point.color}">●</span> ${series.name}: <b>${value}</b>`;
        })
        .join('<br/>');

      return label;
    };

    const categoriesAssoc: ReactText[] = getUniqueStrategyValues('timestep', projections);
    const categories: string[] = categoriesAssoc.map((category: ReactText) => category.toString());
    const newChartOptions = {
      series: series as SeriesColumnOptions[],
      tooltip: {
        formatter: formatTooltip,
      },
      xAxis: {
        categories: categories,
      },
      yAxis: {
        labels: {
          // Format the y Axis labels so the numbers aren't too crazy
          // We want to display percentages, currency in the millions, years
          formatter: function (this: { value: number }): string {
            const { value } = this;
            return numberFormatter.format(isCurrencyMetric(formMetric) ? value / 1000000 : value);
          },
        },
        title,
      },
    };

    setChartOptions(
      merge(defaultOptions, newChartOptions, {
        arrayMerge: (destinationArray, sourceArray) => sourceArray,
      }),
    );
  }, [colors, defaultOptions, projections, formMetric]);

  // Grab metrics for our dropdown
  useEffect(() => {
    if (rawData) {
      setMetrics(getUniqueStrategyValues('metric', rawData));
    }
  }, [rawData]);

  // Reset the form/chart
  const handleClearClick = useCallback(() => {
    setFormMetric('');
    setChartOptions(defaultOptions);
  }, [defaultOptions]);

  // Prevent form submissions. We use `onChange` to update data
  const handleUpdateSubmit = useCallback((event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
  }, []);

  // Change selected metric. If empty, reset the form
  const handleSelectChange = useCallback(
    (event: FormEvent<HTMLSelectElement>) => {
      if (event.currentTarget.value === '') {
        handleClearClick();
      }
      setFormMetric(event.currentTarget.value);
    },
    [handleClearClick],
  );

  return (
    <>
      <Flex alignItems="baseline" mb={2} mt={3} pl={rem(33)} pr={3} style={!metrics ? { visibility: 'hidden' } : {}}>
        <Box mr={2}>
          <form action="" method="post" onSubmit={handleUpdateSubmit}>
            <Flex alignItems="baseline" flexWrap="wrap">
              <VisuallyHiddenLabel htmlFor="metric-id">Choose metric</VisuallyHiddenLabel>
              <Box maxWidth="200px" mb={2} mr={2}>
                <Select
                  id="metric-id"
                  isClearEnabled={true}
                  name="metric"
                  required={true}
                  value={formMetric}
                  variant="default"
                  onChange={handleSelectChange}
                  onClear={handleClearClick}
                >
                  <option value="">No metric selected</option>
                  {metrics?.map((value) => (
                    <option key={value} value={value}>
                      {getDisplayableMetricName(value.toString())}
                    </option>
                  ))}
                </Select>
              </Box>
            </Flex>
          </form>
        </Box>
      </Flex>
      <ResponsiveChartContainer height={height}>
        {loading && <Loading />}
        {portfolio !== undefined && !loading && formMetric !== undefined && (
          <PercentageAreaChart options={chartOptions} />
        )}
      </ResponsiveChartContainer>
    </>
  );
});
