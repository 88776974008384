import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { BrowserRouter as Router } from 'react-router-dom';
import ResizeObserverPolyfill from 'resize-observer-polyfill';
import { Auth0ProviderWithHistory } from './app/auth/Auth0ProviderWithHistory';
import { store } from './app/store';
import * as serviceWorkerRegistration from './service-worker-registration';
import '@risk-first/reset-css';
import './index.css';
// useLocation includes some monkey patching of history methods that we require
// seeing as we are using other hooks from the library, e.g. useSearchParam.
export { useLocation } from 'react-use';

if (!window.ResizeObserver) {
  window.ResizeObserver = ResizeObserverPolyfill;
}

const render = () => {
  const App = require('./app/App').default;

  ReactDOM.render(
    <Provider store={store}>
      <Router>
        <Auth0ProviderWithHistory>
          <App />
        </Auth0ProviderWithHistory>
      </Router>
    </Provider>,
    document.getElementById('root'),
  );
};

render();

// if (process.env.NODE_ENV === 'development' && module.hot) {
//   module.hot.accept('./app/App', render);
// }

// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorkerRegistration.register();
