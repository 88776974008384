import { PlusIconComponent } from '@risk-first/ui-assets';
import { Checkbox } from '@risk-first/ui-checkbox';
import { Box, Flex, VisuallyHiddenLabel } from '@risk-first/ui-core';
import { themeGet } from '@styled-system/theme-get';
import { withTheme } from 'emotion-theming';
import { rem } from 'polished';
import React, { FormEvent, MouseEvent, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import uniqid from 'uniqid';
import { RootState } from '../../app/RootState';
import { Filter } from '../../components/Filter';
import { FilterStateValues } from '../../features/filter/FilterState';
import { updateFilters } from '../../features/filter/filterSlice';
import { FlatButton } from '../../styles';

export const FiltersForm: React.FC = withTheme((props) => {
  const dispatch = useDispatch();
  const { filters } = useSelector((state: RootState) => state.filter);

  const addFilter = useCallback(
    (event: MouseEvent<HTMLButtonElement>) => {
      const newFilter: FilterStateValues = {
        id: uniqid('filter-'),
        active: false,
        metric: undefined,
        statistic: undefined,
        timestep: '0',
        minValue: undefined,
        maxValue: undefined,
      };

      const newFilters = filters ? [...filters, newFilter] : [newFilter];

      dispatch(updateFilters(newFilters));
    },
    [dispatch, filters],
  );

  const toggleAll = useCallback(
    (event: FormEvent<HTMLInputElement>) => {
      const activeState: boolean = event.currentTarget.checked;
      const updatedFilters = filters.map((filter: FilterStateValues) => ({ ...filter, active: activeState }));

      // TODO - known bug - filters with invalid data (e.g. a non-numeric minValue) can be dispatched via this method.
      dispatch(updateFilters(updatedFilters));
    },
    [dispatch, filters],
  );

  const allChecked = filters.every((filter) => filter.active);

  return (
    <Box py={3}>
      <Flex
        alignItems="center"
        borderBottom={`1px solid ${themeGet('colors.border')(props)}`}
        justifyContent="space-between"
        pb={3}
        pt={0}
        px={rem(20)}
      >
        {filters.length ? (
          <>
            <VisuallyHiddenLabel htmlFor="filter-select-all">Apply all filters</VisuallyHiddenLabel>
            <Checkbox checked={allChecked} id="filter-select-all" onChange={toggleAll} />
          </>
        ) : (
          /* Set a height to maintain the layout as the checkbox is visually toggled */
          <div style={{ height: rem(18) }} />
        )}
        <FlatButton type="button" onClick={addFilter}>
          <PlusIconComponent />
          <Box ml={2}>New filter</Box>
        </FlatButton>
      </Flex>
      {filters && [...filters].map((filter, index) => <Filter key={filter.id} filter={filter} filterIndex={index} />)}
    </Box>
  );
});
