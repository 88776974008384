import { GridApi } from 'ag-grid-community';
import { useLayoutEffect } from 'react';
import { debounce } from 'underscore';

const delayedResizeDelay = 30;
export const delayedResizeTable = debounce((gridApi?: GridApi) => {
  if (gridApi) {
    gridApi.sizeColumnsToFit();
  }
}, delayedResizeDelay);

export function useResizeTable(tableRef: HTMLDivElement | null, gridApi: GridApi | undefined) {
  useLayoutEffect(() => {
    const resizeObserver = new ResizeObserver((entries) => {
      delayedResizeTable(gridApi);
    });

    if (tableRef && resizeObserver) {
      resizeObserver.observe(tableRef);

      return () => {
        resizeObserver.disconnect();
      };
    }
  });
}
