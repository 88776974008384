import { Box, Button, Flex, H2, TextCtaButton } from '@risk-first/ui-core';
import { IconToggle } from '@risk-first/ui-toggle';
import { rem } from 'polished';
import React, { FormEvent, useCallback, useMemo, useState } from 'react';
import { Portfolio, PortfolioAsset } from '../../../features/portfolios/PortfolioState';
import { GENERAL_DECIMAL_NUMBER_PATTERN } from '../../../lib/constants/validation';
import { useThemeVal } from '../../../lib/style-helper/theme';
import { Label, NumberInput } from '../../../styles';
import { AssetsSelector } from './AssetsSelector';
import { AssetTotalRow } from './styles';

interface AssetAllocationContainerProps {
  existingStrategy?: Portfolio;
  newStrategy?: Portfolio;
  setNewStrategyVisible: (visible: boolean) => void;
  updateNewStrategy: (strategy: Portfolio | undefined) => void;
  toggleDefaultStrategyDialog: () => void;
}

const marketValueOptions = [
  {
    label: 'Percent',
    value: '%',
    icon: '%',
  },
  {
    label: 'Pounds',
    value: '£',
    icon: '£',
  },
];

export const AssetAllocationContainer = (props: AssetAllocationContainerProps) => {
  const { newStrategy, setNewStrategyVisible, toggleDefaultStrategyDialog, updateNewStrategy } = props;
  const assets = newStrategy?.assets;
  const [marketValue, setMarketValue] = useState<string>('100');

  const themeVal = useThemeVal();
  const panelBorderStyle = `1px solid ${themeVal('colors.border')}`;

  const handleMarketValueChange = useCallback((event: FormEvent<HTMLInputElement>) => {
    const value = event.currentTarget.value;
    setMarketValue(value);
  }, []);

  const setAssets = useCallback(
    (assets: PortfolioAsset[]) => {
      if (newStrategy) {
        updateNewStrategy({ ...newStrategy, assets });
      }
    },
    [newStrategy, updateNewStrategy],
  );

  const totalAllocationPercentage = useMemo(() => {
    const value = assets?.reduce((sum, { value }) => sum + (value || 0), 0).toFixed(2);
    return value ? `${value}%` : '-';
  }, [assets]);

  return (
    <>
      <Box borderBottom={panelBorderStyle}>
        <H2 fontWeight={themeVal('fontWeight.medium')} p={rem(20)}>
          Asset allocation
        </H2>
      </Box>
      <Flex alignItems="flex-end" borderBottom={panelBorderStyle} p={rem(20)}>
        <Box flex="1">
          <Label htmlFor="market-value">Market Value</Label>
          <NumberInput
            id="market-value"
            inputMode="numeric"
            name="marketValue"
            pattern={GENERAL_DECIMAL_NUMBER_PATTERN}
            required={true}
            style={{ textAlign: 'right' }}
            type="text"
            value={marketValue}
            variant="default"
            onChange={handleMarketValueChange}
          />
        </Box>
        <Box marginLeft={rem(10)}>
          <IconToggle
            name="marketValueUnits"
            options={marketValueOptions}
            size="large"
            value="%"
            variant="default"
            onChange={() => {}}
          />
        </Box>
      </Flex>

      <Box borderBottom={panelBorderStyle} p={`${rem(10)} ${rem(20)}`}>
        <Button
          disabled={newStrategy === undefined}
          size="small"
          variant="osirisSecondary"
          onClick={toggleDefaultStrategyDialog}
        >
          Pick Funds
        </Button>
      </Box>

      <Box borderBottom={panelBorderStyle} p={`${rem(10)} ${rem(20)}`}>
        {assets ? <AssetsSelector assets={assets} setAssets={setAssets} /> : <Box height={rem(80)} />}
      </Box>

      <AssetTotalRow borderBottom={panelBorderStyle} height={rem(50)}>
        <span>Total</span>
        <span>{totalAllocationPercentage}</span>
      </AssetTotalRow>

      <Flex
        borderBottom={panelBorderStyle}
        justifyContent="space-between"
        marginBottom="-1px"
        p={`${rem(10)} ${rem(20)}`}
      >
        <Button
          disabled={newStrategy === undefined}
          size="small"
          variant="osirisSecondary"
          onClick={useCallback(() => setNewStrategyVisible(true), [setNewStrategyVisible])}
        >
          Run new strategy
        </Button>
        <TextCtaButton disabled={newStrategy === undefined}>Cancel</TextCtaButton>
      </Flex>
    </>
  );
};
