import { Flex } from '@risk-first/ui-core';
import { Loading as Spinner } from '@risk-first/ui-loading';
import React from 'react';
import { AlignItemsProps, HeightProps, JustifyContentProps, SpaceProps } from 'styled-system';

type LoadingProps = Pick<AlignItemsProps, 'alignItems'> &
  Pick<HeightProps, 'height'> &
  Pick<JustifyContentProps, 'justifyContent'> &
  Pick<SpaceProps, 'padding'>;

export const Loading: React.FC<LoadingProps> = ({
  alignItems = 'center',
  height,
  justifyContent = 'center',
  padding = 4,
}) => (
  <Flex alignItems={alignItems} height={height} justifyContent={justifyContent} padding={padding}>
    <Spinner />
  </Flex>
);
