import styled from '@emotion/styled';
import { LargerChevronRightComponent } from '@risk-first/ui-assets';
import { Box, Flex, Input } from '@risk-first/ui-core';
import { themeGet } from '@styled-system/theme-get';
import { rem, rgba } from 'polished';
import { FlatButton, ResponsiveChartContainer } from '../../../styles';

export const TabMenuStyleWrapper = styled.div`
  border-bottom: 1px solid ${themeGet('colors.border')};

  & > * {
    margin-bottom: -1px;
  }
`;

export const TabContents = styled.div`
  border: 1px solid ${themeGet('colors.border')};
  border-top: none;
  font-size: ${themeGet('fontSizes.1')};
  overflow: hidden;
`;

export const HalfWidthInputBox = styled(Box)`
  :first-of-type {
    margin-right: 20px;
  }

  width: 50%;

  & input,
  & > div {
    width: 100%;
  }

  & input {
    border: 1px solid rgba(46, 46, 67, 0.25); /* match the Select border */
  }
`;

export const OptimizationList = styled.div`
  button[role='tab'] {
    color: ${themeGet('colors.text')};
    border: none;
    border-left: 5px solid transparent;
    font-weight: ${themeGet('fontWeight.medium')};
    outline: none;
    padding: 0 20px 0 25px;
    width: 100%;
  }

  button[role='tab'][aria-selected='true'] {
    background-color: ${themeGet('colors.ghostWhite')};
    border-left-color: ${themeGet('colors.brand.primary')};
  }

  button:hover:not([disabled]) {
    box-shadow: none;
  }
`;

export const SmallerFlatButton = styled(FlatButton)`
  font-size: ${themeGet('fontSizes.0')};
  outline: none;
`;

export const ScenarioDataInputBox = styled(Box)`
  label {
    font-weight: ${themeGet('fontWeight.medium')};
    margin-right: 10px;
  }

  input {
    /* Properties to allow inputs to be shown as fixed fields for mockup purposes */
    background: transparent;
    border: none;
    font-size: inherit;
    opacity: 0.8;
    padding: 0;
    text-align: left;
    width: 5em;
  }

  /* We need this because flex box doesn't respect "box-sizing: border-box"
     and doesn't include padding when calculating item width */
  &::after {
    content: '';
    display: inline-block;
    width: 20px;
  }
`;

export const ScenarioDataLink = styled(Flex)`
  align-items: center;
  border-left: 1px solid ${themeGet('colors.border')};
  color: ${themeGet('colors.brand.primary')};
  justify-content: flex-end;
  padding: 0 1.25rem;
  width: 12.5rem;
`;

export const ConstraintNameInput = styled(Input)`
  /* This is visible as a control if the constraint is open for editing or if it's been selected */
  .constraint-item[aria-expanded='false'] &:not(:focus) {
    background-color: transparent;
    border-color: transparent;
  }

  padding: 0.125rem 0.25rem;
  width: 100%;
`;

export const ConstraintEditIconComponent = styled(LargerChevronRightComponent)`
  &[aria-expanded='true'] {
    transform: rotate(90deg);
  }
  color: ${themeGet('colors.text')};
  transform: none;
`;

export const ConstraintAssetTable = styled.table`
  /* There's an issue, I think when a table has a fractional width value, where it doesn't
     fill the parent container and leaves an empty 1 pixel wide strip down the side */
  width: calc(100% + 1px);

  thead {
    tr {
      border-bottom: 1px solid ${themeGet('colors.border')};
    }
  }

  th {
    font-weight: ${themeGet('fontWeight.medium')};
    padding: 0.8rem 0;
  }

  th:nth-of-type(2) {
    position: relative;
    left: -0.75rem;
  }

  td {
    padding-top: 0.3125rem;
    padding-bottom: 0.3125rem;
    padding-right: 0.375rem;
    text-align: center;
    vertical-align: middle;
  }

  th:first-of-type,
  td:first-of-type {
    padding-left: 1rem;
    padding-right: 0;
    text-align: left;
  }
  th:last-of-type,
  td:last-of-type {
    padding-right: 0.8rem;
  }
  td:last-of-type {
    padding-left: calc(2 * 0.5625rem); /* This is double the empty label margin on checkboxes so it looks centred */
  }

  tbody tr:nth-of-type(odd) {
    background-color: ${(props) => rgba(themeGet('colors.darkGrayishBlue')(props), 0.04)};
  }
`;

export const EfficientFrontierChartContainer = styled(ResponsiveChartContainer)`
  svg {
    padding-left: 6px;
    padding-right: 9px;
  }

  .highcharts-tooltip {
    line-height: 1.4;

    b {
      font-weight: ${themeGet('fontWeight.medium')};
    }

    th {
      padding-right: ${rem(5)};
    }

    /* Remove the default formatting from the top of the tooltip */
    & > span {
      span,
      br {
        display: none;
      }
    }
  }
`;

export const EfficientFrontierDataTable = styled.table`
  width: 100%;
  border: 1px solid ${themeGet('colors.border')};
  color: ${themeGet('colors.darkGrayishBlue')};

  th,
  td {
    padding-right: ${rem(13)};
    padding-bottom: ${rem(13)};
    padding-top: ${rem(13)};
  }

  td {
    width: 6%; /* Stop longer data values from pushing out the column width */
  }

  thead th {
    font-weight: ${themeGet('fontWeight.medium')};
  }

  thead tr {
    border-bottom: 1px solid ${themeGet('colors.border')};
  }

  th:first-of-type {
    padding-left: ${rem(13)};
  }

  thead th,
  td {
    text-align: right;
  }

  tbody th,
  thead th:first-of-type {
    text-align: left;
  }

  tbody th,
  tbody td {
    opacity: 0.8;
  }

  tbody tr:nth-of-type(odd) {
    background-color: ${(props) => rgba(themeGet('colors.darkGrayishBlue')(props), 0.04)};
  }
`;

export const EfficientPortfolioDataTable = styled(EfficientFrontierDataTable)`
  thead tr:first-of-type {
    background-color: ${(props) => rgba(themeGet('colors.darkGrayishBlue')(props), 0.04)};
    th {
      padding-bottom: ${rem(9)};
      padding-top: ${rem(9)};
    }
  }
`;

export const NoLabelCheckboxWrapper = styled.div`
  & label::before {
    margin-right: 0 !important;
    background-color: ${themeGet('colors.white')};
  }
`;
