import { getRandomFromRange } from '../lib/number-utils/getRandomFromRange';
import { sleep } from '../lib/sleep';
import { addHeader } from './addHeader';

/**
 * Generic function to call API endpoint
 */
export async function callAPI(endpoint: string, init: RequestInit = {}) {
  /* TODO: remove delay of API calls once there is no more need of it
      - The reason for having the delay in first place is that, the live product may take a while to resolve API calls,
      and currently (while project is still DEMO) the calls are nearly instant. 
      
      So, adding artificial delay here simulates exactly that - small delay (500 - 1500ms) on each of the API calls.
  */
  const delay = getRandomFromRange(500, 1500);
  await sleep(delay);

  return fetch(endpoint, addHeader(init))
    .then(function (response) {
      if (response.status !== 200) {
        console.error('Looks like there was a problem. Status Code: ' + response.status);
        return;
      }

      return response.json();
    })
    .catch(function (err) {
      console.error('API Error', err);
    });
}
