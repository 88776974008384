import { ColumnChart } from '@risk-first/ui-column-chart';
import { Box, Button, Flex } from '@risk-first/ui-core';
import { themeGet } from '@styled-system/theme-get';
import merge from 'deepmerge';
import { withTheme } from 'emotion-theming';
import { Options, SeriesColumnOptions, Tooltip, TooltipFormatterContextObject } from 'highcharts';
import { rem } from 'polished';
import React, { MouseEvent, useCallback, useEffect, useMemo, useState } from 'react';
import { useMountedState } from 'react-use';
import { getCurve } from '../../api/curve';
import { getLiabilities } from '../../api/liabilities';
import { Loading } from '../../components';
import { DefaultOptions, getDefaultChartOptions } from '../../lib/charts/defaultChartOptions';
import { ResponsiveChartContainer } from '../../styles';
import { getFixedLocaleNumberFormatter } from '../../utils';
import { CurveJSON, CurveProps, CurveSeriesProps } from './types';
import { buildBuckets, calculateNetData } from './utils';

enum ButtonIds {
  DV01 = 'curve-button-dv01',
  KRD = 'curve-button-krd',
}

const numberFormatter = getFixedLocaleNumberFormatter();

const getComponentDefaultChartOptions = (props: { theme: any }) => ({
  chart: {
    spacingLeft: 8,
  },
  legend: {
    align: 'left',
    enabled: true,
    itemMarginBottom: 6,
    itemStyle: {
      color: themeGet('colors.chartTextColor')(props),
      fontSize: rem(10),
      fontWeight: themeGet('fontWeight.regular')(props),
      marginRight: rem(5),
      opacity: 0.8,
    },
    x: 16,
  },
  xAxis: {
    categories: ['0-2', '3-5', '6-10', '11-15', '16-20', '21-30', '31-40', '41-50', '51+'],
  },
  yAxis: {
    title: {
      y: 2,
    },
  },
});

// Place our timesteps into 'buckets'
const timestepBuckets = [
  [0, 1],
  [2, 3, 4],
  [5, 6, 7, 8, 9],
  [10, 11, 12, 13, 14],
  [15, 16, 17, 18, 19],
  [21, 22, 23, 24, 25, 26, 27, 28, 29],
  [31, 32, 33, 34, 35, 36, 37, 38, 39],
  [41, 42, 43, 44, 45, 46, 47, 48, 49, 50],
  [
    51,
    52,
    53,
    54,
    55,
    56,
    57,
    58,
    59,
    60,
    61,
    62,
    63,
    64,
    65,
    66,
    67,
    68,
    69,
    70,
    71,
    72,
    73,
    74,
    75,
    76,
    77,
    78,
    79,
    80,
  ],
];

export const Curve: React.FC<CurveProps> = withTheme((props) => {
  const { height, portfolio } = props as CurveProps;
  const defaultOptions = useMemo(
    () =>
      merge(
        getDefaultChartOptions({ theme: props.theme }),
        getComponentDefaultChartOptions({ theme: props.theme }),
      ) as DefaultOptions,
    [props.theme],
  );
  const [loading, setLoading] = useState<boolean>(true);
  const [rawData, setRawData] = useState<CurveJSON[] | undefined>(undefined);
  const [chartData, setChartData] = useState<CurveSeriesProps[] | undefined>(undefined);
  const isMounted = useMountedState();
  const [liabilities, setLiabilities] = useState<CurveJSON[] | undefined>(undefined);

  useEffect(() => {
    async function fetchData() {
      const newLiabilities = await getLiabilities();

      if (isMounted() && newLiabilities) {
        setLiabilities(newLiabilities);
      }
    }

    fetchData();
  }, [isMounted]);

  // Fetch the correct JSON file based on selectedPortfolio
  useEffect(() => {
    async function fetchData(selectedPortfolio: string) {
      const importedData = await getCurve(selectedPortfolio);

      if (isMounted() && importedData) {
        setRawData(importedData);
        setLoading(false);
      }
    }

    if (portfolio !== undefined) {
      setLoading(true);
      fetchData(portfolio);
    }
  }, [isMounted, portfolio]);

  // Set the curve data
  useEffect(() => {
    if (!rawData || !liabilities) {
      return;
    }

    const newChartData: CurveSeriesProps[] = [
      {
        name: 'Liabilities',
        color: '#581bb8',
        data: buildBuckets(liabilities, 'dV01Interest', timestepBuckets),
      },
      {
        name: 'Assets',
        color: '#a91963',
        data: buildBuckets(rawData, 'dV01Interest', timestepBuckets),
      },
      {
        name: 'Net',
        color: '#356ac2',
        data: [0, 0, 0, 0, 0, 0, 0, 0, 0], // We will calculate this value later *
      },
      {
        name: 'Liabilities',
        color: '#581bb8',
        data: buildBuckets(liabilities, 'kRDInterest', timestepBuckets),
      },
      {
        name: 'Assets',
        color: '#a91963',
        data: buildBuckets(rawData, 'kRDInterest', timestepBuckets),
      },
      {
        name: 'Net',
        color: '#356ac2',
        data: [0, 0, 0], // We will calculate this value later **
      },
    ];

    // * Net DV01
    newChartData[2] = {
      ...newChartData[2],
      data: calculateNetData(newChartData[1].data, newChartData[0].data, timestepBuckets),
    };

    // ** Net KRD
    newChartData[5] = {
      ...newChartData[5],
      data: calculateNetData(newChartData[4].data, newChartData[3].data, timestepBuckets),
    };

    setChartData(newChartData);
  }, [rawData, liabilities]);

  // Curve Toggle Buttons
  const [activeButton, setActiveButton] = useState<string>(ButtonIds.DV01);
  const handleButtonClick = useCallback((event: MouseEvent<HTMLButtonElement>) => {
    const button = event.currentTarget;
    const newActiveTab: string = button.getAttribute('data-id') || '';

    setActiveButton(newActiveTab);
  }, []);

  const formatTooltip = useCallback(function (this: TooltipFormatterContextObject, toolip?: Tooltip) {
    const firstPoint: TooltipFormatterContextObject | undefined = this;
    const { point } = firstPoint;
    const value: string = numberFormatter.format(point.y!);

    let label = `<div class="tooltip-title">${firstPoint?.key}</div>`;
    label += `<div class="tooltip-point">
                  <span class="tooltip-marker" style="background-color: ${point.color};"></span>
                    <span class="tooltip-name">${firstPoint.series.name}</span>
                    <span class="tooltip-value">${value.replace(/,/g, ' ')}</span>
                  </span>
                </div>`;

    return label;
  }, []);

  // Curve Options - dictates how our chart appears
  const [curveOptions, setCurveOptions] = useState<Options>(defaultOptions);

  // Build up the rest of the chart data (depending upon what the user has chosen to see)
  useEffect(() => {
    if (!chartData) {
      return;
    }
    let series: CurveSeriesProps[] = [];
    let titleText: string = '';
    const isDV01 = activeButton.includes('dv01');
    const isKRD = activeButton.includes('krd');
    const curveData = JSON.parse(JSON.stringify(chartData));

    if (isDV01) {
      series = [curveData[0], curveData[1], curveData[2]];
      titleText = 'Present Value Movement £m';
    } else if (isKRD) {
      series = [curveData[3], curveData[4], curveData[5]];
      titleText = 'Key Rate Duration years';
    }

    const newCurveOptions = {
      ...defaultOptions,
      series: series as SeriesColumnOptions[],
      tooltip: {
        formatter: formatTooltip,
        useHTML: true,
      },
      yAxis: {
        title: {
          text: titleText,
        },
        labels: {
          ...defaultOptions.yAxis.labels,
          formatter: function (this: { value: number }) {
            if (activeButton === ButtonIds.DV01) {
              return numberFormatter.format(this.value / 1000000);
            }
            return this.value;
          },
        },
      },
    };

    setCurveOptions(
      merge(defaultOptions, newCurveOptions, {
        arrayMerge: (destinationArray, sourceArray) => sourceArray,
      }),
    );
  }, [activeButton, chartData, defaultOptions, formatTooltip]);

  return (
    <>
      <Flex style={loading ? { visibility: 'hidden' } : {}}>
        <Box mb={3} ml="auto" mt={3} pr={rem(10)}>
          <Button
            aria-selected={activeButton === ButtonIds.DV01}
            data-id={ButtonIds.DV01}
            marginRight={rem(10)}
            size="regular"
            type="button"
            variant={activeButton === ButtonIds.DV01 ? 'default' : 'ghost'}
            onClick={handleButtonClick}
          >
            DV01
          </Button>{' '}
          <Button
            aria-selected={activeButton === ButtonIds.KRD}
            data-id={ButtonIds.KRD}
            size="regular"
            type="button"
            variant={activeButton === ButtonIds.KRD ? 'default' : 'ghost'}
            onClick={handleButtonClick}
          >
            KRD
          </Button>
        </Box>
      </Flex>
      <ResponsiveChartContainer height={height} role="tabpanel">
        {loading && <Loading />}
        {curveOptions && curveOptions.series && <ColumnChart options={curveOptions} />}
      </ResponsiveChartContainer>
    </>
  );
});
