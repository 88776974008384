import styled from '@emotion/styled';
import { Box, Flex } from '@risk-first/ui-core';
import { themeGet } from '@styled-system/theme-get';
import { rem } from 'polished';

export const StrategyValueTableWrapper = styled(Box)`
  table {
    border: 1px solid ${themeGet('colors.border')};

    thead th {
      font-weight: ${themeGet('fontWeight.medium')};
      z-index: 0; // We need this until the issue is resolved in work item #34349
    }

    /* align all cells right apart from the first */
    td:not(:first-of-type) div {
      justify-content: flex-end;
    }

    thead th:not(:first-of-type) {
      width: 17%;
      text-align: right;
    }
    thead th:first-of-type {
      width: 32%;
      text-align: left;
    }

    tbody tr td:last-of-type {
      color: ${themeGet('colors.darkPink')};
      padding-right: ${rem(20)};
    }
  }
`;

export const AssetsSelectorTable = styled.table`
  width: 100%;

  th {
    text-align: left;
    font-size: ${themeGet('fontSizes.1')};
    font-weight: ${themeGet('fontWeight.medium')};
    line-height: ${rem(40)};
  }

  td {
    padding-left: 5px;
    padding-right: 5px;
  }

  td:first-of-type {
    font-size: 0.625rem;
    padding-left: 0;
    padding-right: 0; /* should be 5px but we need to shave off some space for long asset names */
  }
  td:nth-of-type(3) {
    font-size: ${themeGet('font-sizes.0')};
    width: ${rem(110)};
    padding-bottom: ${rem(10)};
  }
  td:last-of-type {
    padding-right: 0;
  }
`;

export const AssetTotalRow = styled(Flex)`
  align-items: center;
  font-size: ${themeGet('fontSizes.1')};
  font-weight: ${themeGet('fontWeight.medium')};
  justify-content: space-between;
  padding: ${rem(10)} ${rem(20)};
`;

export const ErrorMessage = styled(Box)`
  color: ${themeGet('colors.errorTextColor')};
  font-size: ${themeGet('fontSizes.0')};
`;
