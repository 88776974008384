import { ChevronRightIconComponent } from '@risk-first/ui-assets';
import { Box, Button, Flex, H1, H2 } from '@risk-first/ui-core';
import { TabMenu, TabItem } from '@risk-first/ui-tab-menu';
import { TextFiltersMap } from '@risk-first/ui-text-filter';
import { themeGet } from '@styled-system/theme-get';
import { withTheme } from 'emotion-theming';
import { rem } from 'polished';
import React, { useCallback, useState, useEffect, useMemo } from 'react';
import { Link, useHistory, useParams } from 'react-router-dom';
import { useMountedState, useSearchParam } from 'react-use';
import { getLineOfBusiness } from '../api/lineOfBusiness';
import { getStrategies } from '../api/strategies';
import { getStrategiesComparison } from '../api/strategiesComparison';
import { CreateStrategies, InvestmentStrategyComparisonSummary, Layout, Loading } from '../components';
import { StrategyComparisonProps } from '../components/InvestmentStrategyComparisonSummary/InvestmentStrategyComparisonSummary';
import { StrategiesDetailTable } from '../components/StrategiesDetailTable';
import { Portfolio } from '../features/portfolios/PortfolioState';
import { DEFAULT_DATE } from '../lib/constants/dates';
import { STRATEGIES } from '../lib/constants/pageTitles';
import { Breadcrumb, DateTime } from '../styles';
import { getSchemeFromId, Scheme, formatDate, isGeneratedStrategy, transformSearchParams } from '../utils';

const strategyTabs = {
  strategies: {
    label: 'Strategies',
    tabId: 'tab-strategies',
    tabpanelId: 'tabpanel-strategies',
    pathParam: '',
  },
  assets: {
    label: 'Asset Allocation',
    tabId: 'tab-assets',
    tabpanelId: 'tabpanel-assets',
    pathParam: 'assets',
  },
  create: {
    label: 'Create Strategies',
    tabId: 'tab-create',
    tabpanelId: 'tabpanel-create',
    pathParam: 'create',
  },
};

type StrategyTabKeys = keyof typeof strategyTabs;

const tabItems: TabItem[] = Object.keys(strategyTabs).map((key) => {
  const tab = strategyTabs[key as StrategyTabKeys];
  return {
    'aria-controls': tab.tabpanelId,
    id: tab.tabId,
    label: tab.label,
    role: 'tab',
    onClick: () => {},
  };
});

export const Strategies: React.FC = withTheme((props) => {
  const history = useHistory();
  const { id, tab: urlTab } = useParams<{ id: string; tab?: string }>();
  const userSelectedDate = useSearchParam('date');
  const date = formatDate(userSelectedDate || DEFAULT_DATE);
  const [loading, setLoading] = useState<boolean>(true);
  const [schemes, setSchemes] = useState<Scheme[] | undefined>(undefined);
  const [strategiesComparison, setStrategiesComparison] = useState<StrategyComparisonProps[] | undefined>(undefined);
  const [strategies, setStrategies] = useState<Portfolio[] | undefined>(undefined);
  const [scheme, setScheme] = useState<Scheme | undefined>(undefined);
  const [sourceFilters, setSourceFilters] = useState<TextFiltersMap>(new Map<string, boolean>());
  const isMounted = useMountedState();

  const selectedPortfolioQueryParam = useSearchParam('selected');
  const selectedPortfolioIds: string[] = useMemo(
    () => selectedPortfolioQueryParam?.split(',').filter((s) => s.length > 0) || [],
    [selectedPortfolioQueryParam],
  );
  const setSelectedPortfolioIds = useCallback(
    (ids: string[]) => {
      const selected = ids.length > 0 ? ids.join(',') : undefined;
      history.replace({
        pathname: history.location.pathname,
        search: transformSearchParams(history.location.search, { selected }),
      });
    },
    [history],
  );

  const activeTab = useMemo(
    () => Object.values(strategyTabs).find((tab) => urlTab === tab.pathParam) || strategyTabs.strategies,
    [urlTab],
  );

  const setActiveTab = useCallback(
    (tabLabel: string) => {
      const newTab = Object.values(strategyTabs).find((tab) => tab.label === tabLabel);
      if (newTab) {
        history.push({
          pathname: `/strategies/${id}${newTab.pathParam ? `/${newTab.pathParam}` : ''}`,
          search: transformSearchParams(history.location.search, { existingId: undefined }),
        });
      } else {
        throw new Error('Invalid tab selection');
      }
    },
    [history, id],
  );

  // Get API data
  useEffect(() => {
    async function fetchData() {
      Promise.all([getLineOfBusiness(), getStrategies(), getStrategiesComparison()]).then((json) => {
        const newSchemes = json[0];
        const newStrategies = json[1];
        const newStrategiesComparison = json[2];

        if (isMounted()) {
          if (newSchemes) {
            setSchemes(newSchemes);
          }

          if (newStrategies) {
            setStrategies(newStrategies);
          }

          if (newStrategiesComparison) {
            setStrategiesComparison(newStrategiesComparison);
          }

          if (newSchemes && newStrategies && newStrategiesComparison) {
            setLoading(false);
          }
        }
      });
    }

    fetchData();
  }, [isMounted]);

  useEffect(() => {
    if (schemes && isMounted()) {
      const newScheme = id && schemes ? getSchemeFromId(schemes, parseInt(id, 10)) : undefined;
      setScheme(newScheme);
    }
  }, [id, isMounted, schemes]);

  // Set table rows
  const [rowData, setRowData] = useState<Portfolio[] | undefined>(undefined);
  const filteredRowData = useMemo(() => rowData?.filter((row) => sourceFilters.get(row.source)), [
    rowData,
    sourceFilters,
  ]);

  const filteredStrategiesComparison = useMemo<StrategyComparisonProps[]>(() => {
    const filteredStrategyNames = new Set<string>(
      strategies?.filter((s) => sourceFilters.get(s.source)).map((s) => s.name),
    );

    return (
      strategiesComparison?.map((comparison) => ({
        ...comparison,
        data: comparison.data.filter((c) => filteredStrategyNames.has(c.name)),
      })) || []
    );
  }, [strategies, strategiesComparison, sourceFilters]);

  useEffect(() => {
    if (strategies) {
      setRowData(strategies);
      setSourceFilters(new Map<string, boolean>(strategies.map((strategy) => [strategy.source, true])));
    }
  }, [strategies]);

  // Send user to the next page.
  const runOptimiser = useCallback(() => {
    // Strip out generated strategies
    const staticSelectedIds = selectedPortfolioIds.filter((id) => !isGeneratedStrategy(id)).join(',') || undefined;
    history.push({
      pathname: `/analysis/${id}`,
      search: transformSearchParams('', {
        date: userSelectedDate || undefined,
        selected: staticSelectedIds,
      }),
    });
  }, [history, id, selectedPortfolioIds, userSelectedDate]);

  useEffect(() => {
    document.title = scheme ? `${scheme.lineOfBusiness} - ${STRATEGIES}` : STRATEGIES;
  }, [scheme]);

  const handleCreateStrategy = useCallback(
    (strategy?: Portfolio) => {
      if (strategy) {
        setStrategies([...(strategies || []), strategy]);
      }
      setActiveTab(strategyTabs.strategies.label);
    },
    [setActiveTab, strategies],
  );

  const handleDeleteStrategy = useCallback(
    (strategyId: string) => {
      if (isGeneratedStrategy(strategyId)) {
        setStrategies((strategies || []).filter((strategy) => strategy.strategyID !== strategyId));
        setSelectedPortfolioIds(selectedPortfolioIds.filter((id) => id !== strategyId));
      }
    },
    [selectedPortfolioIds, setSelectedPortfolioIds, strategies],
  );

  return (
    <Layout>
      <div>
        <Box p={[3, 4]}>
          <H1 mb={rem(5)}>Investment Strategy Summary</H1>
          {loading && (
            <>
              <Flex>
                <Breadcrumb>
                  <Link to="/">Line of Business</Link>
                </Breadcrumb>
                <DateTime ml="auto">{date}</DateTime>
              </Flex>
              <Flex
                bg="white"
                border={`1px solid ${themeGet('colors.paleLilac')(props)}`}
                height={'calc(100vh - 190px)'}
                justifyContent={'center'}
                mt={rem(20)}
              >
                <Loading />
              </Flex>
            </>
          )}
          {!scheme && !loading && (
            <Box backgroundColor="white" p={4}>
              <H2 mb={3}>Error</H2>
              <p>
                Go back and <Link to="/">choose a line of business</Link>
              </p>
            </Box>
          )}
          {scheme && !loading && (
            <>
              <Flex>
                <Breadcrumb>
                  <Link to="/">Line of Business</Link> <ChevronRightIconComponent /> {scheme.lineOfBusiness}
                </Breadcrumb>
                <DateTime ml="auto">{date}</DateTime>
              </Flex>
              <div>
                <Flex
                  alignItems={'center'}
                  bg="white"
                  border={`1px solid ${themeGet('colors.paleLilac')(props)}`}
                  borderBottom="none"
                  mt={rem(20)}
                  px={3}
                >
                  <div>
                    <TabMenu active={activeTab.label} items={tabItems} setActive={setActiveTab} variant="default" />
                  </div>
                  <Flex alignItems="center" ml="auto">
                    <Button minWidth={rem(125)} size="small" variant="primary" onClick={runOptimiser}>
                      Strategy Comparison
                    </Button>
                  </Flex>
                </Flex>
                {activeTab === strategyTabs.strategies && (
                  <div id={strategyTabs.strategies.tabpanelId} role="tabpanel">
                    <StrategiesDetailTable
                      portfolios={filteredRowData || []}
                      selectedPortfolioIds={selectedPortfolioIds}
                      setSelectedPortfolioIds={setSelectedPortfolioIds}
                      setSourceFilters={setSourceFilters}
                      sourceFilters={sourceFilters}
                      onDeleteStrategy={handleDeleteStrategy}
                    />
                  </div>
                )}
                {activeTab === strategyTabs.assets && (
                  <div id={strategyTabs.assets.tabpanelId} role="tabpanel">
                    {strategiesComparison !== undefined && (
                      <InvestmentStrategyComparisonSummary data={filteredStrategiesComparison} />
                    )}
                  </div>
                )}
                {activeTab === strategyTabs.create && (
                  <div id={strategyTabs.create.tabpanelId} role="tabpanel">
                    <CreateStrategies strategies={strategies} onSaveStrategy={handleCreateStrategy} />
                  </div>
                )}
              </div>
            </>
          )}
        </Box>
      </div>
    </Layout>
  );
});
