import styled from '@emotion/styled';
import { Box } from '@risk-first/ui-core';
import { themeGet } from '@styled-system/theme-get';
import { rem } from 'polished';
import { BaseMenu } from '../../styles';

export const Wrapper = styled(Box)`
  position: relative;
`;

// Drop down menu, hidden with the [hidden] attribute
export const Menu = styled(BaseMenu)`
  right: 0;
  top: calc(100% + ${rem(4)});
  width: ${rem(305)};

  li {
    padding: ${rem(15)} ${rem(20)};
  }

  li:not(:last-child)::after {
    background: ${themeGet('colors.lavender')};
    content: '';
    display: block;
    height: ${rem(1)};
    transform: translateY(${rem(14)});
  }
`;

export const CloseButton = styled.button`
  background: transparent;
  border: ${rem(1)} solid transparent;
  color: ${themeGet('colors.darkPink')};
  cursor: pointer;
  display: block;
  font-family: inherit;
  font-size: ${themeGet('fontSizes.1')};
  letter-spacing: ${rem(-0.19)};
  line-height: inherit;
  margin-left: auto;
  padding: 0;
  text-decoration: underline;

  &:hover {
    text-decoration: none;
  }
`;

export const CreateButton = styled.button`
  background: transparent;
  border: ${rem(1)} solid transparent;
  color: ${themeGet('colors.darkPink')};
  cursor: pointer;
  display: block;
  font-family: inherit;
  font-size: ${themeGet('fontSizes.0')};
  font-weight: ${themeGet('fontWeight.medium')};
  height: ${rem(30)};
  line-height: ${rem(30)};
  margin-right: ${rem(20)};
  padding: 0;
  text-decoration: none;

  &:hover {
    text-decoration: none;
  }
`;
