import { callAPI } from '.';

/**
 * Retrieve the Cashflow data from the JSON data folder
 * The id passed in will be a zero-indexed number in a string like '0', or '9' but the filename
 * will be cashflow-00.json or cashflow-09.json
 */
export function getCashflow(portfolioId: string) {
  const zeroIndexedSelectedPortfolio: string = portfolioId;
  const paddedSelectedPortfolio = zeroIndexedSelectedPortfolio.padStart(2, '0');
  const filename = `cashflow-${paddedSelectedPortfolio}.json`;
  const json = callAPI(`/api/${filename}`);

  return json;
}
