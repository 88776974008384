import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { getSavedAnalysisSettings } from '../../lib/localStorage';
import { FilterState, FilterStateValues } from './FilterState';

const analysisSavedSettings = getSavedAnalysisSettings();
const initialState: FilterState = {
  filters: analysisSavedSettings?.filters || [],
};

const getIndex = (array: FilterStateValues[], id: string) => array.findIndex((element) => element.id === id);

const filterSlice = createSlice({
  name: 'filter',
  initialState,
  reducers: {
    deleteFilter: (state, action: PayloadAction<string>) => {
      const newFilters = [...state.filters];
      const index = getIndex(newFilters, action.payload);

      newFilters.splice(index, 1);
      state.filters = [...newFilters];
    },
    updateFilter: (state, action: PayloadAction<FilterStateValues>) => {
      const newFilters = [...state.filters];
      const index = getIndex(newFilters, action.payload.id);

      newFilters[index] = action.payload;
      state.filters = [...newFilters];
    },
    updateFilters: (state, action: PayloadAction<FilterStateValues[]>) => {
      state.filters = action.payload;
    },
  },
});

export const { deleteFilter, updateFilter, updateFilters } = filterSlice.actions;

export const { reducer: filterReducer } = filterSlice;
