import styled from '@emotion/styled';
import { Box } from '@risk-first/ui-core';
import { themeGet } from '@styled-system/theme-get';
import { rem } from 'polished';

export const TabPanel = styled(Box)`
  align-items: flex-end;
  border-right: ${rem(1)} solid ${themeGet('colors.border')};
  height: 100%;
  max-width: 40vw;
  overflow: auto;
  width: ${rem(338)};

  /*
  For a11y we need to focus the entire tab when this element becomes active
  but that default blue focus style around an entire element would be hard to justify to the Design team...
  */
  &:focus {
    outline: none;
  }

  /*
  at 1280px (80em * 16px font-size = 1280px) ensure sidebar does not take up too much room
  */
  ${themeGet('mediaQueries.large')} {
    max-width: 30vw;
    width: ${rem(285)};
    border-left: ${rem(1)} solid ${themeGet('colors.border')};
    position: absolute;
    bottom: 0;
    height: auto;
    right: 0;
    top: 0;
  }

  /*
  at 768px (48em * 16px font-size = 768px) ensure form elements are full width
  TODO change this when these elements become components
  */
  ${themeGet('mediaQueries.small')} {
    input,
    select {
      width: 100%;
    }
  }
`;
